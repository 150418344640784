import { getPriceConversion } from "../../../action/walletAction";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = []

export const getPriceCNV = createAsyncThunk("priceconversion", async () => {
    // console.log('rrrrr');
    const { status, result } = await getPriceConversion();
    // console.log(status,result, 'statusstatusstatus')

    if (result) return result;
    else return initialState;
});

const todoSlice = createSlice({
    name: "priceconversion",
    initialState,
    reducers: {
        accountReset: (state, action) => {
            state = initialState;
            return state;
        },
        deleteTodo: (state, action) => {
            state.tasks = state.tasks.filter((task) => task.id !== action.payload);
        },
    },
    extraReducers: (build) => {
        build.addCase(getPriceCNV.fulfilled, (state, action) => {
            // console.log(state, 'statestatestate')

            state = action.payload;
            return state;

        });
    },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;


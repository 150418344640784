import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useTranslation } from "react-i18next";

// img
import w1 from "../assets/images/w1.svg";
import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function About() {

  const { t } = useTranslation()

  return (
    <div>
      <Navbar />
      <div className="common_page" >
        <div className="container">
          <h1 className="h2tag" ><strong>{t("ABOUT_US")}</strong></h1>

          <ul>
            <li><strong>2022</strong>{t("ABOUT_LIST1")}</li>
            <li><strong>45K+</strong>{t("ABOUT_LIST2")}</li>
            <li><strong>97+</strong>{t("ABOUT_LIST3")}</li>
            <li><strong>50+</strong>{t("ABOUT_LIST4")}</li>
          </ul>

          <h2><strong><em><strong><em>{t("ABOUNT_HEAD1")}</em></strong></em></strong></h2>
          <p>{t("ABOUT_PARA1")}</p>

          <h2><strong><em><strong><em>{t("ABOUNT_HEAD2")}</em></strong></em></strong></h2>
          <p>{t("ABOUT_PARA2")}</p>

          <h3><strong>&middot;&nbsp;</strong><strong><strong>{t("ABOUNT_HEAD3")}</strong></strong></h3>
          <p>{t("ABOUT_PARA3")}</p>

          <h3><strong>&middot;&nbsp;</strong><strong><strong>{t("ABOUNT_HEAD4")}</strong></strong></h3>
          <p>{t("ABOUT_PARA4")}</p>

          <h3><strong>&middot;&nbsp;</strong><strong><strong>{t("ABOUNT_HEAD5")}</strong></strong></h3>
          <p>{t("ABOUT_PARA5")}</p>

          <h3><strong>&middot;&nbsp;</strong><strong><strong>{t("ABOUNT_HEAD6")}</strong></strong></h3>
          <p>{t("ABOUT_PARA6")}</p>

          <h3><strong>&middot;&nbsp;</strong><strong><strong>{t("ABOUNT_HEAD7")}</strong></strong></h3>
          <p>{t("ABOUT_PARA7")}</p>

          <h3><strong>&middot;&nbsp;</strong><strong><strong>{t("ABOUNT_HEAD8")}</strong></strong></h3>
          <p>{t("ABOUT_PARA8")}</p>


          <ul>
            <li><a href="#"><u>{t("AFFILIATE_PROGRAM")}</u></a></li>
          </ul>


        </div>
      </div>
      <Footer />
    </div>
  );
}

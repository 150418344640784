import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import Wallet from "./pages/wallet.js";
import Marketplace from "./pages/marketplace.js";
import StoreMarketPlace from "./pages/storemarket.js"
import Megamart from "./pages/megamart.js";
import Login from "./pages/login.js";
import Register from "./pages/register.js";
import Orders from "./pages/orders.js";
import Productdetails from "./pages/product-details.js";
import Myproduct from "./pages/myproduct.js";
import MyproductInfo from "./pages/myproduct-details.js"
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";
import config from "../src/config/index.js"
import i18n from "./components/i18next/i18n";
import { I18nextProvider } from "react-i18next";

import store, { persistor } from './redux/store.js';
import HelperRoute from "../src/components/route/HelperRoute";

import ProtectedRouter from "./helper/ProtectedRouter.js";

import LoginRouter from "./helper/loginRouter.js";
import Forget from "./pages/forgot.js";
import Support from "./pages/support-ticket.js";

import Verifypassword from "./pages/reset-password.js"
import Emailverification from "./pages/email-verification.js";
import Verifymail from "./pages/verifymail.js"
import ResendMail from "./pages/resendMail.js";
import Deposit from "./pages/deposit.js";
import Withdraw from "./pages/withdrawcoin.js";

import Finance from "./pages/finance.js";
import Kyc from "./pages/kyc.js";
import Changepassword from "./pages/changepassword.js";
import VipMarketplace from "./pages/vipMarketplace.js";
import Ipchecking from "./pages/Ipchecking.js"
import MapChart from "./components/globlemap/MapDisplay.js"

import ShippingPackage from "./pages/Shipping-package.js";
import Advertisementpackage from "./pages/advertisement-package.js";
import Marketingpackage from "./pages/marketing-package.js";
import Packagesolution from "./pages/PackageSolution.js"

import LoginUrl from "./pages/urlLogin.js"
import Refferal from "./pages/refferal.js";
import Privacy from "./pages/privacy.js";
import Terms from "./pages/terms.js";
import About from "./pages/about.js";
import Affiliate from "./pages/affiliate.js";
import Community from "./pages/community.js";
import Hand from "./pages/hand.js";

import { GoogleOAuthProvider } from '@react-oauth/google';
import { getLang, setLang } from "./lib/localStorage.js";
import { isEmpty } from "./helper/validation.js";


const AppWrapper = () => {






  useEffect(() => {
    let lang = getLang();
    if (isEmpty(lang)) {
      i18n.changeLanguage("en")
      setLang("en")
    } else {
      i18n.changeLanguage(lang)
    }
  }, [])

  return (
    <>
      <GoogleOAuthProvider clientId={config.GoogleOauth}>

        <Provider store={store}>
          <I18nextProvider i18n={i18n}>

            <PersistGate persistor={persistor}>
              <Router>
                <Routes>
                  {/* Protect router */}
                  <Route element={<ProtectedRouter />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/marketplace" element={<Marketplace />} />
                    <Route path="/store-marketplace" element={<StoreMarketPlace />} />
                    <Route path="/megamart" element={<Megamart />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/deposit/:id" element={<Deposit />} />
                    <Route path="/withdraw/:id" element={<Withdraw />} />
                    {/* <Route path="/withdraws" element={<Withdraw />} /> */}
                    <Route path="/finance" element={<Finance />} />
                    <Route path="/myproduct" element={<Myproduct />} />
                    <Route path="/product-details/:type/:id" element={<Productdetails />} />
                    <Route path="/myproduct-details/:id" element={< MyproductInfo />} />
                    <Route path="/support-ticket" element={< Support />} />
                    <Route path="/kyc" element={< Kyc />} />
                    <Route path="/change-password" element={<Changepassword />} />
                    <Route path="/vip-marketplace" element={<VipMarketplace />} />
                    {/* <Route path="/advertisement-package" element={<Advertisementpackage />} />
                <Route path="/shipping-package" element={<ShippingPackage />} /> 
                <Route path="/marketing-package" element={<Marketingpackage />} /> */}
                    <Route path="/Packagesolution" element={<Packagesolution />} />
                    <Route path="/referral" element={<Refferal />} />
                    <Route element={<Ipchecking />} path="/Ipchecking" />
                  </Route>


                  <Route element={<Home />} path="/" />
                  {/* <Route element={<LoginUrl />} path="/Url-login/:token" /> */}
                  {/* <Route element={<Home />} path="/" /> */}
                  <Route element={<Privacy />} path="/privacy" />
                  <Route element={<Terms />} path="/terms" />
                  <Route element={<About />} path="/about" />
                  <Route element={<Affiliate />} path="/affiliate" />
                  <Route element={<Community />} path="/community" />
                  <Route element={<Hand />} path="/hand" />
                  <Route element={<LoginUrl />} path="/Url-login/:token" />

                  {/* basic router */}
                  <Route element={<LoginRouter />}>
                    <Route element={<Login />} path="/login" />
                    <Route element={<Login />} path="/login/:authToken" />
                    <Route element={<Register />} path="/register" />
                    <Route path="/forgot" element={< Forget />} />
                    <Route path="/reset-password/:token" element={<Verifypassword />} />
                    <Route path="/email-verification/:id" element={<Emailverification />} />
                    <Route path="/verify-mail" element={<Verifymail />} />
                    <Route path="/resend-Mail" element={<ResendMail />} />
                  </Route>
                </Routes>
              </Router>

              <HelperRoute />

              <ToastContainer />

            </PersistGate>

          </I18nextProvider>
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
};

export default AppWrapper;
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from "react-i18next";

//import lib
import isEmpty from "../../lib/isEmpty";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import ExchangeModal from "./ExchangeModal";
// import { currencyChange } from "../../lib/pairHelper";
import { toastAlert, textCopy } from "../../utils/toast";

const initialFormValue = {
    isModalOpen: false,
    record: {},
};

const WalletDetails = () => {

    //redux
    const currency = useSelector((state) => state.currency);
    const walletData = useSelector((state) => state.wallet);
    const priceConversion = useSelector((state) => state.priceconversion);
    // const { defaultcurrency } = useSelector((state) => state.account);
    const defaultcurrency = 'USD'

    const history = useNavigate()
    const { t, i18n } = useTranslation()

    //state
    const [showBalance, setshowBalance] = useState(false);
    const [totalUSD, setTotalUSD] = useState(0);
    const [Wallet, setWallet] = useState([]);
    const [check, setCheck] = useState(false);
    const [filter, setFilter] = useState(true)
    const [exchangeData, setExhangeData] = useState(initialFormValue);
    const [loader, setLoading] = useState(true);
    const [PriceChange, setPriceChange] = useState()

    //function
    const handleSearch = (e) => {
        let { name, value } = e.target;
        function getSearch(item) {
            return (
                item.coin.toLowerCase() == value?.toLowerCase() ||
                item.balance == value ||
                item.USDValue == value
            );
        }
        let filterDoc = Wallet.filter(getSearch);
        if (!isEmpty(filterDoc)) {
            setWallet(filterDoc);
            setFilter(true)
        } else {
            setFilter(false)
        }

        if (isEmpty(value)) {
            handleAsset();
            setFilter(true)
        }
    };

    const handleAsset = () => {
        try {
            let totalAmount = 0;
            let tempArr = [...walletData];
            currency?.length >= 0 &&
                currency.map((item, index) => {
                    let PriceCnv = priceConversion.find(
                        (el) =>
                            el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD" ||
                            el.baseSymbol == "USD" && el.convertSymbol == item.currencySymbol
                    );
                    let pairIndex =
                        tempArr &&
                        tempArr.findIndex((el) => {
                            return el._id == item._id;
                        });
                    if (pairIndex >= 0 && !isEmpty(pairIndex)) {
                        let Balance = tempArr[pairIndex].balance
                        tempArr[pairIndex] = {
                            ...tempArr[pairIndex],
                            ...{
                                image: item.image,
                                decimals: item.decimals,
                                status: item.status,
                                type: item.type,
                                USDValue: !isEmpty(PriceCnv?.convertPrice) ? parseFloat(Balance * PriceCnv.convertPrice) : Balance,
                            },
                        };
                        totalAmount += tempArr[pairIndex].USDValue;
                    }
                });

            if (defaultcurrency == "USD") {
                setTotalUSD(totalAmount);
            } else {
                let totalbalance = !isEmpty(PriceChange) ? parseFloat(totalAmount * PriceChange) : 0
                setTotalUSD(totalbalance);
            }

            setWallet(tempArr);
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log("err:------ ", err);
        }
    };


    const handleCloseModal = () => {
        setExhangeData({ isModalOpen: false, record: {} });
    };




    const handleExchange = (item) => {
        // if (isExchange) {
        setExhangeData({
            isModalOpen: true,
            record: item?.item,
        });
        // } else {
        //     toastAlert("error", t("EXCHANGE_ACCESS"), "login");
        // }

    }

    useEffect(() => {
        if (!isEmpty(walletData) && !isEmpty(currency) && !isEmpty(priceConversion)) {
            handleAsset();
        }
        if (defaultcurrency != "USD") {
            let convetCurrency = priceConversion && priceConversion.length > 0 && priceConversion.find((el) => el.baseSymbol == "USD" && el.convertSymbol == defaultcurrency)
            if (convetCurrency?.convertPrice) {
                let amount = !isEmpty(convetCurrency?.convertPrice) ? parseFloat(convetCurrency?.convertPrice) : 1
                setPriceChange(amount)
            } else {
                setPriceChange(0)
            }
        }

    }, [walletData, currency, priceConversion, defaultcurrency]);


    // console.log(Wallet, 'walletDatawalletData')
    return (
        <>
            <ExchangeModal
                isShow={exchangeData.isModalOpen}
                record={exchangeData.record}
                onHide={handleCloseModal} />


            <div className="staking_top_flex">
                <div className="wallet_bal">
                    <h4>
                        {t("TOTAL_BALANCE")} :{" "}
                        <span>
                            ${" "}
                            {/* {currencyChange(defaultcurrency)} */}
                            {showBalance ?
                                <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(totalUSD ? totalUSD : 0, 2)} />
                                : "********"}</span>
                    </h4>
                    <i
                        onClick={() => setshowBalance(showBalance ? false : true)}
                        className={showBalance ? "bi bi-eye" : "bi bi-eye-slash"}
                    ></i>
                </div>
                <div className="wallet_page_right_flex">
                    <div className="search_grp">
                        <input
                            type="text"
                            placeholder={t("SEARCH")}
                            onChange={handleSearch}
                            className="form-control"
                        />
                        <img
                            src={require("../../assets/images/search_icon.png")}
                            alt="Search"
                            className="img-fluid search_icon"
                        />
                    </div>
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            onClick={() => setCheck(check ? false : true)}
                        />
                        <label class="form-check-label" for="inlineCheckbox1">
                            {t("HIDE_ZERO_BALANCES")}
                        </label>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table wallet_table">
                    <thead>
                        <tr>
                            <th>{t("ASSET")}</th>
                            <th>{t("BALANCE")}</th>
                            <th>{t("ACTION")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Wallet?.length > 0 &&
                            Wallet.map((item, index) => {
                                let Balance = item?.balance;
                                if (filter && item?.status == "active") {
                                    if (check) {
                                        if (item?.balance > 0) {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="table_asset">
                                                            <img
                                                                src={item.image}
                                                                alt="Crypto"
                                                                className="img-fluid"
                                                                loading="lazy"
                                                            />
                                                            <span>{item.coin}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">
                                                            <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(Balance, item?.decimals)} />{" "}
                                                            &nbsp; {item?.coin}
                                                        </p>
                                                        <span>≈ $
                                                            {/* {currencyChange(defaultcurrency)}  */}
                                                            {item.USDValue ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(defaultcurrency == "USD" ? item?.USDValue : PriceChange * item?.USDValue, item?.decimals)} /> : 0}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="button_grp mt-0">
                                                            <Link to={`/deposit/${item?._id}`} className="primary_btn me-2">
                                                                {t("DEPOSIT")}
                                                            </Link>
                                                            <Link
                                                                to={`/withdraw/${item?._id}`}
                                                                className="primary_btn me-2"
                                                            >
                                                                {t("WITHDRAW")}
                                                            </Link>
                                                            <button to="#" onClick={() => {
                                                                handleExchange({
                                                                    item
                                                                });
                                                            }} className="primary_btn" >{t("EXCHANGE")}</button>


                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    } else {
                                        if (filter) {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="table_asset">
                                                            <img
                                                                src={item?.image}
                                                                alt="Crypto"
                                                                className="img-fluid"
                                                            />
                                                            <span>{item?.coin}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">
                                                            <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(Balance, item?.decimals)} />
                                                            &nbsp;{item?.coin}
                                                        </p>
                                                        <span>≈ $
                                                            {/* {currencyChange(defaultcurrency)} */}
                                                            {item.USDValue ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(defaultcurrency == "USD" ? item?.USDValue : PriceChange * item?.USDValue, item?.decimals)} /> : 0} </span>
                                                    </td>
                                                    <td>
                                                        <div className="button_grp mt-0">
                                                            <Link to={`/deposit/${item?._id}`} className="primary_btn me-2">
                                                                {t("DEPOSIT")}
                                                            </Link>
                                                            <Link
                                                                to={`/withdraw/${item?._id}`}
                                                                className="primary_btn me-2"
                                                            >
                                                                {t("WITHDRAW")}
                                                            </Link>
                                                            <button to="#" onClick={() => {
                                                                handleExchange({
                                                                    item
                                                                });
                                                            }} className="primary_btn" >{t("EXCHANGE")}</button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    }
                                }
                            })}
                        {
                            !loader && Wallet && Wallet.length <= 0 || !filter && <span style={{ position: 'center' }} >{t("THERE_NO_RECORD")}</span>
                        }
                        {loader && (
                            <span style={{ textAlign: "center", display: "inline-block" }}>
                                {t("LOADING")}...
                            </span>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default WalletDetails;

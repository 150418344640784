import React, { useEffect } from "react";
import { useTheme } from "./ThemeContext";

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <button className="toggletheme_btn" onClick={toggleTheme}>
    {theme === "light" ? (
      <i class="bi bi-sun-fill"></i>
    ) : (
      <i class="bi bi-moon-fill"></i>
    )}
  </button>
  );
};

export default ThemeSwitcher;

export default {
    "HOMEPAGE_TITLE": "Build Al powered Drop shipping Store in just 5 minutes with <span>{{DSPLATFORM}}</span>",
    "TITLE_CONTENT": "Open a business without the hassle of managing inventory, packaging, or shipping.",
    "GET_STARTED": "Get Started",
    "NEW_TITLE1": "Jumpstart your dropshipping business with <span>{{DSPLATFORM}}</span>",
    "HEADER1": "The Benefits of <span>{{DSPLATFORM}}</span>",
    "TITLE1": "The benefits of AI Dropshipping can be far more than you can imagine if you can make the most of AI power in your dropshipping business. Generally speaking, AI has the potential to improve your dropshipping business by poroviding the following benefits:",
    "HEADER2": "Save on Dropshipping Costs",
    "TITLE2": "By improving the efficiency of repetitive tasks of running a dropshipping store, you can reduce the need for manual labor and potentially lower costs. Besides, winning ads will be easier to create with the help of AI Ad generators, so you can avoid spending more on the wrong ads and campaigns.",
    "HEADER3": "Bring Personalized Shopping Experiences",
    "TITLE3": "Some AI tools can analyze customer data such as previous purchases, browsing history, and customer preferences to make personalized product recommendations and offer discounts, improving customer shopping experiences. As the customers are satisfied with your personalized services, they are more likely to make decisions on more purchases.",
    "HEADER4": "Deliver Engaging Content in Minutes",
    "TITLE41": "AI copywriting tools are the most widely used in e-commerce businesses. They have the power to create high-quality content for your website, social media posts, ads, blogs, emails, etc. more efficiently. And some AI image generators can generate compelling image and video designs for your brand.",
    "TITLE42": "Such tools are trained to generate human-like responses and can provide personalized tones and copywriting according to your preferences and engaging creatives for different social media needs!",
    "HEADER5": "Supporting your growth every step of the way",
    "TITLE5": "At DSPLATFORM, we are dedicated to fostering your personal and professional growth at every stage of your journey. Whether you're taking your first steps in a new role, advancing your skills, or aiming for leadership, our commitment is to be with you every step of the way.",
    "HEADER6": "Our supported worldwide suppliers",
    "TITLE6": "At DSPLATFORM, we take pride in our strong and diverse network of suppliers from around the globe. These partnerships are the backbone of our operations, enabling us to deliver high-quality products and services to our customers.",
    "NEW_TITLE2": "Turn your products into profits at <span> {{DSPLATFORM}}</span>",
    "HEADER7": "Pick your template",
    "TITLE7": "Our templates are designed for easy navigation on any device to ensure a great shopping experience.",
    "HEADER8": "Add your products",
    "TITLE8": "Add or import your products, including descriptions, specifications, and images.",
    "HEADER9": "Set up your shipping and taxes",
    "TITLE9": "Display location-specific tax rates and shipping options in your store.",
    "HEADER10": "Publish your store",
    "TITLE10": "Go online with one click and see updates reflected on your website instantly",
    "HEADER11": "Integrate with payment gateways",
    "TITLE11": "Connect your store with popular payment gateways or let customers pay offline.",
    "HEADER12": "Launch Your Own E-Commerce Brand!",
    "TITLE12": "Your Path to Profit Starts Here!",
    "GET_FREE": "Get Free Trial",
    "REVIEW_HEADER": "What do other entrepreneurs say about <span>{{DSPLATFORM}}</span>",
    "REVIEW_NAME1": "Landon Le",
    "REVIEW1": "I decided to step into the dropshipping business only three months ago. I had no experience and didn't know where to start. I searched for dropshipping software and found DSPLATFORM. Guess what? After three months, I have already fulfilled 50+ orders! This tool helped me find trending products in health care and organize my online store most effectively.",
    "REVIEW_NAME2": "Graciela Tyler",
    "REVIEW2": "DSPLATFORM helped me to restart my business. I lost my domestic supplier for women's jewelry and wanted to find new ways for business organization. I was afraid to start dropshipping without goods in my storage; I had never done this before. Nevertheless,I tried so, and expand the choice of lovely ceramic jewelry for my customers and boost my profit!",
    "REVIEW_NAME3": "Edison Price",
    "REVIEW3": "DSPLATFORM is not my first dropshipping tool. I tried several ones for searching for products to sell,repricing activities, and online store creation. I did not expect everything I could find in one with an affordable product price. Now, I recommend DSPLATFORM to my colleagues as the best product worth its price.",
    "REVIEW_NAME4": "Abel Nelson",
    "REVIEW4": "In our digital area, it's nice when something can do all the work for you! DSPLATFORM is the best automation tool! I manage to have five active online stores where all order fulfillment is automated. I am focusing on promotion campaigns and receiving my profit only.",
    "FREQUENTLY_ASKED_QUESTIONS": "Frequently Asked Questions?",
    "FEATURES": "Features",
    "BENEFITS": "Benefits",
    "INTEGRATIONS": "Integrations",
    "SUPPLIERS": "Suppliers",
    "FAQ": "FAQ",
    "DASHBOARD": "Dashboard",
    "SIGN_UP": "Sign Up",
    "SIGN_IN": "Sign In",
    "START_CHAT": "Start a Chat",
    "FOOTER1": "DSPLATFORM, we are not selling,",
    "FOOTER2": "We are succeeding!",
    "REGISTERED_ADDRESS": "Registered office address",
    "DSPLATFORM": "DSPLATFORM",
    "ABOUT": "About",
    "INVESTORS": "Investors",
    "PARTNERS": "Partners",
    "AFFILIATES": "Affiliates",
    "LEGAL": "Legal",
    "SERVICE_STATUS": "Service status",
    "SUPPORT": "Support",
    "MERCHANT_SUPPORT": "Merchant support",
    "HELP_CENTER": "Help center",
    "HIRE_PARTNER": "Hire a Partner",
    "COMMUNITY": "Community",
    "PRODUCTS": "Products",
    "SHOP": "Shop",
    "SHOP_PAY": "Shop Pay",
    "DSPLATFORM_PLUS": "DSPLATFORM Plus",
    "LINKPOP": "Linkpop",
    "DEVELOPERS": "Developers",
    "DSPLATFORM_DEV": "DSPLATFORM.dev",
    "API_DOCUMENT": "API Documentation",
    "DEV_DEGREE": "Dev Degree",
    "TERMS_OF_SERVICE": "Terms Of Service",
    "PRIVACY_POLICY": "Privacy Policy",
    "SITEMAP": "Sitemap",
    "PRIVACY_CHOICES": "Privacy Choices",
    "COPY_RIGHTS": "Copy right © {{year}} <a href='/'>{{title}}</a> All rights Reserved",


    "REGISTER": "Register",
    "REGISTER_HEADER": "Register with <span> {{DSPLATFORM}}</span>",
    "REGLOGIN_CONTENT": "Log in now to DSPLATFORM to better manage your dropshipping account and increase your profits!",
    "FIRST_NAME": "First Name",
    "FIRST_NAME_PLACEHOLDER": "Enter your first name",
    "FIRST_NAME_REQUIRED": "First Name Required",
    "ALPHABETS_CHECK": "Alphabets Only Allowed",
    "LAST_NAME": "Last Name",
    "LAST_NAME_PLACEHOLDER": "Enter your last name",
    "LAST_NAME_REQUIRED": "Last Name Required",
    "EMAIL": "Email Address",
    "EMAIL_PLACEHOLDER": "Enter your email address",
    "EMAIL_REQUIRED": "Email Required",
    "EMAIL_INVALID": "Invalid Email Address",
    "EMAIL_NOT_EXISTS": "Email does not exists",
    "EMAIL_NOT_FOUND": "We can't find a user with this e-mail address.",
    "EMAIL_EXISTS": "Email already exists",
    "EMAIL_NOTVERIFIED": "Email Not Verified",
    "MOBILE_NUMBER": "Mobile Number",
    "MOBILE_PLACEHOLDER": "Enter your mobile number",
    "MOBILE_NUMBER_REQUIRED": "Mobile Number Required",
    "MOBILE_NUMBER_INVALID": "Invalid mobile number",
    "MOBILE_NUMBER_EXISTS": "Mobile number already exists",
    "PASSWORD": "Password",
    "PASSWORD_PLACEHOLDER": "Enter your password",
    "PASSWORD_REQUIRED": "Password Required",
    "PASSWORD_INCORRECT": "Password incorrect",
    "PASSWORD_MIN_MAX": "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 8 and maximum 18",
    "PASSWORD_MIN": "Password must contain at least 8 characters",
    "PASSWORD_MAX": "Password must contain less then 18 characters",
    "PASSWORD_UPPER": "Password must contain 1 uppercase letter",
    "PASSWORD_LOWER": "Password must contain 1 lowercase letter",
    "PASSWORD_SPECIAL": "Password must contain 1 special character",
    "PASSWORD_NUMERIC": "Password must contain 1 numeric character",
    "YOUR_PASSWORD": "Your Password is",
    "STRONG": "Strong",
    "MEDIUM": "Medium",
    "WEAK": "Weak",
    "CONFIRM_PASSWORD": "Confirm Password",
    "CONFIRM_PASSWORD_PLACEHOLDER": "Enter your re-enter password",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm Password Required",
    "CONFIRM_PASSWORD_MISMATCH": "Password and Confirm Password Does not Match",
    "INVALID_REFERRAL_CODE": "Invalid Referral Code",
    "ALREADY_HAVE_ACCOUNT": "Already have an account",
    "LOGIN_HERE": "Login here",
    "REGISTER_HERE": "Register here",
    "LOGIN": "Login",
    "LOGIN_HEADER": "Login with <span> {{DSPLATFORM}}</span>",
    "REMEMBER_ME": "Remember me",
    "FORGOT_PASSWORD": "Forgot Password",
    "FORGOT_SUCCESS": "Success! We have e-mailed your password reset link!",
    "DONT_HAVE_ACCOUNT": "Don't have an account",
    "SUBMIT": "Submit",
    "REGISTER_SUCCESS": "Register Successfully",
    "LOGIN_SUCCESS": "Logged In Successfully",
    "ACCOUNT_LOCKED": "Your Account Locked By Admin",
    "SOMETHING_WRONG": "Something went wrong",
    "ERROR_ON_SERVER": "Error on server",
    "ERROR_OCCURED": "Error occured",
    "VERIFY_ACCOUNT": "Verify Your Account",
    "VERIFY_BOX": "Your account has been successfully registered and pending for email verification. If you're not receive email,",
    "CLICK_HERE": "Click here",
    "VERIFY_CONTENT1": "We have sent an email with confirmation link to your email address. In order to complete the signup process, please click on the confirmation link.",
    "VERIFY_CONTENT2": "If you dont receive any email, please check your spam folder or wait for few minutes to receive email. Also, please verify that you entered a valid email address during registration",
    "ALREADY_VERIFIED": "Your Email has been activated. Please login your account!",
    "ACCOUNT_VERIFIED": "Your account has been activated already. Please login your account!",
    "USER_NOT_FOUND": "User not found",
    "RESENT_SUCCESS": "Resend Successfully",
    "RESET_PASSWORD": "Reset Password",
    "CONFIRM": "Confirm",
    "RESET_SUCCESS": "Password Reset Successfully",
    "RESET_LINK_EXPIRED": "Your Reset Password Link Expired",
    "RESENT_MAIL": "We have sent you the activation link again. kindly check your Inbox/Spam folder!",
    "REQUIRED": "Required",
    "MYSTORE": "My Store / Add Store",
    "MARKETPLACE": "Marketplace",
    "VIP_MARKETPLACE": "VIP Marketplace",
    "ORDERS": "Orders",
    "ORDER": "Order",
    "FINANCE": "Finance",
    "MY_INVENTORY": "My Inventory",
    "PACKAGE_SOLUTIONS": "Packages Solutions",
    "CUSTOMER_SUPPORT": "Customer Support",
    "REFER": "Refer A Friend",
    "BALANCE": "Balance",
    "DEPOSIT": "Deposit",
    "WITHDRAW": "Withdraw",
    "SUBSCRIPTION_PACKAGE": "Subscription Package",
    "PACKAGE": "Package",
    "WELCOME": "Welcome",
    "NOTIFICATION": "Notification",
    "CLEARALL": "Clear All",
    "BANK_DETAILS": "Bank Details",
    "CHANGE_PASSWORD": "Change Password",
    "LOGOUT": "Logout",
    "CHANGE_LANG": "Change Language",
    "AMOUNT": "Amount",
    "PROCEED_PAY": "Proceed to pay",
    "PROCEED_WITHDRAW": "Proceed to withdraw",
    "DEPOSIT_PLACEHOLDER": "Enter your deposit amount",
    "WITHDRAW_PLACEHOLDER": "Enter your Withdraw Amount",
    "AMOUNT_REQUIRED": "Please enter USD Amount",
    "INVALID_AMOUNT": "Amount field must allow only number value",
    "AMOUNT_ERROR": "Amount must be greater than or equal to {{AMOUNT}} USD",
    "WITHDRAW_AMOUNT_REQUIRED": "Withdraw Amount Is Required",
    "INVALID_WITHDRAW_AMOUNT": "Only Numbers Are Allowed",
    "BALANCE_TOO_LOW": "Your Balance Is Low",
    "WITHDRAW_SUCCESS": "Withdraw Request Sent Successfully",
    "INSUFFICIENT_AMOUNT": "Insufficient Amount",
    "CURRENT_PASSWORD": "Current password",
    "NEW_PASSWORD": "New Password",
    "NEW_PASSWORD_REQUIRED": "New Password Required",
    "CONFIRM_NEW_PASSWORD": "Confirm New password",
    "NEW_CONFIRM_MISMATCH": "New Password and Confirm Password Does not Match",
    "PASSWORD_SAME": "New Password cannot be your old password",
    "TRY_ANOTHER_PASSWORD": "Please try another password",
    "CHANGE_PASSWORD_SUCCESS": "Successfully changed password",
    "NAME_BANK": "Name on Bank",
    "NAME_REQUIRED": "Name Required",
    "INVALID_NAME": "Invalid Name",
    "NAME_BANK_PLACEHOLDER": 'Enter Holder Name',
    "IBAN": "IBAN",
    "IBAN_PLACEHOLDER": "Enter IBAN",
    "IBAN_REQUIRED": "IBAN Required",
    "INVALID_IBAN": "Invalid IBAN",
    "SWIFT": "Swift bic",
    "SWIFT_PLACEHOLDER": "Enter Swift bic",
    "SWIFT_REQUIRED": "Swift bic Required",
    "INVALID_SWIFT": "Invalid Swift bic",
    "BANK_NAME": "Bank Name",
    "BANK_NAME_REQUIRED": "Bank Name Required",
    "INVALID_BANK_NAME": "Invalid Bank Name",
    "BANK_PLACEHOLDER": "Enter Bank name",
    "BANK_DETAIL_SUCCESS": "Bank Details Uploaded Successfully",
    "BANK_PENDING": "Successfully Uploaded, Waiting for Verification",
    "BANK_REJECTED": "Your Application is Rejected By The Admin",
    "SUCCESS": "Success",
    "REJECTED": "Rejected",
    "CREATE_REFERRAL": "Create Referral",
    "REFERRAL_SUCCESS": "Referal Added Successfully",
    "CREATE_SUPPORT_TICKET": "Create Support Ticket",
    "CATEGORY": "Category",
    "SELECT_CATEGORY": "Select Category",
    "CATEGORY_REQUIRED": "Category Required",
    "MESSAGE": "Message",
    "MESSAGE_REQUIRED": "Message Required",
    "MESSAGE_ERROR": "Please Enter Your Message",
    "UPLOAD_FILE": "Attachment  (Add file or Drop files here)",
    "UPLOAD_SIZE_RESTRICT": "Note: MAX 1 MB (only .jpeg, .png, .jpg, .pdf)",
    "INVALID_IMAGE": "Invalid Image Format",
    "SUPPORT_ERROR": "Support Ticket Not Submitted",
    "SUPPORT_SUCCESS": "Support Ticket  Submitted Successfully",
    "TICKET_DETAILS": "Ticket Details",
    "TICKET_ID": "Ticket ID",
    "SUB": "Sub",
    "SEND": "Send",
    "OPEN": "Open",
    "CLOSED": "Closed",
    "ALL": "All",
    "ISSUE": "Issue",
    "STATUS": "Status",
    "ADMIN": "Admin",
    "YOU": "You",
    "FILE": "File",
    "NO_RECORD": "No record",
    "CLOSE_TICKET": "Satisfied, close this ticket",
    "CLOSE_SUCCESS": "Ticket Closed Successfully",
    "REPLY_SUBMIT": "Reply Submited Successfully",
    "MESSAGE_SENT_SUCCESS": "Your message has been sent successfully",
    "TICKET_ALREADY_CLOSED": "Ticket already closed",
    "DAILY_PROFIT": "Daily Profit",
    "CURRENCY": "Currency",
    "TOTAL_ORDERS": "Total Orders",
    "TOTAL_PROFIT": "Total Profit",
    "MY_PRODUCTS": "My Products",
    "NEW_PRODUCTS": "New Products",
    "SALES_OVERVIEW": "Sales Overview",
    "DAYS": "Days",
    "WEEK": "Week",
    "MONTH": "Month",
    "OVERALL": "Overall",
    "OVERVIEW": "Overview",
    "TOTAL_SELL_COST": "Total Sell Order Cost",
    "MAINTENANCE_FEE": "Monthly Shop Maintenance Fee",
    "ADVERTISEMENT": "Advertisement",
    "SHIPPING": "Shipping",
    "STARTUP": "Startup",
    "TOTAL_FEE": "Total Fee",
    "WORLD_OVERVIEW": "Store World Coverage Overview",
    "ORDER_LIST": "Order List",
    "VIEW_ORDERS": "View more orders",
    "TOP_SELLING": "Top Selling Products",
    "ADD_PRODUCTS": "Add products",
    "PRODUCT_NOT_FOUND": "We didn`t find any products",
    "PROFIT": "Profit",
    "PRODUCT_COST": "Product Cost",
    "IMPORT_COST": "Import Cost",
    "ONE_TIME": "One Time Payment",
    "DEPOSIT_SUCCESS": "Amount Deposited Successfully",
    "PRODUCT_NAME": "Product Name",
    "ORDERID": "Order ID",
    "STORE_NAME": "StoreName",
    "DATE_TIME": "Date & Time",
    "TOTAL_SALE": "Total Sale",
    "ALL_CATEGORY": "All Categories",
    "PRODUCT_PRICE": "Product Price",
    "IMPORT_PRICE": "Import Price",
    "HIGH": "High",
    "LOW": "Low",
    "SORT_BY": "Sort by",
    "RECENTS": "Recents",
    "OLDEST": "Oldest",
    "TYPE_SOME": "Type something",
    "GALLERY": "Gallery",
    "LIST": "List",
    "ADDED_CART": "Added To Cart",
    "IMPORT_CART": "Import To Cart",
    "SUBSCRIBE": "Please Subscribe to VIP",
    "ACCOUNT_HISTORY": "Account History",
    "SEARCH": "Search",
    "ORDERS_NOT_FOUND": "We didn’t find any orders",
    "REASON": "Reason",
    "TYPE": "Type",
    "PAYMENT_ID": "Payment ID",
    "PENDING": "Pending",
    "COMPLETED": "Completed",
    "ADMIN_DEPOSIT": "Admin Deposited",
    "USER_NOT_FINISHED": "User Deposite Not Finished",
    "SUCCEEDED": "Succeeded",
    "USER": "User",
    "USER_NAME": "User Name",
    "USER_EMAIL": "User Email",
    "BEFORE_BALANCE": "Before Balance",
    "AFTER_BALANCE": "After Balance",
    "BILLED_MONTH": "Billed Monthly",
    "SUBSCRIBED": "Subscribed",
    "BUY_NOW": "Buy Now",
    "BUSINESS_DAYS": "Business Days for Shipping",
    "AIR_HUB": "Air hubs",
    "FOR": "For",
    "INCLUDES": "Includes",
    "AUTHORIZED": "Authorized Ship Center® (FASC) and Alliance Partners.",
    "ARE_YOU": "Are You sure to Buy",
    "PACKAGE_WITH": "Package With",
    "CANCEL": "Cancel",
    "INSUFFICIENT_USD": "Your USD Balance is Low",
    "SUBSCRIBED_SUCCESS": "Subscriped Successfully",
    "NO_PACKAGE": "No Package Found",
    "ADVERTISE": "Advertise to",
    "CLIENT_WORLD": "clients Worldwide",
    "AIR_EXPRESS_HUBS": "Air Express hubs",
    "AVERAGE_DAILY_PACKAGE": "Average daily package volume",
    "AIRPORTS": "Airports served",
    "SELL_COST": "Sell Cost",
    "SELECT_STORE": "Select Store",
    "VIEW_PRODUCTS": "View Product",
    "STORE_NAME": "Store Name",
    "EDIT_SUCCESS": "Edited Successfully",
    "EDIT_SELL_PRICE": "Edit Sell Price",
    "ID": "Id",
    "CREATED": "Created",
    "SELLING_PRICE": "Selling Price",
    "SELLING_PRICE_PLACEHOLDER": "Enter Selling Amount",
    "WAITING": "waiting",
    "SELLING_PRICE_REQUIRED": "Sell Cost Must not be Empty",
    "SELLILNG_PRICE_MAX": "Sell cost must be Greater than Product cost",
    "ARE_YOU_DELETE": "Are You sure to Delete",
    "DELETE_SUCCESS": "Product Deleted Successfully",
    "DELETE_NOTE": "Note: We didn't refund your Import cost for Deleting this Product",
    "PRODUCT_DETAILS": "Product Details",
    "PRODUCT_ID": "Product ID",
    "REVIEWS": "Reviews",
    "SHIPS_FROM": "Ships from",
    "22BUSINESS_DAYS": "22 Business days To",
    "US": "United States",
    "PRODUCT_INFO": "Product Info",
    "SPECIFICATIONS": "Specifications",
    "POLICY": "Policy",
    "ITEM_COST": "Item Cost",
    "NUMBER_ORDERS": "Number of Orders",
    "ADDED_DRAFT": "Added to Draft",
    "IMPORT_PRODUCT": "Import Product",
    "PRODUCT_NOT_FOUND": "Product Not Found",
    "ALREADY_DRAFT": "oops,Item Already in Draft",
    "QUANTITY": "Quantity",
    "ACTUAL_PRICE": "Actual Price",
    "USER_PROFIT": "User Profit",
    "RETURN": "Return",
    "SEARCH_PRODUCT": "Search Product or Store",
    "SELECT_DATE": "Select Date",
    "STORE": "Store",
    "SEARCH_STORE": "Search Store Name",
    "SETTINGS": "Settings",
    "ADD_STORE": "Add Store",
    "EDIT": "Edit",
    "SEARCH_ANYTHING": "Search anything",
    "STORE_LOCATION": "Store Location",
    "SEARCH_COUNTRY": "Search for a country...",
    "STORE_LOGO": "Store Logo",
    "STORE_LOGO_RESTRICT": "Acceptable format: png,jpeg,jpg",
    "UPDATE_SUCCESS": "Update Successfully",
    "STORE_NAME_REQUIRED": "Store Name Required",
    "COUNTRY_REQUIRED": "Country Required",
    "SELECT_DEFAULT": "Please Select Default Store",
    "FILE_SIZE": "File size exceeds 30 MB",
    "SELECT_COUNTRY": "Select Country",
    "STORE_NAME_PLACEHOLDER": "Enter Store Name",
    "ACCEPTABLE_SIZE": "Acceptable size: 30MB format: png,jpeg,jpg",
    "SELECT_STORE_TYPE": "Select Store Type",
    "CREATE_STORE": "Create Store",
    "STORE_ALREADY_EXISTS": "Store Already Exists",
    "STORE_SUCCESS": "Store Created Successfully",
    "NO_DATA": "No Data",
    "DRAFT_PRODUCT": "Draft Product",
    "BACK": "Back",
    "ALL_PRODUCT": "All Product",
    "FAILED": "Failed",
    "SUCCESSFUL": "Successful",
    "ACTIONS": "Actions - Fast Import",
    "IMPORT": "Import",
    "FOURTH_TAB": "fourth tab content",
    "ARE_YOU_REMOVE": "Are You Sure To Remove",
    "SELECT_YOUR_STORE": "Select Your Store",
    "SELLING_PRICE_ERROR": "No product has Selling Price",
    "SELECT_STORE_ERROR": "Please select Your store",
    "PRODUCT": "Product",
    "AVERAGE_MARKPRICE": "Average Market price",
    "ACTION": "Action",
    "SELL_PRICE": "Sell Price",
    "INSUFFICIENT_FUNDS": "Insufficient Funds",
    "REMOVE_SUCCESS": "Removed Successfully",
    "STORE_REQUIRED": "Store Required",
    "PROFIT_PER_ORDER": "Profit Per Order",
    "IMPORT_STORE": "Import Store",
    "PRODUCT_ALREADY_SELECTED": "Product already in selected store",
    "IMPORT_SUCCESS": "Product Import Successfully",
    "ALREADY_DEPOSITED": "Already Deposited",
    "CONFIRM_MAIL_SUCCESS": "Your email has been verified, you can now log in",
    "IS_ACTIVE": "Your email is already verified",
    "UNAUTHORIZED": "Unauthorized",
    "SHIPPING_TIME": "Shipping time",
    "BUSSINESS_DAYS": "Business Days",
    "PRODUCT_SUCCESS": "{{SUCCESS}} Product Import Successfully {{FAILER}} Product Failed",





    "WALLET": "Wallet",
    "BANK_DETAILS": "Bank Details",
    "DEPOSIT_TERMS": "Deposit Terms",
    "MINIMUM_DEPOSIT_AMOUNT": "Minimum Deposit Amount: ",
    "INVALID_IMAGE": "Invalid image",
    "MAINTENANCE_ERROR": "Maintenance in process try after some time!",
    "MINIMUM_DEPOSIT_AMOUNT": "Minimum Deposit Amount: ",
    "DEPOSIT_DESCRIPTION": "Deposits Less Than The Minimum Amount Will Not Be Accepted And Refunds Are Not Allowed",
    "DEPOSIT_NOTES1": "Send only {{CURRENCY_SYMBOL}} To This Wallet Address.",
    "DEPOSIT_NOTES2": "Sending Coins Or Tokens Other Than {{CURRENCY_SYMBOL}} To This Wallet May Result In The Total Loss Of Your Deposit",
    "DEPOSIT_NOTES3": "Please Upload A Screenshot Or A Hash Of Your Transaction In Order For Us To Approve Your Transaction",
    "DEPOSIT_CARD": "Deposit With Card",
    "DEPOSIT_ADDRESS": "Deposit Address",
    "MEMO_ADDRESS": "Memo Address",
    "DEPOSIT_IMAGE_RESTRICTION": "Max. upload file size 2MB (Jpg, Jpeg, Png, Pdf only).",
    "DEPOSIT_AMOUNT_ERROR": "Please enter the deposit amount is greater than or equal to {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "Deposit request sent successfully",
    "ASSET_ERROR": "User asset field is required",
    "COPIED": "Copied!",
    "DOCUMENT_UPLOAD": "Upload Document (Optional)",
    "UPLOAD": "Upload",
    "WITHDRAW_TERMS": "Withdraw Terms",
    "MINIMUM_WITHDRAW_AMOUNT": "The Minimum Withdrawal amount is: ",
    "TERMS1": "Maximum Withdraw :",
    "TERMS2": "Withdraw Fee :",
    "RECEIVER_ADDRESS": "Receiver Address",
    "RECEIVER_ADDRESS_PLACEHOLDER": "Please enter withdrawl address here",
    "RECEIVER_ADDRESS_REQUIRED": "Receiver address field is required",
    "WITHDRAW_AMOUNT": "Withdrawal Amount",
    "WITHDRAW_AMOUNT_ERROR": "Please enter withdraw amount greater than or equal to {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "Minimum withdraw amount must be greater than or equal to {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "Withdraw amount must be less than or equal to {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "Withdraw request sent successfully",
    "INSUFFICIENT_WITHDRAW": "Withdraw amount too low",
    "WITHDRAW_OTP": "An OTP has been successfully sent to your email address. Please check your inbox and use the provided code to proceed",
    "HOLDER_NAME": "Holder Name",
    "BANK_CODE": "Bank Code",
    "BANKCODE_REQUIRED": "Bank Code Required",
    "INVALID_CODE": "Invalid Code",
    "ACCOUNTNO": "Account Number",
    "ACCOUNTNO_REQUIRED": "Account Number Required",
    "INVALID_ACCOUNTNO": "Invalid Number",
    "COUNTRY": "Country",
    "TOTAL_BALANCE": "Total Balance",
    "BALANCE": "Balance",
    "EXCHANGE": "Exchange",
    "HIDE_ZERO_BALANCES": "Hide Zero Balances",
    "FROM_CURRENCY": "From Currency",
    "TO_CURRENCY": 'To Currency',
    "TO_CURRENCY_REQUIRED": "To currency field is required",
    "SELECT_CURRENCY": "Select Currency",
    "INVALID_USER": "Invalid user",
    'INSUFFICIENT_BALANCE': "Insufficient balance",
    "TRANSFER_SUCCESS": "Transfered successfully",
    "AMOUNT_CAN'T_ZERO": "Amount must be greater than zero",
    "AVAILABLE_BALANCE": "Available Balance",
    "MAX": "Max",
    "TOO_LARGE": "Too large",
    "ASSET": "Asset",
    "AMOUNT_PLACEHOLDER": "Enter Amount",
    "OTP": "OTP",
    "OTP_PLACEHOLDER": "Enter OTP",
    "RESENT_OTP": "Resend Verification Code",
    "OTP_VALIDUPTO": "OTP valid up to",
    "OTP_REQUIRED": "OTP field is required",
    "OTPSTATUS_REQUIRED": "OTP status is required",
    "INVALID_OTPSTATUS": "Invalid OTP status",
    "OTP_SENT_SUCCESS": "OTP sent to your email address, please enter a OTP",
    "EXPIRED_OTP": "Expired OTP",
    "INVALID_OTP": "Invalid OTP",
    "SEC": "Sec",
    "STORE_MARKETPLACE": "Store Marketplace",
    "STORE_PRICE": "Store Price",
    "INVALID_CURRENCY": "Invalid currency",
    "VALID_AMOUNT": "Please enter a valid amount",
    "INVALID_ASSET": "Invalid asset",
    "SITE_URL": "Site Url",
    "PAYMENTYPE": "Payment Type",
    "CURRENCYSYMBOL": "Currency Symbol",
    "COIN_DEPOSIT": "Coin_deposit",
    "COIN_WITHDRAW": "Coin_withdraw",
    "COIN_TRANSFER": "Coin-Transfer",
    "FIAT_DEPOSIT": "Fiat_deposit",
    "FIAT_WITHDRAW": "Fiat_withdraw",
    "NEW_USER": "New User",
    "BUYING_STORE": 'Buying Store',
    "BUYING_PRODUCT": "Buying Products",
    "USER_DEPOSIT": "User Deposit",
    "USER_WITHDRAW": "User Withdraw Pending",
    "USER_WITHDRAW_VERIFY": "User Withdraw Verified",
    "USER_WITHDRAW_REJECT": "User Withdraw Rejected",
    "ADMIN_WITHDRAW": "Admin Withdraw",
    "ADMIN_DEP": "Admin Deposit",
    "PROFITS": "Profits",
    "PACKAGE_SUBSCRIBE": "Package Subcribtion",
    "STORE_RENEWAL": "Store Renewal",
    "RETURN_PRODUCT": "Return Product",
    "COIN_TRANSFERS": "Coin Transfer",
    "ROWS_PER_PAGE": "Rows per page",







    "ABOUT_US": "About Us",
    "ABOUT_LIST1": " DSPLATFORM was founded",
    "ABOUT_LIST2": " Monthly active users",
    "ABOUT_LIST3": " Countries users are from",
    "ABOUT_LIST4": " Employees",
    "ABOUNT_HEAD1": "Your all-in-one platform for product research & fulfillment",
    "ABOUT_PARA1": "Good product research is key to success, but the reality of sourcing, dealing with agents, international shipping and customs is more complex than most expect. DSPLATFORM’s vision is to remove this complexity. What you see is what you can get right here in your dashboard with just a few clicks. No external automation services, no dealing with suppliers.",
    "ABOUNT_HEAD2": "Experience knowing rather than guessing",
    "ABOUT_PARA2": "DSPLATFORM’s research team works with standalone-shop, Amazon, and eBay sellers of all sizes that test assumptions and help DSPLATFORM’s analysts validate their data. Thanks to a network of partners, DSPLATFORM’s prediction methodology is not just paper-based, but largely field-tested in practice. It’s accurate research, not made-up numbers made by pretty much all so-called “sales trackers”.",
    "ABOUNT_HEAD3": "Transparency",
    "ABOUT_PARA3": "We know how frustrating it can be to be overwhelmed with tons of anonymous websites and fictional numbers. We fix that. DSPLATFORM is a company in the heart of London whose founders stand behind their research methodology and services with their names.",
    "ABOUNT_HEAD4": "Professionalism",
    "ABOUT_PARA4": "We understand your basic needs to be able to operate as a business: proper invoices, necessary product certifications, competent advice. The support team consists of native English speakers who can handle even the toughest questions and bureaucracy.",
    "ABOUNT_HEAD5": "Entrepreneurial Spirit",
    "ABOUT_PARA5": "We want you to be successful, especially if you are a beginner. That’s why we made DSPLATFORM’s dashboard as easy as possible to use, very lean, with many features for scalability and growth that we wish we would’ve had when building our first stores.",
    "ABOUNT_HEAD6": "Optimization",
    "ABOUT_PARA6": "DSPLATFORM’s dashboard is just the beginning. We improve, we add features, we ask for feedback, and we implement feedback. We believe that when quality improves, growth comes naturally. Please give us as much feedback as possible. Every bit counts!",
    "ABOUNT_HEAD7": "E-Commerce for Everyone",
    "ABOUT_PARA7": "We want fewer big players, like Amazon and Costco, and more entrepreneurs. DSPLATFORM empowers independent sellers, helping them form a diverse market for everyone. Let’s learn from each other, grow together, and help others succeed.",
    "ABOUNT_HEAD8": "Love",
    "ABOUT_PARA8": "DSPLATFORM’s team members create features they love. No one implements anything that they wouldn’t use themselves. We rally people that have a deep passion for e-commerce. This passion is shared with every single subscriber through the dashboard.",
    "AFFILIATE_PROGRAM": "Affiliate Program",
    "AFFILIATE_QUES": "Who can promote DSPLATFORM",
    "AFFILIATE_HEAD1": "Content Creators",
    "AFFILIATE_PARA1": "Employ DSPLATFORM’s ready-made content for promotion if you’re a content creator on Facebook, Instagram, or TikTok.",
    "AFFILIATE_HEAD2": "Social Media Influencers",
    "AFFILIATE_PARA2": "Earn through promoting DSPLATFORM on social media and expressing your affection for it as an influencer.",
    "AFFILIATE_HEAD3": "YouTubers",
    "AFFILIATE_PARA3": "Provide insights on DSPLATFORM through a YouTube video and include your affiliate link in the description.",
    "AFFILIATE_HEAD4": "Educators",
    "AFFILIATE_PARA4": "Transform your content into a revenue stream by educating others on how to effectively utilize DSPLATFORM.",
    "AFFILIATE_HEAD5": "Entrepreneurs",
    "AFFILIATE_PARA5": "Enhance your content’s monetization potential by guiding others in the effective use of DSPLATFORM.",
    "AFFILIATE_HEAD6": "Bloggers and Writers",
    "AFFILIATE_PARA6": "Enhance the profitability of your blog posts by incorporating DSPLATFORM’s ads and your affiliate link.",
    "UNLOCK_POTENTIAL": "Unlock your potential",
    "COMMUNITY_PARA1": "Join a group of like-minded e-commerce enthusiasts. Share experiences, ask questions, and receive advice from people who understand your journey.",
    "COMMUNITY_HEAD2": "Free Product Picks",
    "COMMUNITY_PARA2": "Get exclusive access to hand-picked products. These free selections will help you discover new items that have the potential to become the next bestsellers in your store.",
    "COMMUNITY_HEAD3": "Valuable Insights",
    "COMMUNITY_PARA3": "Learn from the collective wisdom of the community. Gain insights into what works in e-commerce, helping you make smarter business decisions.",
    "COMMUNITY_HEAD4": "Networking Opportunities",
    "COMMUNITY_PARA4": "Connect with potential partners, mentors, or even investors. Networking can open up new avenues for your business to grow.",
    "COMMUNITY_HEAD5": "News",
    "COMMUNITY_PARA5": "Keep your finger on the pulse of e-commerce trends. Being part of this community means you’ll hear about the latest news and strategies first.",
    "COMMUNITY_HEAD6": "Motivation",
    "COMMUNITY_PARA6": "Joining a supportive community can provide the encouragement needed to persevere through the challenges of running an e-commerce business.",
    "COMMUNITY_HEAD7": "Don’t dropship like it’s 2017",
    "COMMUNITY_PARA7": "Imagine someone asks you who your supplier is, and then you have to tell them it’s a private agent, or AliExpress, or an automation tool that simply buys from AliExpress for you. It’s 2024. Your customers, your revenues, and your profits will thank you.",
    "TESTIMONIALS": "TESTIMONIALS",
    "WHAT_OTHERS": "What other entrepreneurs say about DSPLATFORM",
    "SAYS_HEAD1": "Timo Herzog",
    "SAYS_PARA1": "«After 2 years in e-commerce and little success, I realized that accurate store trackers or spy tools simply don’t exist. Success in dropshipping requires proper research and a trustworthy supplier to do the job. DSPLATFORM was the game-changer I needed. Real, transparent, university-level research coupled with own logistics and automation. It made me go from hustler to entrepreneur in under 3 months.»",
    "SAYS_HEAD2": "Mario Giordano",
    "SAYS_PARA2": "«DSPLATFORM is very beginner-friendly. For dropshipping beginners, this is by far the best all-in-one solution. Products are sent out in a neutral package, so the end-customer doesn’t see it’s dropshipped. For professionals like myself, it still lacks a branding option. According to support, they’re working on it. However, it’s still the best for research and fulfillment of products that don’t require branding.»",
    "SAYS_HEAD3": "Lisa-Marie Meskendahl",
    "SAYS_PARA3": "«Great for handling multiple stores simultaneously and testing products quickly. There’s nothing comparable out there. For dropshipping, there is, but for staying lean and agile and not overinvesting, there isn’t.»",
    "SAYS_HEAD4": "Farid Saadlou",
    "SAYS_PARA4": "«Reliable worldwide shipping, full automation, transparent research. The product trends tool is especially great. I’m always ready to react to tomorrow’s demands, and the seasonality analysis helps me know when to sell what.»",
    "SAYS_HEAD5": "Agnesa Veliu",
    "SAYS_PARA5": "«When I got into e-commerce many years ago, the complexity of customs, imports and tax accounting quickly made me lose interest. I’ve always been good at marketing but bad at operations, especially legal matters. DSPLATFORM fixed these issues for me, and now I’m running and growing my store!»",
    "SAYS_HEAD6": "Dominic Galagher",
    "SAYS_PARA6": "«DSPLATFORM has hands-down the best support! They care, they ask questions, they advise you, they want you to succeed. I’m always baffled by the sheer quality of it.»",
    "SAYS_HEAD7": "Marc Malen",
    "SAYS_PARA7": "«What started as a side hustle turned into a company with now eight employees. DSPLATFORM was with me all the way and is still my go-to product research and fulfillment tool. I experiment with other suppliers, but it just requires so much micromanagement. With DSPLATFORM I know that everything will go smoothly.»",
    "SAYS_HEAD8": "Dima Voronov",
    "SAYS_PARA8": "«I connected my stores. It just works. Most all-in-one tools are just automators that connect you with dubious Chinese agents or buy from AliExpress for you. I love that DSPLATFORM is an actual supplier. VAT invoices, CE certificates, and advice from native English speakers with obvious e-commerce experience. No more begging agents or AliExpress sellers who don’t know what an invoice is.»",




}
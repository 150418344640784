import React, { useEffect } from "react";
import Navbarinner from "../components/Navbarinner.js";
import Sidebar from "../components/sidebar.js";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import { useNavigate, useParams } from "react-router-dom";

// import components
import Deposit from "../components/Deposit";

//import lib
import isEmpty from "../lib/isEmpty.js";



export default function DepositPage() {
  const { id } = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (isEmpty(id)) {
      history("/wallet");
    }
  }, [id]);


  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />
      <Navbarinner />
      <Sidebar navLink={"/wallet"} />
      <div className="innerpages_wrapper">
        <div className="container">

          {/* <div className="d-block d-lg-none">
            <div
              role="alert"
              className="primary_warning_alert alert alert-secondary"
            >
              <div className="deposite_flx">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
              >
                <path
                  d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                  fill="#00cdf9"
                />
              </svg>
              <h3>
                  Minimum deposit amount:
                </h3>
                </div>
              <div className="alertCard">
                
                <p>
                  Deposits less than the minimum amount will not be accepted
                  and refunds are not allowed
                </p>
              </div>
            </div>
          </div> */}
          <Deposit coin={id} />
        </div>
      </div>
    </div>
  );
}

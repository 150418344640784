import React, { useEffect, useState, useRef, useMemo } from "react";
import DataTable from 'react-data-table-component';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// img 
import csv from "../assets/images/csv.svg";
import xlsx from "../assets/images/xlsx.svg";
import pdf from "../assets/images/pdf.svg";
import calender from "../assets/images/calender.svg";
import search_icon from "../assets/images/search_icon.svg";
import down from "../assets/images/down.svg";
import sort_arw from "../assets/images/sort_arw.svg";
import plus1 from "../assets/images/plus1.svg";


import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link } from "react-router-dom";
import { getTransaction } from "../action/user.action.js"
import moment from "moment"
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import Sidebar from "../components/sidebar.js";

import { downloadCSV, downloadXls, downloadpdf } from "../helper/downloadFun.js"
import { useTranslation } from "react-i18next";



export default function Deposits() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [drpdwn, setdrpdwn] = useState()
    const [record, setRecord] = useState({
        data: [],
        count: 0,
    });

    const [filter, setFilter] = useState({
        limit: 10,
        page: 1,
        search: "",
        startdate: "",
        enddate: "",
    });


    const { t } = useTranslation()


    // console.log(dateRange,"dateRange");

    const getDepost = async (reqdata) => {
        try {

            var { status, result } = await getTransaction(reqdata)
            // console.log(status, 'result, totalrecords');
            if (status) {
                setRecord({
                    data: result.data,
                    count: result.count,
                });
            }
        } catch (err) {
            console.log(err, "-error")

        }
    }




    const CustomInput = ({ value, onClick }) => (
        <button className="custom_input bg-transparent" onClick={onClick} >
            <img src={calender} className="img-fluid" alt="logo" />
            <span className="date" >{value || t('SELECT_DATE')}</span>
            {!drpdwn && <img src={down} className="img-fluid" alt="logo" />}
            {drpdwn && <img src={down} className="img-fluid uparw" alt="logo" />}
        </button>

    );

    const numberWithCommas = (number) => {
        return number.toLocaleString()
    };


    const columns = [
        {
            name: t('DATE_TIME'),
            selector: row => row.createdAt,
            sortable: true,
            width: '220px',
            cell: (row) => <div>{moment(row.createdAt).format("lll")}</div>,

        },
        {
            name: t('PAYMENTYPE'),
            selector: row => row.paymentType,
            sortable: true,
            width: "265px",
            cell: (row) => {
                let data = row.paymentType

                if (row.paymentType == "coin_deposit") {
                    data = t("COIN_DEPOSIT")
                } if (row.paymentType == "coin_withdraw") {
                    data = t("COIN_WITHDRAW")
                } if (row.paymentType == "fiat_deposit") {
                    data = t("FIAT_DEPOSIT")
                } if (row.paymentType == "fiat_withdraw") {
                    data = t("FIAT_WITHDRAW")
                } if (row.paymentType == "coin-transfer") {
                    data = t("COIN_TRANSFER")
                } if (row.paymentType == "Profit") {
                    data = t("PROFIT")
                }

                return (
                    <>{data}</>
                )
            }
        },
        {
            name: t('CURRENCYSYMBOL'),
            selector: row => row.currencySymbol,
            sortable: true,
            width: "180px",
        },
        {
            name: t('AMOUNT'),
            selector: row => numberWithCommas(row.amount),
            sortable: true,
            width: "120px",
        },
        {
            name: t('TYPE'),
            selector: row => row.actionBy == "admin" ? t("ADMIN") : t("USER"),
            sortable: true,
            width: "120px",
        },
        // {
        //     name: t('REASON'),
        //     selector: row => row.reason,
        //     sortable: true,
        //     width: "160px",
        //     cell: (row) => <div className='reg'>{row?.List_type == "Admin" ?

        //         row.reason ? row.reason : row.status == "succeeded" ? t("ADMIN_DEPOSIT") : ''
        //         :
        //         row.reason ? row.reason : row.status == "requires_payment_method" ? t("USER_NOT_FINISHED") : t("SUCCEEDED")}</div>,

        // },
        {
            name: t('STATUS'),
            selector: row => row.status == "completed" ? t("SUCCESS") : row.status == "rejected" ? t("REJECTED") : t("PENDING"),
            sortable: true,
            width: "150px",
        },

    ];



    const CustomNoDataMessage = () => (
        <div className="text-center py-5 my-5" >
            <p> {t("ORDERS_NOT_FOUND")}</p>
        </div>
    );




    const handlePerRowsChange = async (newPerPage, page) => {
        let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
        setFilter(filterData);
    };
    const handlePageChange = (page) => {
        let filterData = { ...filter, ...{ page: page } };
        setFilter(filterData);
    };

    const handleSearch = async (event) => {
        let filterData = { ...filter, ...{ search: event.target.value } };
        setFilter(filterData);
    };


    useEffect(() => {
        handleStartChange(startDate)
    }, [startDate])


    useEffect(() => {
        handleEndChange(endDate)
    }, [endDate])

    const handleStartChange = (date) => {
        let newDate = new Date(date);
        newDate.setMilliseconds(0);

        let filterData = { ...filter, ...{ startdate: newDate } };
        setFilter(filterData);
    };

    const handleEndChange = (date) => {

        let newDate = new Date(date);
        newDate.setMilliseconds(0);

        let filterData = { ...filter, ...{ enddate: newDate } };
        setFilter(filterData);
    };


    useMemo(() => {
        // alert("hi")
        getDepost(filter);
    }, [filter]);


    const downloadData = (select) => {
        try {
            if (select == 1) {
                downloadCSV(record?.data, "Order List")
            } else if (select == 2) {
                downloadXls(record?.data, "Order List")
            } else if (select == 3) {
                downloadpdf(record?.data, "Order List")
            }
        } catch (err) {
            console.log(err);
        }
    }
    const customPaginationOptions = {
        rowsPerPageText: t('ROWS_PER_PAGE'),
    };



    // console.log(record, '-recordrecord')

    return (
        <div>
            <ScrollToTopOnMount />

            {/* <Sidebar navLink={"/deposits"} /> */}
            <div>
                {/* <Navbarinner /> */}
                <div>
                    {/* <h2 className="h3tag bdr mb-3" >User Deposits</h2> */}
                    <div className="categories">
                        <div className="hflx mt-3">
                            <div className="flx gap-3" >
                                <div className="input_box">
                                    <div class="input-group">


                                        <input type="text" className="form-control bg-transparent border-end-0" name="text" placeholder={t("SEARCH")}
                                            onChange={handleSearch}
                                        />
                                        <span class="input-group-text cursor-pointer bg-transparent" id="basic-addon2">
                                            <img src={search_icon} className="img-fluid" />
                                        </span>
                                    </div>
                                </div>
                                <div className="custom_date" >
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => { setDateRange(update); }}
                                        onCalendarOpen={() => { setdrpdwn(true); }}
                                        onCalendarClose={() => { setdrpdwn(false); }}
                                        customInput={<CustomInput />}
                                    />
                                </div>
                            </div>
                            {/* <div className="flx gap-2 flex-row" >
                <div style={{ cursor: "pointer" }} onClick={() => downloadData(3)} >
                  <img src={pdf} class="img-fluid" alt="img" />
                </div>
                <div  style={{ cursor: "pointer" }}onClick={() => downloadData(2)}>
                  <img src={xlsx} class="img-fluid" alt="img" />
                </div>
                <div  style={{ cursor: "pointer" }}onClick={() => downloadData(1)}>
                  <img src={csv} class="img-fluid" alt="img" />
                </div>
              </div> */}
                        </div>
                        <Scrollbars style={{ width: "100%", height: 720 }} >
                            <div className="custom_table_chg" >
                                {/* <DataTable
                  columns={columns}
                  data={orderData}
                  selectableRows
                  noDataComponent={<CustomNoDataMessage />}
                  sortIcon={<img src={sort_arw} className="img-fluid ms-2 " alt="logo" />}
                  pagination
                /> */}

                                <DataTable
                                    columns={columns}
                                    data={record?.data}
                                    paginationTotalRows={record?.count}
                                    responsive
                                    sortIcon={<img src={sort_arw} className="img-fluid ms-2 " alt="logo" />}
                                    noDataComponent={<CustomNoDataMessage />}

                                    pagination
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    paginationServer
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={customPaginationOptions}

                                />
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react'
import hide from "../assets/images/hide.svg";
import login from "../assets/images/login.svg";
import login_logo from "../assets/images/logo_dark.png";
import lock from "../assets/images/lock.svg";
import email from "../assets/images/email.svg";
import eye from "../assets/images/eye.svg";
import {
    Alert,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { CheckresetPasswordLink, resetPassword } from "../action/user.action"
import { toastAlert } from "../utils/toast";
import { useTranslation } from 'react-i18next';


const initialstate = {
    conpassword: "",
    password: ""
}


function Resetpassword() {

    const { token } = useParams()
    const { t } = useTranslation()

    const [showPassword1, setShowPassword1] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [validateError, setValidateError] = useState({});
    const [formdata, setformdata] = useState(initialstate)
    const [open, setOpen] = useState(false);
    const [errormsg, seterrormsg] = useState("");
    const [passmessage, setpassmessage] = useState("");

    const [message, setMessage] = useState("");
    const [progress, setProgress] = useState("");
    const [toasttype, settoasttype] = useState();

    const [expireStatus, setExpireStatus] = useState(false)
    const { password, conpassword } = formdata;


    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handlePassword1 = () => {
        setShowPassword1(!showPassword1);
    };


    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formdata, ...{ [name]: value } };
        setformdata(formData);
        if (name == "password") {
            handlePassword(value);
        }
    };

    const handleSubmit = async () => {
    }

    const handleClick = (data, type) => {
        setOpen(true);
        seterrormsg(data);
        settoasttype(type);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        fetchData()
    }, [])

    // const handlePassword = (passwordValue) => {
    //     const strengthChecks = {
    //         length: 0,
    //         hasUpperCase: false,
    //         hasLowerCase: false,
    //         hasDigit: false,
    //         hasSpecialChar: false,
    //     };

    //     strengthChecks.length = passwordValue.length >= 8 ? true : false;
    //     strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    //     strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    //     strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    //     strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    //     var errormsg = {};
    //     if (!strengthChecks.length) {
    //         errormsg = "Password must contain at least 8 characters";
    //     } else if (!strengthChecks.hasUpperCase) {
    //         errormsg = "Password must contain 1 uppercase letter";
    //     } else if (!strengthChecks.hasLowerCase) {
    //         errormsg = "Password must contain 1 lowercase letter";
    //     } else if (!strengthChecks.hasDigit) {
    //         errormsg = "Password must contain 1 numeric character";
    //     } else if (!strengthChecks.hasSpecialChar) {
    //         errormsg = "Password must contain 1 special character";
    //     }
    //     setpassmessage(errormsg);

    //     let verifiedList = Object.values(strengthChecks).filter((value) => value);

    //     let strength =
    //         verifiedList.length == 5
    //             ? "Strong"
    //             : verifiedList.length >= 2
    //                 ? "Medium"
    //                 : "Weak";

    //     // setPassword(passwordValue);
    //     setProgress(`${(verifiedList.length / 5) * 100}%`);
    //     setMessage(strength);

    //     // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
    // };

    const handlePassword = (passwordValue) => {
        const strengthChecks = {
            length: 0,
            length1: 0,
            hasUpperCase: false,
            hasLowerCase: false,
            hasDigit: false,
            hasSpecialChar: false
        };

        strengthChecks.length = passwordValue.length >= 8 ? true : false;
        strengthChecks.length1 = passwordValue.length < 18 ? true : false;
        strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
        strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
        strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
        var errormsg = {};
        if (!strengthChecks.length) {
            errormsg = "PASSWORD_MIN";
        } else if (!strengthChecks.length1) {
            errormsg = "PASSWORD_MAX";
        } else if (!strengthChecks.hasUpperCase) {
            errormsg = "PASSWORD_UPPER";
        } else if (!strengthChecks.hasLowerCase) {
            errormsg = "PASSWORD_LOWER";
        } else if (!strengthChecks.hasDigit) {
            errormsg = "PASSWORD_NUMERIC";
        } else if (!strengthChecks.hasSpecialChar) {
            errormsg = "PASSWORD_SPECIAL";
        }
        setpassmessage(errormsg);
        let verifiedList = Object.values(strengthChecks).filter((value) => value);
        let strength =
            verifiedList.length == 6
                ? t("STRONG")
                : verifiedList.length >= 2 && strengthChecks.length1 == true
                    ? t("MEDIUM")
                    : t("WEAK");

        setProgress(`${(verifiedList.length / 6) * 100}%`);
        setMessage(strength);
    };


    const getActiveColor = (type) => {
        if (type === "Strong") return "#8BC926";
        if (type === "Medium") return "#FEBD01";
        return "red";
    };


    const Fromvalidation = async () => {
        try {
            var validateError = {};

            if (password.trim() == "") {
                validateError.password = "PASSWORD_REQUIRED";
            } else if (Object.keys(passmessage).length != 0) {
                validateError.password = passmessage;
            }

            if (conpassword.trim() == "") {
                validateError.conpassword = "CONFIRM_PASSWORD_REQUIRED";
            } else if (password != conpassword) {
                validateError.conpassword = "CONFIRM_PASSWORD_MISMATCH";
            }

            setValidateError(validateError);
            return validateError;
        } catch (err) {
            //console.log(err);
        }
    };

    const fetchData = async () => {

        try {

            var reqbody = {
                token: token,
            };
            //console.log(reqbody);

            const result = await CheckresetPasswordLink(reqbody);

            // console.log(result, 'resultresultresult');
            if (result.status) {
                setExpireStatus(false)
            } else {
                setExpireStatus(true)
            }

        } catch (error) {

        }

    }

    const handlesubmit = async () => {
        try {
            const check = await Fromvalidation();
            var errorsSize = Object.keys(check).length;
            // //console.log(errorsSize);
            setOpen(false);
            if (errorsSize == 0) {
                // password, confirmPassword, token, reCaptcha

                var reqbody = {
                    password: password,
                    confirmPassword: conpassword,
                    token: token,
                };
                //console.log(reqbody);

                const result = await resetPassword(reqbody);

                // console.log(result);
                if (result.status) {
                    toastAlert("success", t("RESET_SUCCESS"));

                    setTimeout(() => {
                        window.location.href = "/login"
                    }, 1500);
                } else {
                    toastAlert("error", result.error);
                }
            }
        } catch (err) { }
    };







    return (
        <div className="login">

            <div className="left" >
                <div className="w-75" >
                    <h2>{t("RESET_PASSWORD")} <span> </span></h2>
                    {
                        expireStatus ?
                            <Alert key={"danger"} variant={"danger"} className="p-2">
                                <i className="me-2 fa-solid fa-circle-xmark"></i>
                                {t("RESET_LINK_EXPIRED")}
                            </Alert> : ''
                    }



                    <div className="input_box mb-4">
                        <label className="mb-3" >{t("PASSWORD")}</label>
                        <div class="input-group">
                            <span class="input-group-text">
                                <img src={lock} className="img-fluid" alt="img" />
                            </span>
                            <input type={showPassword1 ? 'text' : 'password'}
                                class="form-control py-3" name="password"
                                placeholder={t("PASSWORD_PLACEHOLDER")}
                                onChange={handleChange}
                                disabled={expireStatus}

                            />
                            <span class="input-group-text cursor-pointer" onClick={handlePassword1}>
                                <img src={showPassword1 ? eye : hide} className="img-fluid" alt="img" />
                            </span>
                        </div>

                        {/* {validateError.email && (
                            <span className="text-danger">
                                {validateError.email}
                            </span>
                        )} */}

                    </div>
                    {password.length !== 0 ? (
                        <>
                            <div className="progress-bg">
                                <div
                                    className="progress"
                                    style={{
                                        marginTop: "-24px",
                                        height: "6px",
                                        width: progress,
                                        backgroundColor: getActiveColor(t(message)),
                                    }}
                                ></div>
                            </div>
                            <p
                                className="message"
                                style={{ color: getActiveColor(t(message)) }}
                            >
                                {t("YOUR_PASSWORD")} {t(message)}
                            </p>
                        </>
                    ) : null}
                    {validateError.password && (
                        <span className="text-danger">
                            {t(validateError.password)}
                        </span>
                    )}
                    <div className="input_box mb-4">
                        <label className="mb-3" >{t("CONFIRM_PASSWORD")}</label>
                        <div class="input-group">
                            <span class="input-group-text">
                                <img src={lock} className="img-fluid" alt="img" />
                            </span>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                disabled={expireStatus}
                                class="form-control py-3" name="conpassword" placeholder={t("CONFIRM_PASSWORD_PLACEHOLDER")}
                                onChange={handleChange}
                            />

                            <span class="input-group-text cursor-pointer"
                                onClick={handleTogglePassword}>
                                <img src={showPassword ? eye : hide} className="img-fluid" alt="img" />
                            </span>
                        </div>
                        {validateError.conpassword && (
                            <span className="text-danger">
                                {t(validateError.conpassword)}
                            </span>
                        )}
                    </div>

                    <button className="primary_btn mb-4 w-100" disabled={expireStatus} onClick={handlesubmit}>{t("CONFIRM")}</button>
                    <div className="signup text-center" >{t("DONT_HAVE_ACCOUNT")}? <a href="/login"> {t("LOGIN")}</a></div>
                </div>
            </div>
            <div className="right d-none d-lg-flex">
                <div className="text-center" >
                    <img src={login_logo} className="img-fluid mb-4" alt="img" />
                    <p className="para mb-5" >
                        {t("REGLOGIN_CONTENT")}
                        {/* Log in now to DSPLATFORM to better manage your dropshipping account and increase your profits! */}
                    </p>
                    <img src={login} className="img-fluid" alt="img" />

                </div>
            </div>
        </div>
    )
}

export default Resetpassword
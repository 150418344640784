import React from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"
import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";

import WalletDetails from "../components/wallet"

const Wallet = () => {




    return (
        <>

            <div className="page_wrapper" >
                <ScrollToTopOnMount />
                <Sidebar navLink={"/wallet"} />
                <div className="w-100" >
                    <Navbarinner />
                    <div className="right_wrapper" >
                        <WalletDetails />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet;
import isEmpty from "../../lib/isEmpty";
import { ValidateEmail, validSpecialPositive, validSwift, ValidateUserName, validPositive } from "../../helper/validation"

export const WithdrawValidation = (value, t) => {
    let errors = {};

    if (isEmpty(value.receiverAddress)) {
        errors.receiverAddress = t("RECEIVER_ADDRESS_REQUIRED")
    }

    if (isEmpty(value.amount)) {
        errors.amount = t("AMOUNT_REQUIRED")
    } else if (isNaN(value.amount)) {
        errors.amount = t("VALID_AMOUNT")
    } else if (parseFloat(value.minWithdraw) > parseFloat(value.amount)) {
        errors.amount = t('MINIMUM_WITHDRAW_ERROR', { AMOUNT: value.minWithdraw });
    } else if (parseFloat(value.maxWithdraw) < parseFloat(value.amount)) {
        errors.amount = t('WITHDRAW_MAXAMOUNT_ERROR', { AMOUNT: value.maxWithdraw });
    }


    if (isEmpty(value.otpStatus.toString())) {
        errors.otpStatus = t("OTPSTATUS_REQUIRED");
    } else if (![true, false].includes(value.otpStatus)) {
        errors.otpStatus = t("INVALID_OTPSTATUS");
    } else if (value.otpStatus == true && isEmpty(value.otp)) {
        errors.otp = t("OTP_REQUIRED");
    }

    return errors;
}



export const FiatWithdrawValidation = (value, t) => {
    let errors = {};
    const regex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

    if (isEmpty(value.bankName)) {
        errors.bankName = "BANK_NAME_REQUIRED"
    } else if (!ValidateUserName(value.bankName)) {
        errors.bankName = "INVALID_BANK_NAME";
    }

    if (isEmpty(value.accountNo)) {
        errors.accountNo = "ACCOUNTNO_REQUIRED"
    } else if (!(value.accountNo).match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/)) {
        errors.accountNo = "INVALID_ACCOUNTNO";
    }

    if (isEmpty(value.ifscCode)) {
        errors.ifscCode = t("BANKCODE_REQUIRED")
    } else if (!regex.test(value.ifscCode)) {
        errors.ifscCode = "INVALID_CODE";
    }


    if (isEmpty(value.holderName)) {
        errors.holderName = t("NAME_REQUIRED")
    } else if (!ValidateUserName(value.holderName)) {
        errors.holderName = "INVALID_NAME";
    }


    if (isEmpty(value.country)) {
        errors.country = t("COUNTRY_REQUIRED")
    }

    if (isEmpty(value.amount)) {
        errors.amount = t("AMOUNT_REQUIRED")
    } else if (isNaN(value.amount)) {
        errors.amount = t("VALID_AMOUNT")
    } else if (parseFloat(value.minWithdraw) > parseFloat(value.amount)) {
        errors.amount = t('MINIMUM_WITHDRAW_ERROR', { AMOUNT: value.minWithdraw });
    } else if (parseFloat(value.maxWithdraw) < parseFloat(value.amount)) {
        errors.amount = t('WITHDRAW_MAXAMOUNT_ERROR', { AMOUNT: value.maxWithdraw });
    }


    if (isEmpty(value.otpStatus.toString())) {
        errors.otpStatus = t("OTPSTATUS_REQUIRED");
    } else if (![true, false].includes(value.otpStatus)) {
        errors.otpStatus = t("INVALID_OTPSTATUS");
    } else if (value.otpStatus == true && isEmpty(value.otp)) {
        errors.otp = t("OTP_REQUIRED");
    }

    return errors;
}

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

// import lib
import { toastAlert } from "../../utils/toast";
import { toFixedDown } from '../../lib/roundOf';
import isEmpty from '../../lib/isEmpty';
import { precentConvetPrice } from "../../lib/calculation"

//import validation
import { WithdrawValidation, FiatWithdrawValidation } from "./validation"

// import api
import { WithdrawReq, FiatWithdrawReq } from '../../action/walletAction';

const initialFormValue = {
  receiverAddress: "",
  amount: 0,
  memoAddress: "",
  accountNo: "",
  bankName: "",
  ifscCode: "",
  holderName: "",
  country: "",
};

const Withdraw = (props) => {

  //props
  const { coin } = props


  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()


  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);

  // state
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpTextBox, setOtpTextBox] = useState(false);
  const [formValue, setFormValue] = useState({})
  const [errors, setErrors] = useState({});
  const [selectAsset, setSelectAsset] = useState({});
  const [loader, setLoader] = useState(false);
  const [otp, setOTP] = useState("")
  const [otpStatus, setOTPStatus] = useState(false)
  const { receiverAddress,
    amount,
    memoAddress,
    accountNo,
    bankName,
    ifscCode,
    holderName,
    country } = formValue;



  //function
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "amount" ? toFixedDown(value, 8) : value;

    setFormValue({ ...formValue, [name]: newValue });
    if (!isEmpty(errors)) setErrors({});
  };



  const handleClick = () => {
    let Amount = toFixedDown(selectAsset?.balance, 8)
    let finalAmount = parseFloat(Amount) - precentConvetPrice(Amount, selectAsset?.withdrawFee);
    let formData = { ...formValue, ...{ ["amount"]: finalAmount } };
    setFormValue(formData);
  }



  const handleAsset = () => {
    let tempArr = [...walletData];
    currency?.length > 0 && currency.map((item, index) => {
      let pairIndex = tempArr && tempArr.findIndex((el) => {
        return el._id == item._id;
      })
      if (pairIndex >= 0 && !isEmpty(pairIndex)) {
        tempArr[pairIndex] = {
          ...tempArr[pairIndex],
          ...{
            'image': item.image,
            'type': item.type,
            'minWithdraw': item.minimumWithdraw,
            'maxWithdraw': item.maximumWithdraw,
            'withdrawFee': item.withdrawFee,
            'status': item.status
          }
        }

      }
    })
    let checkIndex = tempArr && tempArr.findIndex((el) => {
      return el._id == coin;
    })
    if (checkIndex >= 0) {
      setSelectAsset(tempArr[checkIndex])
    } else {
      let newArr = tempArr.filter((item) => { return item.status == 'active' })
      setSelectAsset(newArr[0])
    }
  }


  const handleSubmit = async (isFiat) => {
    try {
      setLoader(true);
      const reqData = {
        currencyId: coin,
        ...formValue,
        otpStatus,
        otp,
      };
      console.log(isFiat, '-isFiatisFiat')

      const validation = isFiat ? FiatWithdrawValidation(reqData, t) : WithdrawValidation(reqData, t);
      if (!isEmpty(validation)) {
        setErrors(validation);
        setLoader(false);
        return;
      }
      const action = isFiat ? FiatWithdrawReq : WithdrawReq;
      const { status, loading, message, errors, il8 } = await action(reqData, dispatch)
      setLoader(loading);
      if (status === "success") {
        setLoader(false);
        toastAlert('success', t(message), "WithdrawReq")
        setErrors({})
        setFormValue(initialFormValue)

        navigate("/wallet")
      } else if (status === "OTP") {
        setLoader(false);
        setMinutes(1);
        setSeconds(59);
        setOtpTextBox(true)
        toastAlert('success', t(message), "WithdrawReq")
        setOTPStatus(true)
      } else {
        setLoader(false);
        if (errors) {
          setErrors(errors)
        } else {
          return toastAlert('error', t(message, il8), "WithdrawReq")
        }
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "-erroro")
    }
  }



  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(interval);
        setOTPStatus(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes]);

  useEffect(() => {
    if (!isEmpty(walletData) && !isEmpty(currency))
      handleAsset()
  }, [walletData, currency])


  // useEffect(() => {
  //   const selectedAsset = walletData.find(item => item._id === coin) || {};
  //   setSelectAsset(selectedAsset);
  // }, [walletData, coin]);

  return (
    <div className="dash_wrapper">
      <div className="w-100" >
        <div className="right_wrapper page_margin_top">
        <div className="dash_box asset_dashbox_flex asset_dashbox_flex_line">
          <div className="row">
            <div className="col-lg-6">
      
                <div className="asset_dashbox_flex_left">
                  <h3 className="asset_deposit_innertitle">
                    {t("WITHDRAW_TERMS")}
                  </h3>
                  <p>{t("MINIMUM_WITHDRAW_AMOUNT")} {selectAsset?.minWithdraw} {selectAsset?.coin} </p>
                  <ul className="deposit_notes_list">
                    <li>
                      {t("TERMS1")}  {selectAsset?.maxWithdraw}
                    </li>
                    <li>
                      {t("TERMS2")}  {selectAsset?.withdrawFee}%
                    </li>
                  </ul>
                </div>
                </div>
                <div className='col-lg-6'>
                <div className="asset_dashbox_flex_right">
                  <h3 className="asset_deposit_innertitle">{selectAsset?.coin} {t("WITHDRAW")}</h3>
                  <form className="assets_form">
                    {selectAsset && selectAsset.type === 'crypto' ? (
                      <>
                        <div className="mb-3">
                          <label for="withdrawaddress" className="form-label">
                            {t("RECEIVER_ADDRESS")}
                          </label>
                          <input
                            type="text"
                            className="form-control primary_asset_inp"
                            id="receiverAddress"
                            placeholder={t("RECEIVER_ADDRESS_PLACEHOLDER")}
                            name="receiverAddress"
                            value={receiverAddress}
                            onChange={handleChange}
                          />
                          <span className='text-danger'>{t(errors?.receiverAddress)}</span>
                        </div>
                        {
                          selectAsset.coin === 'XRP' &&
                          <div className="mb-3">
                            <label for="email" className="form-label">
                              {t("MEMO_ADDRESS")}
                            </label>
                            <input
                              type="text"
                              name="memoAddress"
                              value={memoAddress}
                              onChange={handleChange}
                              className="form-control primary_asset_inp"
                            />
                            {/* <span className='text-danger'>{errors?.memoAddress}</span> */}
                          </div>
                        }
                      </>) : (
                      <>
                        <div className="mb-3">
                          <label for="withdrawaddress" className="form-label">
                            {t("ACCOUNTNO")}
                          </label>
                          <input
                            type="text"
                            className="form-control primary_asset_inp"
                            placeholder={t("ACCOUNTNO")}
                            name="accountNo"
                            value={accountNo}
                            onChange={(e) => handleChange(e)}

                          />
                          <span className='text-danger'>{t(errors?.accountNo)}</span>
                        </div>
                        <div className="mb-3">
                          <label for="email" className="form-label">
                            {t("BANK_NAME")}
                          </label>
                          <input
                            type="text"
                            name="bankName"
                            placeholder={t("BANK_PLACEHOLDER")}
                            value={bankName}
                            onChange={handleChange}
                            className="form-control primary_asset_inp"
                          />
                          <span className='text-danger'>{t(errors?.bankName)}</span>
                        </div>
                        <div className="mb-3">
                          <label for="email" className="form-label">
                            {t("BANK_CODE")}
                          </label>
                          <input
                            type="text"
                            name="ifscCode"
                            value={ifscCode}
                            placeholder={t("BANK_CODE")}
                            onChange={handleChange}
                            className="form-control primary_asset_inp"
                          />
                          <span className='text-danger'>{t(errors?.ifscCode)}</span>
                        </div>
                        <div className="mb-3">
                          <label for="email" className="form-label">
                            {t("HOLDER_NAME")}
                          </label>
                          <input
                            type="text"
                            name="holderName"
                            value={holderName}
                            placeholder={t("NAME_BANK_PLACEHOLDER")}
                            onChange={handleChange}
                            className="form-control primary_asset_inp"
                          />
                          <span className='text-danger'>{t(errors?.holderName)}</span>
                        </div>
                        <div className="mb-3">
                          <label for="email" className="form-label">
                            {t("COUNTRY")}
                          </label>
                          <input
                            type="text"
                            name="country"
                            value={country}
                            placeholder={t("COUNTRY")}
                            onChange={handleChange}
                            className="form-control primary_asset_inp"
                          />
                          <span className='text-danger'>{t(errors?.country)}</span>
                        </div>
                      </>
                    )}

                    < div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <label for="withdrawamount" className="form-label">
                          {t("WITHDRAW_AMOUNT")}
                        </label>
                        <label className="max_label"
                          onClick={handleClick}
                        >{t("MAX")}</label>
                      </div>
                      <div className="asset_deposit_grp">
                        <input
                          type="number"
                          className="form-control primary_asset_inp"
                          id="amount"
                          name="amount"
                          value={amount}
                          onChange={handleChange}
                          placeholder={t("AMOUNT_PLACEHOLDER")}
                        />
                        <span className='text-danger'>{t(errors?.amount)}</span>
                      </div>
                    </div>
                    {
                      (otpStatus === true) && (
                        <div class="mb-3">
                          <div className="d-flex align-items-center justify-content-between">
                            {
                              (minutes != 0 || seconds != 0) && otpTextBox ?
                                <p >
                                  {" "}
                                  {t("OTP_VALIDUPTO")} :{" "}
                                  {`${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}{" "} </p> : 'OTP'
                            }
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            id="otp"
                            placeholder={t("OTP_PLACEHOLDER")}
                            name='otp'
                            onChange={(e) => setOTP(e.target.value)}
                            value={otp}
                          />
                          <span className="text-danger">{t(errors?.otp)}</span>
                        </div>
                      )
                    }
                    <div className="mt-4 mb-4">
                      <button
                        className="primary_btn primary_btn_with_bg primary_btn_transform"
                        type="button"
                        onClick={() => handleSubmit(selectAsset.type === 'fiat')}
                      >
                        {loader && <i class="fas fa-spinner fa-spin"></i>} {t("WITHDRAW")}
                      </button>
                    </div>
                  </form>
                </div>
                </div>
            
           
          </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Withdraw;
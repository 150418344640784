import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useTranslation } from "react-i18next";

// img
import w1 from "../assets/images/w1.svg";
import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Affiliate() {

  const { t } = useTranslation()


  return (
    <div>
      <Navbar />
      <div className="common_page" >
        <div className="container">
          <h1 className="h2tag mb-4" ><strong>{t("AFFILIATE_PROGRAM")} </strong></h1>
          <h2><strong><em>{t("AFFILIATE_QUES")}?</em></strong></h2>

          <ul className="list-unstyled" >
            <li>
              <h5><strong>&middot;&nbsp;</strong> <strong>{t("AFFILIATE_HEAD1")}</strong> </h5>
              <p>{t("AFFILIATE_PARA1")}</p>
              <h5><strong>&middot;&nbsp;</strong><strong><strong>{t("AFFILIATE_HEAD2")}</strong></strong></h5>
              <p>{t("AFFILIATE_PARA2")}</p>
              <h5><strong>&middot;&nbsp;</strong><strong><strong>{t("AFFILIATE_HEAD3")}</strong></strong></h5>
              <p>{t("AFFILIATE_PARA3")}</p>
              <h5><strong>&middot;&nbsp;</strong><strong><strong>{t("AFFILIATE_HEAD4")}</strong></strong></h5>
              <p>{t("AFFILIATE_PARA4")}</p>
              <h5><strong>&middot;&nbsp;</strong><strong><strong>{t("AFFILIATE_HEAD5")}</strong></strong></h5>
              <p>{t("AFFILIATE_PARA5")}</p>
              <h5><strong>&middot;&nbsp;</strong><strong><strong>{t("AFFILIATE_HEAD6")}</strong></strong></h5>
              <p>{t("AFFILIATE_PARA6")}</p>
            </li>
          </ul>

        </div>
      </div>
      <Footer />
    </div>
  );
}

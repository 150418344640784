import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ship from "../../assets/images/ship.svg";
import plus1 from "../../assets/images/plus1.svg";

import Lockvip from "../../assets/images/LockImg.png";
import lock from "../../assets/images/lock.svg";

import {
  getProductList,
  setMyDraft,
  getvipProductList,
} from "../../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import config from "../../config/index";
import { getUserDraft } from "../../redux/features/draft/mydraftslice";
import { toastAlert } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
function ProductDetailSlider({ ids, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const [productlist, setproductlist] = useState([]);
  const [loading, setloading] = useState(undefined);

  const userdraft = useSelector((state) => state?.userdraft);
  const User = useSelector((state) => state?.account);

  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    getProduct();
  }, [ids]);

  const getProduct = async () => {
    try {
      var id = 0,
        search = "";

      const { result, status } = await getProductList(id, search);

      // const { result, status } = await getvipProductList(id, search)

      if (result) {
        let filteredUsers = result.filter((user) => {
          return user._id != ids;
        });
        setproductlist(filteredUsers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDraft = async (pid) => {
    try {
      // console.log(pid,);
      var reqdata = {
        productid: pid,
      };
      var { status, message } = await setMyDraft(reqdata);
      if (status) {
        setloading(pid);
        dispatch(getUserDraft());
        setTimeout(() => {
          setloading(undefined);
        }, 2000);
      } else {
        toastAlert("error", t(message));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const ButtonDisplay = (props) => {
  //     var { pid } = props
  //     const findone = userdraft.find((data) => (data.product_id == pid))
  //     return (
  //         <>
  //             {findone != undefined ?
  //                 <button className="primary_btn_success w-100 p-2 text-center"
  //                     style={{ backgroundColor: "green" }}
  //                 > <i class="fa fa-check" aria-hidden="true"></i>
  //                     Added to Draft
  //                 </button>
  //                 :
  //                 <button className="primary_btn w-100 p-2 text-center" onClick={() => handleDraft(pid)}>
  //                     Import Product
  //                 </button>

  //             }
  //         </>
  //     )

  // }

  const ButtonDisplay = (props) => {
    var { pid } = props;
    const findone = userdraft.find((data) => data.product_id == pid.product_id);
    return (
      <>
        {findone != undefined ? (
          <button
            className="primary_btn_success w-100 p-2 text-center"
            style={{ backgroundColor: "green" }}
          >
            <i class="fa fa-check" aria-hidden="true"></i>
            {t("ADDED_DRAFT")}
          </button>
        ) : pid.vip_product ? (
          <>
            <button
              className="primary_btn w-100 p-2 text-center"
              onClick={
                User.vip_status
                  ? () => navigate(`/vip-marketplace`)
                  : () => navigate(`/marketing-package`)
              }
            >
              {/* <img src={lock} className="img-fluid me-2 mb-1" alt="img" /> */}
              {t("IMPORT_PRODUCT")}
            </button>
          </>
        ) : (
          <>
            <button
              className="primary_btn w-100 p-2 text-center"
              onClick={() => handleDraft(pid.product_id)}
            >
              {/* <img src={plus1} className="img-fluid me-2 mb-1" alt="img" /> */}
              {t("IMPORT_PRODUCT")}
            </button>
          </>
        )}
      </>
    );
  };
  return (
    <>
      {productlist &&
        productlist.map((item, index) => (
          <Slider {...settings1}>
            <div>
              <div className="box">
                <div className="img_box">
                  {item.vip_product ? (
                    <a
                      style={{ cursor: "pointer" }}
                      href={
                        User.vip_status
                          ? `/product-details/Vipmarketplace/${item._id}`
                          : `/marketing-package`
                      }
                    >
                      <img
                        src={`${config.API_URL}/product/${item.product_image[0]}`}
                        class="img-fluid"
                        alt="img"
                      />
                    </a>
                  ) : (
                    <a
                      style={{ cursor: "pointer" }}
                      href={`/product-details/marketplace/${item._id}`}
                    >
                      <img
                        src={`${config.API_URL}/product/${item.product_image[0]}`}
                        class="img-fluid"
                        alt="img"
                      />
                    </a>
                  )}
                </div>
                <div className="bach">{item?.product_title}</div>

                {item.vip_product ? (
                  <>
                    <p className="sm_txt" style={{ opacity: "50%" }}>
                      {item?.description}
                    </p>
                    <img
                      style={{
                        position: "absolute",
                        marginTop: "-144px",
                        marginLeft: "50px",
                      }}
                      src={Lockvip}
                    />
                  </>
                ) : (
                  <p className="sm_txt">{item?.description}</p>
                )}
                <h5>
                  ${item?.product_cost} - ${item?.import_cost}
                </h5>
                <div className="detail">
                  <img src={ship} className="img-fluid" alt="img" />
                  <span>{t("SHIPPING_TIME")}: 2 {t("BUSSINESS_DAYS")}</span>
                </div>
                <ButtonDisplay pid={item} />
              </div>
            </div>
          </Slider>
        ))}
    </>
  );
}

export default ProductDetailSlider;

import React, { useState, useEffect } from 'react'
import { Modal, Dropdown, Nav, Tab } from 'react-bootstrap';
import config from "../../config/index"
import moment from 'moment';
import { validPositive } from "../../helper/validation"
import { isEmpty } from '../../helper/validation';
import { EditSellPrice } from "../../action/user.action"
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice"
import { toastAlert } from "../../utils/toast";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

function EditSellCostPop(props) {
    const dispatch = useDispatch();

    var { show, setshow, productInfo } = props

    const { t } = useTranslation()

    const [importAmt, setimportAmt] = useState();
    const [validation, setvalidation] = useState({});

    useEffect(() => {
        setimportAmt(productInfo.sell_cost)
    }, [productInfo])




    const handleClose = () => {
        setshow(false)
        setvalidation({})
        setimportAmt('')
    }

    const handlevalidation = () => {
        try {

            var validateError = {};

            // console.log(importAmt, "importAmt");

            if (isEmpty(importAmt)) {
                validateError.amount = "SELLING_PRICE_REQUIRED";
            }
            if (importAmt < Number(productInfo?.product?.product_cost)) {
                validateError.amount = "SELLILNG_PRICE_MAX";
            }

            setvalidation(validateError);
            return validateError;
        } catch (err) {
            console.log(err);
        }
    }





    const handleSubmit = async () => {
        try {
            const check = handlevalidation();
            var errorsSize = Object.keys(check).length;
            if (errorsSize == 0) {

                var reqdata = {
                    id: productInfo._id,
                    sellprice: importAmt
                }

                var { status, message } = await EditSellPrice(reqdata)
                // console.log(status, "status");
                if (status) {
                    dispatch(getMyproduct());
                    setshow(false)
                    toastAlert("success", t("EDIT_SUCCESS"));
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            }

        } catch (err) {
            console.log(err);

        }
    }




    return (
        <>
            <Modal show={show} centered className="custom_modal" onHide={handleClose}>
                <Modal.Header closeButton  >
                    <Modal.Title>{t("EDIT_SELL_PRICE")}</Modal.Title>
                </Modal.Header>



                <Modal.Body>


                    <div>

                    </div>
                    <div className='categories categories_chg'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className="box">
                                    <div className="img_box">
                                        <img src={`${config.API_URL}/product/${productInfo?.product?.product_image[0]}`} class="img-fluid" alt="img" />
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className="box p-0 border-0">
                                    <p className="sm_txt h-auto" >{t("ID")} : {productInfo?.product_id}</p>
                                    <div className="bol dark" >{productInfo?.product_title}</div>
                                    <p className="sm_txt h-auto" >{t("CREATED")} : {moment(productInfo?.createdAt).format("lll")}</p>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >{t("PRODUCT_COST")} :</h5>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >${productInfo?.actual_cost} </h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >{t("IMPORT_COST")} :</h5>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >${productInfo?.import_cost} </h5>
                                        </div>
                                    </div>
                                    <div className="detail" >
                                        {/* <img src={ship} className="img-fluid" alt="img" /> */}
                                        <span>{t("STATUS")}:{t("WAITING")}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="input_box">
                        <label className="sem sm_txt mb-3">{t("SELLING_PRICE")}</label>
                        <div class="input-group mb-3">
                            <input type="text" className="form-control" name="text" placeholder={t("SELLING_PRICE_PLACEHOLDER")}
                                onInput={validPositive}
                                value={importAmt}
                                onChange={(e) => { setimportAmt(e.target.value) }}

                            ></input>
                        </div>
                        {validation.amount && (
                            <span className="text-danger">
                                {t(validation.amount)}
                            </span>
                        )}
                    </div>


                    <div className="add">
                        <button className="primary_btn" onClick={handleSubmit} >{t("CONFIRM")}</button>
                    </div>
                </Modal.Body>
            </Modal >

        </>

    )
}

export default EditSellCostPop
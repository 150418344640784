import React, { useEffect, useState, useRef } from "react";
// img  
import hide from "../assets/images/hide.svg";
import login from "../assets/images/login.svg";
import login_logo from "../assets/images/logo_dark.png";
import lock from "../assets/images/lock.svg";
import email1 from "../assets/images/email.svg";
import eye from "../assets/images/eye.svg";
import {
  Alert,
} from "react-bootstrap";

import { ValidateEmail } from "../helper/validation"
import { ForgetPassword } from "../action/user.action"
import { useTranslation } from "react-i18next";



export default function Forget() {

  const { t } = useTranslation()


  const [showPassword, setShowPassword] = useState(false);
  const [email, setemail] = useState("")
  const [validateError, setValidateError] = useState({});

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };


  const Fromvalidation = async () => {
    try {
      var validateError = {};

      if (email.trim() == "") {
        validateError.email = "EMAIL_REQUIRED";
      } else if (!ValidateEmail(email)) {
        validateError.email = "EMAIL_INVALID";
      }
      setValidateError(validateError);
      return validateError;
    } catch (err) {
    }
  };

  const handlesubmit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;

      if (errorsSize == 0) {
        var reqbody = {
          email: email,
        };
        const result = await ForgetPassword(reqbody);

        if (result.status) {
          var validateError = {};
          validateError.success = "FORGOT_SUCCESS"
          setValidateError(validateError);
          setemail("");
        } else {
          var validateError = {};
          validateError.email = result.message;
          setValidateError(validateError);
        }
      }
    } catch (err) { }
  };

  return (
    <div className="login">
      <div className="left" >
        <div className="w-75" >
          <h2>{t("FORGOT_PASSWORD")}</h2>

          {validateError.success && (
            <Alert key={"success"} variant={"success"} className="p-2">
              <i className="me-2 fa fa-info-circle"></i>
              {t(validateError.success)}
            </Alert>
          )}

          <div className="input_box mb-4">
            <label className="mb-3" >{t("EMAIL")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={email1} className="img-fluid" alt="img" />
              </span>
              <input type="text" class="form-control py-3" name="name" placeholder={t("EMAIL_PLACEHOLDER")}
                value={email}
                onChange={(e) => setemail(e.target.value)} />
            </div>
            {validateError.email && (
              <span className="text-danger">
                {t(validateError.email)}
              </span>
            )}
          </div>

          <button className="primary_btn mb-4 w-100" onClick={handlesubmit}>{t("SUBMIT")}</button>
          <div className="signup text-center" >{t("ALREADY_HAVE_ACCOUNT")}? <a href="/login">{t("LOGIN_HERE")}</a></div>
        </div>
      </div>
      <div className="right d-none d-lg-flex">
        <div className="text-center" >
          <img src={login_logo} className="img-fluid mb-4" alt="img" />
          <p className="para mb-5" >
            {t("REGLOGIN_CONTENT")}
            {/* Log in now to DSPLATFORM to better manage your dropshipping account and increase your profits! */}
          </p>
          <img src={login} className="img-fluid" alt="img" />
        </div>
      </div>
    </div>
  );
}

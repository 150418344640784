import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSiteSetting } from "../action/user.action";
import { useTranslation, Trans } from "react-i18next";

// import { error } from "jquery";
export default function Footer(props) {
  const { t, i18n } = useTranslation()

  const [settting, setSiteSettings] = useState({})

  const getSiteSettings = async () => {
    try {

      const { status, result } = await getSiteSetting()

      // console.log(status, result, 'status, result')
      if (status) {
        setSiteSettings(result)
      }

    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getSiteSettings()
  }, [])
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="row">
            <div className="col-lg-3">
              <Link to="/" >
                <img src={require("../assets/images/ds-platform/dark_theme_logo.png")} class="img-fluid mt-auto mb-4" alt="img" />
              </Link>
              <h5>
                {t("FOOTER1")}
                {/* DSPLATFORM, we are not selling,  */}
              </h5>
              <h5>
                {/* We are succeeding!  */}
                {t("FOOTER2")}
              </h5>
              <div className="mt-3"> <b>
                {/* Registered office address */}
                {t("REGISTERED_ADDRESS")}
                :</b>  </div>
              {/* <div className="adr" >   Tradelle Research Ltd, </div> */}
              <div className="adr" >{settting?.address}</div>
              {/* <div className="adr" >WC2H 9JQ, London, </div> */}
              {/* <div className="mb-4 adr">United Kingdom.</div> */}
              {/* <h6 className="mb-4" >We are succeeding!</h6> */}
            </div>
            <div className="col-lg-2">
              <div className="right_box ms-lg-5">
                <h5>{t("DSPLATFORM")}</h5>
                <Link target="blank" to="/about" >{t("ABOUT")}</Link>
                <Link target="blank" to="/" >{t("INVESTORS")}</Link>
                <Link target="blank" to="/" >{t("PARTNERS")}</Link>
                <Link target="blank" to="/affiliate" >{t("AFFILIATES")}</Link>
                <Link target="blank" to="/" >{t("LEGAL")}</Link>
                <Link target="blank" to="/" >{t("SERVICE_STATUS")}</Link>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right_box ms-lg-4">
                <h5>{t("SUPPORT")}</h5>
                <Link target="blank" to="/" >{t("MERCHANT_SUPPORT")}</Link>
                <Link target="blank" to="/" >{t("HELP_CENTER")}</Link>
                <Link target="blank" to="/" >{t("HIRE_PARTNER")}</Link>
                <Link target="blank" to="/community" >{t("COMMUNITY")}</Link>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>{t("PRODUCTS")}</h5>
                <Link target="blank" to="/" >{t("SHOP")}</Link>
                <Link target="blank" to="/" >{t("SHOP_PAY")}</Link>
                <Link target="blank" to="/" >{t("DSPLATFORM_PLUS")}</Link>
                <Link target="blank" to="/" >{t("LINKPOP")}</Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="right_box">
                <h5>{t("DEVELOPERS")}</h5>
                <Link target="blank" to="/" >{t("DSPLATFORM_DEV")}</Link>
                <Link target="blank" to="/" >{t("API_DOCUMENT")}</Link>
                <Link target="blank" to="/" >{t("DEV_DEGREE")}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blw">
          <div>
            <ul className="link" >
              <li>
                <Link target="blank" to="/terms" >{t("TERMS_OF_SERVICE")} </Link>{" "}
              </li>
              <li>
                <Link target="blank" to="/privacy" >{t("PRIVACY_POLICY")}</Link>{" "}
              </li>
              <li>
                <a href="#">{t("SITEMAP")} </a>{" "}
              </li>
              <li>
                <a href="#">{t("PRIVACY_CHOICES")}</a>{" "}
              </li>
            </ul>
          </div>
          <p>
            <Trans
              i18nKey="COPY_RIGHTS"
              components={{ a: <a href="/" /> }}
              values={{ year: new Date().getFullYear(), title: 'DSPLATFORM' }}
            />
            {/* Copy right © {new Date().getFullYear()} <a href="#" className="atag" >DSPLATFORM</a>, All
              rights Reserved */}
          </p>

          {/* <ul className="footer_social_links">
            <li>
              <a href={`${settting?.facebookLink}`} target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.twitterUrl}`} target="_blank">
                <i class="fa-brands fa-x-twitter"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.youtubelink}`} target="_blank">
                <i className="fab fa-youtube"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.instagramlink}`} target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.linkedinLink}`} target="_blank">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.telegramlink}`} target="_blank">
                <i className="fab fa-telegram-plane"></i>
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
}

import React, { useEffect, useState } from "react";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";
import { getshipPack, shipplanSubscribtion } from "../action/user.action.js"

import { Modal, Dropdown, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { toastAlert } from "../utils/toast.js";
import config from "../config/index.js"
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { getUserWallet } from "../redux/features/wallet/walletslice.js";

export default function ShippingPackage() {
    const dispatch = useDispatch();

    const [packdata, setpackdata] = useState([])
    const [show3, setshow3] = useState(false)
    const [show, setshow] = useState(100000)
    const [selectpack, setselectpack] = useState([])

    const user = useSelector((state) => state.account);


    const { t } = useTranslation()

    useEffect(() => {
        getpackage()
    }, [])


    const getpackage = async () => {
        try {
            var { status, result } = await getshipPack()
            if (result) {
                setpackdata(result)

            }
        } catch (err) {
            console.log(err);

        }
    }

    var color = ["", "rose", "green", "blue"]
    var colors = ["yellow", "green", "black", "grey", "lightgrey", "violet"]

    const handleOpen = (item) => {
        setshow3(true)
        setselectpack(item)
    }




    const handleBuyPackage = async () => {
        try {

            let reqdata = {
                planid: selectpack._id
            }
            let { status, message } = await shipplanSubscribtion(reqdata)

            if (status) {
                setshow3(false)
                dispatch(getUserProfile());
                dispatch(getUserWallet())
                toastAlert("success", t(message))
            } else {
                toastAlert("error", t(message))
            }

        } catch (err) {
            console.log(err);
        }

    }

    const handleclick = (index) => {
        setshow(index);
        if (show == index) {
            setshow(10000);
        }
    }


    useEffect(() => {
        AOS.init();
    }, []);


    return (
        <div className="page_wrapper" >
            {/* <Sidebar navLink={"/shipping-package"} /> */}
            {/* <Navbarinner /> */}
            <div className="page_width" >
                <h2 className="h3tag bdr mb-5  text-center" >{t("SHIPPING")} {t("PACKAGE")}</h2>

                <div className="plan_box">

                    <div className="grid">
                        {packdata && packdata.map((item, index) => (

                            <div className={`box ${item.color}`}>
                                <div className={`abv_box`} data-aos="fade" data-aos-delay={(index + 1) * 200} data-aos-duration="1000" >
                                    <p> <strong style={{ fontSize: 'x-large' }}>{item?.package_name}</strong></p>
                                    <h2>${item?.package_amount}</h2>


                                    <span>{t("BILLED_MONTH")}</span>

                                    {user.plan_ship_type != "none" && user.plan_ship_id == item._id ?

                                        <button className="primary_btn">{t("SUBSCRIBED")}</button> :

                                        <button className="primary_btn" onClick={() => { handleOpen(item) }}>{t("BUY_NOW")}</button>

                                    }
                                </div>
                                <div className={`blw_box ${show == index ? "show" : "show"}`} >

                                    <ul>
                                        <li>
                                            <img
                                                style={{ width: "130px" }}
                                                src={`${config.API_URL}/package/${item.logo}`}
                                            />
                                        </li>
                                        <li>
                                            <strong>{item?.description}</strong>
                                        </li>

                                        <li>
                                            <strong>{item?.shipping_days}</strong>{" "}
                                            {t("BUSINESS_DAYS")}
                                        </li>
                                        <li>{t("AIR_EXPRESS_HUBS")} :{""}
                                            <strong>{item?.air_express}</strong>

                                        </li>
                                        <li>{t("AVERAGE_DAILY_PACKAGE")}:  {""}
                                            <strong>{item?.average_package}</strong>
                                        </li>
                                        <li > {t("AIRPORTS")} :{""} <strong>{item?.airports}</strong>
                                        </li>
                                        <li> <strong>{item?.authorized}</strong>  {t("AUTHORIZED")}
                                        </li>


                                    </ul>


                                </div>
                            </div>))}



                    </div>
                </div>
            </div>

            <Modal show={show3} centered size="sm" className="custom_modal" >
                <Modal.Header className="mb-3"  >
                    <div className="cursor-pointer" >
                        <h6>{t("ARE_YOU")} <strong>{selectpack.package_name}</strong> {t("PACKAGE_WITH")}
                            <strong> {selectpack.package_amount}</strong> USD?</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <button class="secondary_btn" style={{ backgound: "none !important" }} onClick={() => setshow3(false)}> {t("CANCEL")}
                    </button>
                    &nbsp; &nbsp;
                    <button class="primary_btn" onClick={handleBuyPackage} >  {t("CONFIRM")}
                    </button>
                </Modal.Body>
            </Modal>


        </div >



    );
}

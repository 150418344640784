import React, { useEffect, useState, useRef } from "react";
// img
import hide from "../assets/images/hide.svg";
import login from "../assets/images/login.svg";
import lock from "../assets/images/lock.svg";
import email from "../assets/images/email.svg";
import eye from "../assets/images/eye.svg";
import user from "../assets/images/user.svg";
import mobile from "../assets/images/mobile.svg";
import register_logo from "../assets/images/logo_dark.png";
import { ValidateUserName, validSpecialPositive, ValidateEmail } from "../helper/validation";
import { registerUser, isValidCampign } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { validPositive } from "../helper/validation";
import { useTranslation, Trans } from "react-i18next";
// import { useParams } from "react-router-dom";

let initialstate = {
  Username: "",
  lastname: "",
  emailId: "",
  phone: "",
  password: "",
  confirmpassword: ""
};

export default function Register() {
  // const { CampaignId } = useParams();

  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false);
  const [reshowPassword, setreShowPassword] = useState(false);

  const [formdata, setformdata] = useState(initialstate);
  const [validateError, setValidateError] = useState({});
  const [passmessage, setpassmessage] = useState("");

  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [disable, setDisable] = useState(false)
  // const [isValidC, setIsValidC] = useState(false);

  const { Username, lastname, emailId, phone, password, confirmpassword } = formdata;

  // useEffect(() => {
  //   checkValidCapmCode();
  // }, [CampaignId]);

  // const checkValidCapmCode = async () => {
  //   if (CampaignId) {
  //     console.log("Hi");
  //     const payload = {
  //       refCode: CampaignId
  //     };
  //     const check = await isValidCampign(payload);
  //     console.log(check,'check');
  //     if (check.result) {
  //       setIsValidC(true);
  //     }
  //   }
  // };

  const handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formData = { ...formdata, ...{ [name]: value } };
      setformdata(formData);
      if (name == "password") {
        handlePassword1(value);
      }
    } catch (err) { }
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (Username.trim() == "") {
        validateError.name = "FIRST_NAME_REQUIRED";
      } else if (!ValidateUserName(Username)) {
        validateError.name = "ALPHABETS_CHECK";
      }

      if (lastname.trim() == "") {
        validateError.lastname = "LAST_NAME_REQUIRED";
      } else if (!ValidateUserName(lastname)) {
        validateError.lastname = "ALPHABETS_CHECK";
      }


      if (emailId.trim() == "") {
        validateError.email = "EMAIL_REQUIRED";
      } else if (!ValidateEmail(emailId)) {
        validateError.email = "EMAIL_INVALID";
      }
      if (phone.trim() == "") {
        validateError.phoneNo = "MOBILE_NUMBER_REQUIRED";
      }

      if (password.trim() == "") {
        validateError.password = "PASSWORD_REQUIRED";
      } else if (Object.keys(passmessage).length != 0) {
        validateError.password = passmessage;
      }
      if (confirmpassword.trim() == "") {
        validateError.confirmpassword = "CONFIRM_PASSWORD_REQUIRED";
      } else if (password != confirmpassword) {
        validateError.confirmpassword =
          "CONFIRM_PASSWORD_MISMATCH";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqbody = {
          email: emailId,
          password: password,
          phone: phone,
          confirmPassword: confirmpassword,
          name: Username,
          lastname: lastname
          // CampaignId:CampaignId
        };
        setDisable(true)
        const data = await registerUser(reqbody);

        if (data.status == false) {
          setDisable(false)
          // setloading(false);
          var validateError = {};
          validateError.email = data.error.email;
          validateError.password = data.error.password;
          validateError.phoneNo = data.error.phoneNo;
          setValidateError(validateError);
        } else {
          toastAlert("success", t("REGISTER_SUCCESS"));
          sessionStorage.setItem("USER_AUTH_VERIFY_TOKEN_DATA", data.result);
          setDisable(false)
          setTimeout(() => {
            window.location.href = "/verify-mail";
          }, [1500]);
        }
      }
    } catch (err) {
      console.log(err);
      setDisable(false)
    }
  };

  const handlePassword1 = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      length1: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.length1 = passwordValue.length < 18 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
    var errormsg = {};
    if (!strengthChecks.length) {
      errormsg = "PASSWORD_MIN";
    } else if (!strengthChecks.length1) {
      errormsg = "PASSWORD_MAX";
    } else if (!strengthChecks.hasUpperCase) {
      errormsg = "PASSWORD_UPPER";
    } else if (!strengthChecks.hasLowerCase) {
      errormsg = "PASSWORD_LOWER";
    } else if (!strengthChecks.hasDigit) {
      errormsg = "PASSWORD_NUMERIC";
    } else if (!strengthChecks.hasSpecialChar) {
      errormsg = "PASSWORD_SPECIAL";
    }
    setpassmessage(errormsg);
    let verifiedList = Object.values(strengthChecks).filter((value) => value);
    let strength =
      verifiedList.length == 6
        ? t("STRONG")
        : verifiedList.length >= 2 && strengthChecks.length1 == true
          ? t("MEDIUM")
          : t("WEAK");

    setProgress(`${(verifiedList.length / 6) * 100}%`);
    setMessage(strength);
  };

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "red";
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const rePassword = () => {
    setreShowPassword(!reshowPassword);
  };

  const containedChange = (event) => {
    if (event.key.length === 1 && event.code.startsWith('Key') && event.code.length === 4 && event.target.value.indexOf(event.key) !== -1) {
      event.preventDefault()
      return false;
    }

    return true
  }



  return (
    <div className="login">
      <div className="left">
        <div className="w-75">
          <h2>
            <Trans i18nKey="REGISTER_HEADER" components={{ span: <span /> }} values={{ DSPLATFORM: 'DSPLATFORM' }} />

            {/* Register with <span> DSPLATFORM</span> */}
          </h2>

          <div className="input_box mb-4">
            <label className="mb-3">{t("FIRST_NAME")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={user} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                placeholder={t("FIRST_NAME_PLACEHOLDER")}
                name="Username"
                onChange={handleChange}

              />
            </div>{" "}
            {validateError.name && (
              <span className="text-danger">{t(validateError.name)}</span>
            )}
          </div>
          <div className="input_box mb-4">
            <label className="mb-3">{t("LAST_NAME")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={user} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                placeholder={t("LAST_NAME_PLACEHOLDER")}
                name="lastname"
                onChange={handleChange}

              />
            </div>{" "}
            {validateError.lastname && (
              <span className="text-danger">{t(validateError.lastname)}</span>
            )}
          </div>
          <div className="input_box mb-4">
            <label className="mb-3">{t("EMAIL")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={email} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                placeholder={t("EMAIL_PLACEHOLDER")}
                name="emailId"
                onChange={handleChange}
              />
            </div>
            {validateError.email && (
              <span className="text-danger">{t(validateError.email)}</span>
            )}
          </div>

          <div className="input_box mb-4">
            <label className="mb-3">{t("MOBILE_NUMBER")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={mobile} className="img-fluid" alt="img" />
              </span>
              <input
                // type={phone ? "number" : 'text'}
                class="form-control py-3"
                placeholder={t("MOBILE_PLACEHOLDER")}
                name="phone"
                onChange={handleChange}
                onInput={validPositive}

              />
            </div>

            {validateError.phoneNo && (
              <span className="text-danger">{t(validateError.phoneNo)}</span>
            )}
          </div>

          <div className="input_box mb-4">
            <label className="mb-3">{t("PASSWORD")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={lock} className="img-fluid" alt="img" />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                class="form-control py-3"
                name="password"
                placeholder={t("PASSWORD_PLACEHOLDER")}
                onChange={handleChange}
              />
              <span
                class="input-group-text cursor-pointer"
                onClick={handlePassword}
              >
                <img
                  src={showPassword ? eye : hide}
                  className="img-fluid"
                  alt="img"
                />
              </span>
            </div>
            {validateError.password && (
              <span className="text-danger">{t(validateError.password)}</span>
            )}
          </div>
          {password.length !== 0 ? (
            <>
              <div className="progress-bg">
                <div
                  className="progress"
                  style={{
                    marginTop: "-24px",
                    height: "6px",
                    width: progress,
                    backgroundColor: getActiveColor(t(message))
                  }}
                ></div>
              </div>
              <p className="message" style={{ color: getActiveColor(t(message)) }}>
                {t("YOUR_PASSWORD")} {t(message)}
              </p>
            </>
          ) : null}

          <div className="input_box mb-4">
            <label className="mb-3">{t("CONFIRM_PASSWORD")}</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={lock} className="img-fluid" alt="img" />
              </span>
              <input
                type={reshowPassword ? "text" : "password"}
                class="form-control py-3"
                name="confirmpassword"
                placeholder={t("CONFIRM_PASSWORD_PLACEHOLDER")}
                onChange={handleChange}
              />
              <span
                class="input-group-text cursor-pointer"
                onClick={rePassword}
              >
                <img
                  src={reshowPassword ? eye : hide}
                  className="img-fluid"
                  alt="img"
                />
              </span>
            </div>{" "}
            {validateError.confirmpassword && (
              <span className="text-danger">
                {t(validateError.confirmpassword)}
              </span>
            )}
          </div>

          <button
            className="primary_btn mb-4 w-100"
            onClick={handleSubmit}
            disabled={disable}
          >
            {/* {isValidC ? "Register" : "Camp ID Not Valid"} */}
            {t("REGISTER")}
          </button>
          <div className="signup text-center">
            {t("ALREADY_HAVE_ACCOUNT")}? <a href="/login">{t("LOGIN_HERE")}</a>
          </div>
        </div>
      </div>
      <div className="right d-none d-lg-flex">
        <div className="text-center">
          <img src={register_logo} className="img-fluid mb-4" alt="img" />
          <p className="para mb-5">
            {t("REGLOGIN_CONTENT")}
          </p>
          <img src={login} className="img-fluid" alt="img" />
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import {
    Alert,
} from "react-bootstrap";
import login from "../assets/images/login.svg";
import login_logo from "../assets/images/logo_dark.png";
import { useTranslation } from "react-i18next";

function ResendMail() {

    const { t } = useTranslation()
    return (
        <>
            <div className="login">
                <div className="left" >
                    <div className="w-75" >
                        <h2>{t("VERIFY_ACCOUNT")}</h2>

                        <Alert key={"primary"} variant={"primary"} className="p-2">
                            <i className="me-2 fa fa-info-circle"></i>
                            {t("RESENT_MAIL")}
                        </Alert>
                        <br></br>
                        <a href="/login"
                            className="primary_btn mb-4 w-100"                    >
                            {t("SIGN_IN")}
                        </a>
                    </div>
                </div>
                <div className="right d-none d-lg-flex">
                    <div className="text-center" >
                        <img src={login_logo} className="img-fluid mb-4" alt="img" />
                        <p className="para mb-5" >{t("REGLOGIN_CONTENT")}</p>
                        <img src={login} className="img-fluid" alt="img" />

                    </div>
                </div>
            </div>

        </>


    )
}

export default ResendMail
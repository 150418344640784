import React, { useEffect, useState } from "react";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";
import { getAdsPack, AdsplanSubscribtion } from "../action/user.action.js"

import { Modal, Dropdown, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { toastAlert } from "../utils/toast.js";
import config from '../config/index.js'

import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { getUserWallet } from "../redux/features/wallet/walletslice.js";

export default function Advertisementpackage() {
  const dispatch = useDispatch();

  const [packdata, setpackdata] = useState([])
  const [show3, setshow3] = useState(false)
  const [show, setshow] = useState(100000)
  const [selectpack, setselectpack] = useState([])

  const user = useSelector((state) => state.account);

  const { t } = useTranslation()

  useEffect(() => {
    getpackage()
  }, [])

  const getpackage = async () => {
    try {
      var { status, result } = await getAdsPack()

      // console.log(result, 'result');
      if (result) {
        let arr1 = []

        // await result.map(val => {
        //   Object.keys(val.packList[0]).forEach(key => {
        //     console.log('key', key, val.packList[0][key]);
        //     arr1.push({ [key]: val.packList[0][key] })
        //   });

        //   console.log(arr1, 'packdatapackdatapackdata');
        //   val.pack = arr1
        //   arr1 = []
        // })
        setpackdata(result)

      }
    } catch (err) {
      console.log(err);

    }
  }

  // console.log(packdata, 'packdatapackdata');

  var color = ["", "rose", "green", "blue"]
  var colors = ["yellow", "green", "black", "grey", "lightgrey", "violet"]

  const handleOpen = (item) => {
    setshow3(true)
    setselectpack(item)
  }


  const handleBuyPackage = async () => {
    try {

      let reqdata = {
        planid: selectpack._id
      }
      let { status, message } = await AdsplanSubscribtion(reqdata)



      if (status) {
        setshow3(false)
        dispatch(getUserProfile());
        dispatch(getUserWallet())
        toastAlert("success", t(message))
      } else {
        toastAlert("error", t(message))
      }

    } catch (err) {
      console.log(err);
    }

  }

  const handleclick = (index) => {
    setshow(index);
    if (show == index) {
      setshow(10000);
    }
  }

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="page_wrapper" >
      {/* <Sidebar navLink={"/advertisement-package"} /> */}
      {/* <Navbarinner /> */}
      <div className="page_width" >
        <h2 className="h3tag bdr mb-5 text-center" >{t("ADVERTISEMENT")} {t("PACKAGE")}</h2>
        <div className="plan_box">
          {/* <h3 className="text-center" >Pricing Plans for Everyone</h3>
            <p className="text-center px-lg-5 mx-lg-5">Maecenas sem massa, porttitor vestibulum magna et, porta auctor ex. Donec bibendum turpis non consectetur lobortis. Donec nisl dolor, ornare ac risus vitae, consequat accumsan ex. Phasellus ultricies dolor non ullamcorper elementum. Duis ultricies ex sed mi blandit sagittis. Sed mollis sollicitudin magna, ut varius urna consequat vitae.</p> */}

          <div className="grid">
            {packdata && packdata.map((item, index) => (


              <div className={`box ${item?.color}`} >
                <div className={`abv_box`} data-aos="fade" data-aos-delay={(index + 1) * 200} data-aos-duration="1000">
                  <p> <strong style={{ fontSize: 'x-large' }}>{item?.package_name}</strong></p>
                  <h2>${item?.package_amount}</h2>


                  <span>{t("BILLED_MONTH")}</span>

                  {user.plan_ads_type != "none" && user.plan_ads_id == item._id ?

                    <button className="primary_btn">{t("SUBSCRIBED")}</button> :

                    <button className="primary_btn" onClick={() => { handleOpen(item) }}>{t("BUY_NOW")}</button>

                  }
                </div>
                <div className={`blw_box ${show == index ? "show" : "show"}`} >

                  <ul>
                    <li>{t("ADVERTISE")} {item?.Advertise} {t("CLIENT_WORLD")}</li>
                    <img src={`${config.API_URL}/package/${item.Image}`} alt="img" style={{ width: "130px" }} />
                    {
                      item?.packList?.length > 0 && item?.packList?.map((val, i) => (
                        <>

                          <li  >{val.list}
                          </li>
                        </>
                      ))
                    }
                    {/* <li className={item?.Facebook ? "" : "text_line"} >Facebook ADs
                      </li>
                      <li className={item?.Google ? "" : "text_line"} >Google Ads + YouTube
                      </li>
                      <li className={item?.Taboola ? "" : "text_line"} >Taboola ADs
                      </li> */}
                  </ul>

                </div>
              </div>))}



          </div>
        </div>
      </div>

      <Modal show={show3} centered size="sm" className="custom_modal" >
        <Modal.Header className="mb-3"  >
          <div className="cursor-pointer" >
            <h6>{t("ARE_YOU")} <strong>{selectpack.package_name}</strong> {t("PACKAGE_WITH")}
              <strong> {selectpack.package_amount}</strong> USD?</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button class="secondary_btn" style={{ backgound: "none !important" }} onClick={() => setshow3(false)}> {t("CANCEL")}
          </button>
          &nbsp; &nbsp;
          <button class="primary_btn" onClick={handleBuyPackage} > {t("CONFIRM")}
          </button>
        </Modal.Body>
      </Modal>


    </div >



  );
}

import React from 'react'
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import { deleteMyProduct } from "../../action/user.action"
import { toastAlert } from "../../utils/toast";
import {
    Alert,
} from "react-bootstrap";
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function DeletePop(props) {
    var { showdelete, setshowdelete, productid } = props
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const HandleDelete = async () => {
        try {

            var reqdata = {
                id: productid._id
            }
            var { status, message } = await deleteMyProduct(reqdata)
            if (status) {
                toastAlert("success", t("DELETE_SUCCESS"))
                dispatch(getMyproduct());


                setTimeout(() => {
                    window.location.href = "/myproduct"
                }, (1000));
            }


        } catch (err) {
            console.log(err);
        }
    }




    return (
        <>

            <Modal show={showdelete} size="sm" className="custom_modal">

                <Modal.Header className="mb-3">

                    {/* <br></br> */}
                    <div className="cursor-pointer">
                        <h6>{t("ARE_YOU_DELETE")}?</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <button
                        class="secondary_btn"
                        style={{ backgound: "none !important" }}
                        onClick={() => setshowdelete(false)}
                    >
                        {" "}
                        {t("CANCEL")}
                    </button>
                    &nbsp; &nbsp;
                    <button class="primary_btn" onClick={HandleDelete}>
                        {" "}
                        {t("CONFIRM")}
                    </button>
                </Modal.Body>
                <br></br>
                <Alert key={"success"} variant={"warning"} className="p-1">
                    <i className="me-2 fa-solid fa-info-circle"></i>
                    {t("DELETE_NOTE")}
                </Alert>
            </Modal>
        </>
    )
}

export default DeletePop
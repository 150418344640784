import React, { useEffect, useState } from 'react'
import { InputGroup, Form, Accordion } from "react-bootstrap";
import usr from "../../assets/images/usr.svg";
import { Modal, Dropdown } from 'react-bootstrap';
import { getSupportCategory, createticket, createReferral } from "../../action/user.action"
import { toastAlert } from "../../utils/toast";
import { isEmpty } from '../../helper/validation';
import { useTranslation } from "react-i18next";


const InitialState = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: ''
}

function CreateReferal() {
    const [allCat, setallCat] = useState([]);
    const [formValue, setFormValue] = useState(InitialState)
    const [selectedValue, setSelectedValue] = useState(null);
    const [message1, setmessage] = useState("")
    const [file, setfile] = useState("")
    const [validateError, setValidateError] = useState({});
    const { firstName, lastName, email, mobile } = formValue


    const { t } = useTranslation()





    const Fromvalidation = async (value) => {
        try {
            var validateError = {};
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const modileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
            const name = /^[a-zA-Z\\s]*$/
            if (isEmpty(value.firstName)) {
                validateError.firstName = "FIRST_NAME_REQUIRED";
            }
            else
                if (name.test(value.firstName) == false) {
                    validateError.firstName = "ALPHABETS_CHECK";
                }
            if (isEmpty(value.lastName)) {
                validateError.lastName = "LAST_NAME_REQUIRED";
            }
            else
                if (name.test(value.lastName) == false) {
                    validateError.lastName = "ALPHABETS_CHECK";
                }
            if (isEmpty(value.email)) {
                validateError.email = "EMAIL_REQUIRED";
            } else
                if (emailRegex.test(value.email) == false) {
                    validateError.email = "EMAIL_INVALID";
                }
            if (isEmpty(value.mobile)) {
                validateError.mobile = "MOBILE_NUMBER_REQUIRED";
            } else
                if (modileRegex.test(value.mobile) == false) {
                    validateError.mobile = "MOBILE_NUMBER_INVALID";
                }

            setValidateError(validateError);
            return validateError;
        } catch (err) {
            console.log(err);
        }
    };


    const changeHandler = async (e) => {
        const value = e.target.value
        const id = e.target.id
        setFormValue({ ...formValue, [id]: value })
    }


    const handelsumbit = async () => {
        try {
            const check = await Fromvalidation(formValue);
            // var errorsSize = Object.keys(check).length;
            if (isEmpty(check)) {

                // console.log('good to go');

                const { status, message } = await createReferral(formValue);

                if (status == true) {
                    toastAlert("success", t(message));
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                } else {
                    toastAlert("error", t(message));
                }
            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div className="custom_faq mb-4 p-4">
            <h5 className="mb-3" >{t("CREATE_REFERRAL")}</h5>
            <div className="input_box mb-4">
                <label className="sem sm_txt mb-3" >{t("FIRST_NAME")}</label>
                <InputGroup>
                    <Form.Control
                        aria-label="amount"
                        aria-describedby="basic-addon1"
                        placeholder={t("FIRST_NAME")}
                        id='firstName'
                        value={firstName}
                        onChange={changeHandler}
                    />
                </InputGroup>
                {validateError.firstName && (
                    <span className="text-danger">{t(validateError.firstName)}</span>
                )}
            </div>
            <div className="input_box mb-4">
                <label className="sem sm_txt mb-3" >{t("LAST_NAME")}</label>
                <InputGroup>
                    <Form.Control
                        aria-label="amount"
                        id='lastName'
                        aria-describedby="basic-addon1"
                        placeholder={t("LAST_NAME")}
                        value={lastName}
                        onChange={changeHandler}

                    />
                </InputGroup>
                {validateError.lastName && (
                    <span className="text-danger">{t(validateError.lastName)}</span>
                )}
            </div>
            <div className="input_box mb-4">
                <label className="darkgrey mb-2" >{t("EMAIL")}</label>
                <InputGroup>
                    <Form.Control
                        aria-label="amount"
                        aria-describedby="basic-addon1"
                        placeholder={t("EMAIL")}
                        id='email'
                        value={email}
                        onChange={changeHandler}
                    />
                </InputGroup>
                {validateError.email && (
                    <span className="text-danger">{t(validateError.email)}</span>
                )}
            </div>
            <div className="input_box mb-4">
                <label className="darkgrey mb-2" >{t("MOBILE_NUMBER")}</label>
                <InputGroup>
                    <Form.Control
                        aria-label="amount"
                        aria-describedby="basic-addon1"
                        placeholder={t("MOBILE_NUMBER")}
                        id='mobile'
                        value={mobile}
                        onChange={changeHandler}
                    />
                </InputGroup>
                {validateError.mobile && (
                    <span className="text-danger">{t(validateError.mobile)}</span>
                )}
            </div>


            <div className="blue_btn" >
                <button variant="contained" className="w-auto primary_btn" onClick={handelsumbit} >{t("SUBMIT")}</button>
            </div>
        </div>
    )
}

export default CreateReferal
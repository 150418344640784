export default {

    "HOMEPAGE_TITLE": "Créez une boutique de dropshipping alimentée par l'IA en seulement 5 minutes avec <span>{{DSPLATFORM}}</span>",
    "TITLE_CONTENT": "Ouvrez une entreprise sans les tracas de la gestion des stocks, de l'emballage ou de l'expédition.",
    "GET_STARTED": "Commencer",
    "NEW_TITLE1": "Démarrez votre entreprise de dropshipping avec <span>{{DSPLATFORM}}</span>",
    "HEADER1": "Les avantages de <span>{{DSPLATFORM}}</span>",
    "TITLE1": "Les avantages du dropshipping alimenté par l'IA peuvent être bien plus importants que vous ne l'imaginez si vous tirez parti de la puissance de l'IA dans votre entreprise de dropshipping. En général, l'IA a le potentiel d'améliorer votre entreprise de dropshipping en fournissant les avantages suivants :",
    "HEADER2": "Économisez sur les coûts de dropshipping",
    "TITLE2": "En améliorant l'efficacité des tâches répétitives de gestion d'une boutique de dropshipping, vous pouvez réduire le besoin de main-d'œuvre manuelle et potentiellement diminuer les coûts. De plus, créer des publicités gagnantes sera plus facile grâce à des générateurs de publicités IA, vous évitant ainsi de dépenser plus pour des publicités et des campagnes inefficaces.",
    "HEADER3": "Offrez des expériences d'achat personnalisées",
    "TITLE3": "Certains outils d'IA peuvent analyser les données des clients telles que les achats précédents, l'historique de navigation et les préférences des clients pour faire des recommandations de produits personnalisées et offrir des réductions, améliorant ainsi les expériences d'achat des clients. Comme les clients sont satisfaits de vos services personnalisés, ils sont plus susceptibles de prendre des décisions d'achat supplémentaires.",
    "HEADER4": "Livrez du contenu engageant en quelques minutes",
    "TITLE41": "Les outils de rédaction IA sont les plus largement utilisés dans les entreprises de e-commerce. Ils ont le pouvoir de créer du contenu de haute qualité pour votre site web, vos publications sur les réseaux sociaux, vos publicités, vos blogs, vos e-mails, etc. plus efficacement. De plus, certains générateurs d'images IA peuvent créer des designs d'images et de vidéos attrayants pour votre marque.",
    "TITLE42": "Ces outils sont formés pour générer des réponses semblables à celles d'un humain et peuvent fournir des tons et des rédactions personnalisés selon vos préférences et créer des contenus engageants pour différents besoins sur les réseaux sociaux !",
    "HEADER5": "Soutenir votre croissance à chaque étape",
    "TITLE5": "Chez DSPLATFORM, nous nous engageons à favoriser votre croissance personnelle et professionnelle à chaque étape de votre parcours. Que vous fassiez vos premiers pas dans un nouveau rôle, que vous souhaitiez améliorer vos compétences ou que vous visiez un poste de leadership, notre engagement est d'être à vos côtés à chaque étape.",
    "HEADER6": "Nos fournisseurs soutenus dans le monde entier",
    "TITLE6": "Chez DSPLATFORM, nous sommes fiers de notre réseau solide et diversifié de fournisseurs du monde entier. Ces partenariats sont la colonne vertébrale de nos opérations, nous permettant de fournir des produits et services de haute qualité à nos clients.",
    "NEW_TITLE2": "Transformez vos produits en profits sur <span>{{DSPLATFORM}}</span>",
    "HEADER7": "Choisissez votre modèle",
    "TITLE7": "Nos modèles sont conçus pour une navigation facile sur n'importe quel appareil afin d'assurer une excellente expérience d'achat.",
    "HEADER8": "Ajoutez vos produits",
    "TITLE8": "Ajoutez ou importez vos produits, y compris les descriptions, les spécifications et les images.",
    "HEADER9": "Configurez vos expéditions et vos taxes",
    "TITLE9": "Affichez les taux de taxe spécifiques à chaque lieu et les options d'expédition dans votre boutique.",
    "HEADER10": "Publiez votre boutique",
    "TITLE10": "Allez en ligne en un clic et voyez les mises à jour reflétées instantanément sur votre site web.",
    "HEADER11": "Intégrez des passerelles de paiement",
    "TITLE11": "Connectez votre boutique à des passerelles de paiement populaires ou laissez les clients payer hors ligne.",
    "HEADER12": "Lancez votre propre marque de e-commerce !",
    "TITLE12": "Votre chemin vers le profit commence ici !",
    "GET_FREE": "Obtenez un essai gratuit",
    "REVIEW_HEADER": "Que disent d'autres entrepreneurs sur <span>{{DSPLATFORM}}</span>",
    "REVIEW_NAME1": "Landon Le",
    "REVIEW1": "J'ai décidé de me lancer dans le dropshipping il y a seulement trois mois. Je n'avais aucune expérience et je ne savais pas par où commencer. J'ai cherché un logiciel de dropshipping et j'ai trouvé DSPLATFORM. Devinez quoi ? Après trois mois, j'ai déjà rempli plus de 50 commandes ! Cet outil m'a aidé à trouver des produits tendance dans le secteur des soins de santé et à organiser ma boutique en ligne de manière très efficace.",
    "REVIEW_NAME2": "Graciela Tyler",
    "REVIEW2": "DSPLATFORM m'a aidé à relancer mon entreprise. J'ai perdu mon fournisseur national de bijoux pour femmes et je voulais trouver de nouvelles façons d'organiser mon entreprise. J'avais peur de commencer le dropshipping sans biens en stock ; je n'avais jamais fait cela auparavant. Néanmoins, j'ai essayé et j'ai élargi le choix de bijoux en céramique pour mes clientes et augmenté mes profits !",
    "REVIEW_NAME3": "Edison Price",
    "REVIEW3": "DSPLATFORM n'est pas mon premier outil de dropshipping. J'en ai essayé plusieurs pour rechercher des produits à vendre, des activités de recalibrage et la création de boutiques en ligne. Je ne m'attendais pas à tout trouver dans un seul produit à un prix abordable. Maintenant, je recommande DSPLATFORM à mes collègues comme le meilleur produit qui vaut son prix.",
    "REVIEW_NAME4": "Abel Nelson",
    "REVIEW4": "À notre époque numérique, c'est agréable quand quelque chose peut faire tout le travail pour vous ! DSPLATFORM est le meilleur outil d'automatisation ! Je gère cinq boutiques en ligne actives où toute la gestion des commandes est automatisée. Je me concentre sur les campagnes de promotion et je ne reçois que mes profits.",
    "FREQUENTLY_ASKED_QUESTIONS": "Questions Fréquemment Posées ?",
    "FEATURES": "Fonctionnalités",
    "BENEFITS": "Avantages",
    "INTEGRATIONS": "Intégrations",
    "SUPPLIERS": "Fournisseurs",
    "FAQ": "FAQ",
    "DASHBOARD": "Tableau de bord",
    "SIGN_UP": "S'inscrire",
    "SIGN_IN": "Se connecter",
    "START_CHAT": "Commencer une discussion",
    "FOOTER1": "DSPLATFORM, nous ne vendons pas,",
    "FOOTER2": "Nous réussissons !",
    "REGISTERED_ADDRESS": "Adresse du siège social",
    "DSPLATFORM": "DSPLATFORM",
    "ABOUT": "À propos",
    "INVESTORS": "Investisseurs",
    "PARTNERS": "Partenaires",
    "AFFILIATES": "Affiliés",
    "LEGAL": "Légal",
    "SERVICE_STATUS": "État du service",
    "SUPPORT": "Support",
    "MERCHANT_SUPPORT": "Support aux commerçants",
    "HELP_CENTER": "Centre d'aide",
    "HIRE_PARTNER": "Engager un partenaire",
    "COMMUNITY": "Communauté",
    "PRODUCTS": "Produits",
    "SHOP": "Boutique",
    "SHOP_PAY": "Shop Pay",
    "DSPLATFORM_PLUS": "DSPLATFORM Plus",
    "LINKPOP": "Linkpop",
    "DEVELOPERS": "Développeurs",
    "DSPLATFORM_DEV": "DSPLATFORM.dev",
    "API_DOCUMENT": "Documentation API",
    "DEV_DEGREE": "Dev Degree",
    "TERMS_OF_SERVICE": "Conditions d'utilisation",
    "PRIVACY_POLICY": "Politique de confidentialité",
    "SITEMAP": "Plan du site",
    "PRIVACY_CHOICES": "Choix de confidentialité",
    "COPY_RIGHTS": "Droits d'auteur © {{year}} <a href='/'>{{title}}</a> Tous droits réservés",
    "REGISTER": "S'inscrire",
    "REGISTER_HEADER": "Inscrivez-vous avec <span>{{DSPLATFORM}}</span>",
    "REGLOGIN_CONTENT": "Connectez-vous maintenant à DSPLATFORM pour mieux gérer votre compte de dropshipping et augmenter vos profits !",
    "FIRST_NAME": "Prénom",
    "FIRST_NAME_PLACEHOLDER": "Entrez votre prénom",
    "FIRST_NAME_REQUIRED": "Prénom requis",
    "ALPHABETS_CHECK": "Seules les lettres sont autorisées",
    "LAST_NAME": "Nom",
    "LAST_NAME_PLACEHOLDER": "Entrez votre nom",
    "LAST_NAME_REQUIRED": "Nom requis",
    "EMAIL": "Adresse e-mail",
    "EMAIL_PLACEHOLDER": "Entrez votre adresse e-mail",
    "EMAIL_REQUIRED": "E-mail requis",
    "EMAIL_INVALID": "Adresse e-mail invalide",
    "EMAIL_NOT_EXISTS": "L'e-mail n'existe pas",
    "EMAIL_NOT_FOUND": "Nous ne trouvons pas d'utilisateur avec cette adresse e-mail.",
    "EMAIL_EXISTS": "L'e-mail existe déjà",
    "EMAIL_NOTVERIFIED": "E-mail non vérifié",
    "MOBILE_NUMBER": "Numéro de mobile",
    "MOBILE_PLACEHOLDER": "Entrez votre numéro de mobile",
    "MOBILE_NUMBER_REQUIRED": "Numéro de mobile requis",
    "MOBILE_NUMBER_INVALID": "Numéro de mobile invalide",
    "MOBILE_NUMBER_EXISTS": "Le numéro de mobile existe déjà",
    "PASSWORD": "Mot de passe",
    "PASSWORD_PLACEHOLDER": "Entrez votre mot de passe",
    "PASSWORD_REQUIRED": "Mot de passe requis",
    "PASSWORD_INCORRECT": "Mot de passe incorrect",
    "PASSWORD_MIN_MAX": "Le mot de passe doit contenir au moins une majuscule, au moins une minuscule, au moins un chiffre, au moins un caractère spécial et entre 8 et 18 caractères",
    "PASSWORD_MIN": "Le mot de passe doit contenir au moins 8 caractères",
    "PASSWORD_MAX": "Le mot de passe doit contenir moins de 18 caractères",
    "PASSWORD_UPPER": "Le mot de passe doit contenir 1 lettre majuscule",
    "PASSWORD_LOWER": "Le mot de passe doit contenir 1 lettre minuscule",
    "PASSWORD_SPECIAL": "Le mot de passe doit contenir 1 caractère spécial",
    "PASSWORD_NUMERIC": "Le mot de passe doit contenir 1 caractère numérique",
    "YOUR_PASSWORD": "Votre mot de passe est",
    "STRONG": "Fort",
    "MEDIUM": "Moyen",
    "WEAK": "Faible",
    "CONFIRM_PASSWORD": "Confirmer le mot de passe",
    "CONFIRM_PASSWORD_PLACEHOLDER": "Entrez à nouveau votre mot de passe",
    "CONFIRM_PASSWORD_REQUIRED": "Confirmation du mot de passe requise",
    "CONFIRM_PASSWORD_MISMATCH": "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
    "INVALID_REFERRAL_CODE": "Code de parrainage invalide",
    "ALREADY_HAVE_ACCOUNT": "Vous avez déjà un compte",
    "LOGIN_HERE": "Connectez-vous ici",
    "REGISTER_HERE": "Inscrivez-vous ici",
    "LOGIN": "Se connecter",
    "LOGIN_HEADER": "Connectez-vous avec <span>{{DSPLATFORM}}</span>",
    "REMEMBER_ME": "Se souvenir de moi",
    "FORGOT_PASSWORD": "Mot de passe oublié",
    "FORGOT_SUCCESS": "Succès ! Nous avons envoyé le lien de réinitialisation de votre mot de passe par e-mail !",
    "DONT_HAVE_ACCOUNT": "Vous n'avez pas de compte",
    "SUBMIT": "Soumettre",
    "REGISTER_SUCCESS": "Inscription réussie",
    "LOGIN_SUCCESS": "Connexion réussie",
    "ACCOUNT_LOCKED": "Votre compte est bloqué par l'administrateur",
    "SOMETHING_WRONG": "Quelque chose s'est mal passé",
    "ERROR_ON_SERVER": "Erreur sur le serveur",
    "ERROR_OCCURED": "Une erreur est survenue",
    "VERIFY_ACCOUNT": "Vérifiez votre compte",
    "VERIFY_BOX": "Votre compte a été enregistré avec succès et est en attente de vérification par e-mail. Si vous ne recevez pas d'e-mail,",
    "CLICK_HERE": "Cliquez ici",
    "VERIFY_CONTENT1": "Nous avons envoyé un e-mail avec un lien de confirmation à votre adresse e-mail. Pour terminer le processus d'inscription, veuillez cliquer sur le lien de confirmation.",
    "VERIFY_CONTENT2": "Si vous ne recevez aucun e-mail, veuillez vérifier votre dossier spam ou attendre quelques minutes pour recevoir l'e-mail. Veuillez également vérifier que vous avez entré une adresse e-mail valide lors de l'inscription.",
    "ALREADY_VERIFIED": "Votre e-mail a été activé. Veuillez vous connecter à votre compte !",
    "ACCOUNT_VERIFIED": "Votre compte a déjà été activé. Veuillez vous connecter à votre compte !",
    "USER_NOT_FOUND": "Utilisateur non trouvé",
    "RESENT_SUCCESS": "Renvoi réussi",
    "RESET_PASSWORD": "Réinitialiser le mot de passe",
    "CONFIRM": "Confirmer",
    "RESET_SUCCESS": "Réinitialisation du mot de passe réussie",
    "RESET_LINK_EXPIRED": "Votre lien de réinitialisation du mot de passe a expiré",
    "RESENT_MAIL": "Nous vous avons renvoyé le lien d'activation. Veuillez vérifier votre boîte de réception/dossier spam !",
    "REQUIRED": "Requis",
    "MYSTORE": "Mon magasin / Ajouter un magasin",
    "MARKETPLACE": "Marché",
    "VIP_MARKETPLACE": "Marché VIP",
    "ORDERS": "Commandes",
    "ORDER": "Commande",
    "FINANCE": "Finances",
    "MY_INVENTORY": "Mon inventaire",
    "PACKAGE_SOLUTIONS": "Solutions de forfaits",
    "CUSTOMER_SUPPORT": "Support client",
    "REFER": "Parrainer un ami",
    "BALANCE": "Solde",
    "DEPOSIT": "Dépôt",
    "WITHDRAW": "Retirer",
    "SUBSCRIPTION_PACKAGE": "Forfait d'abonnement",
    "PACKAGE": "Forfait",
    "WELCOME": "Bienvenue",
    "NOTIFICATION": "Notification",
    "CLEARALL": "Tout effacer",
    "BANK_DETAILS": "Détails bancaires",
    "CHANGE_PASSWORD": "Changer le mot de passe",
    "LOGOUT": "Déconnexion",
    "CHANGE_LANG": "Changer de langue",
    "AMOUNT": "Montant",
    "PROCEED_PAY": "Procéder au paiement",
    "PROCEED_WITHDRAW": "Procéder au retrait",
    "DEPOSIT_PLACEHOLDER": "Entrez le montant de votre dépôt",
    "WITHDRAW_PLACEHOLDER": "Entrez le montant de votre retrait",
    "AMOUNT_REQUIRED": "Veuillez entrer le montant en USD",
    "INVALID_AMOUNT": "Le champ montant ne doit contenir que des valeurs numériques",
    "AMOUNT_ERROR": "Le montant doit être supérieur ou égal à {{AMOUNT}} USD",
    "WITHDRAW_AMOUNT_REQUIRED": "Le montant du retrait est requis",
    "INVALID_WITHDRAW_AMOUNT": "Seules les valeurs numériques sont autorisées",
    "BALANCE_TOO_LOW": "Votre solde est faible",
    "WITHDRAW_SUCCESS": "Demande de retrait envoyée avec succès",
    "INSUFFICIENT_AMOUNT": "Montant insuffisant",
    "CURRENT_PASSWORD": "Mot de passe actuel",
    "NEW_PASSWORD": "Nouveau mot de passe",
    "NEW_PASSWORD_REQUIRED": "Nouveau mot de passe requis",
    "CONFIRM_NEW_PASSWORD": "Confirmer le nouveau mot de passe",
    "NEW_CONFIRM_MISMATCH": "Le nouveau mot de passe et la confirmation ne correspondent pas",
    "PASSWORD_SAME": "Le nouveau mot de passe ne peut pas être votre ancien mot de passe",
    "TRY_ANOTHER_PASSWORD": "Veuillez essayer un autre mot de passe",
    "CHANGE_PASSWORD_SUCCESS": "Mot de passe changé avec succès",
    "NAME_BANK": "Nom sur le compte bancaire",
    "NAME_REQUIRED": "Nom requis",
    "INVALID_NAME": "Nom invalide",
    "NAME_BANK_PLACEHOLDER": "Entrez le nom du titulaire",
    "IBAN": "IBAN",
    "IBAN_PLACEHOLDER": "Entrez l'IBAN",
    "IBAN_REQUIRED": "IBAN requis",
    "INVALID_IBAN": "IBAN invalide",
    "SWIFT": "Code Swift",
    "SWIFT_PLACEHOLDER": "Entrez le code Swift",
    "SWIFT_REQUIRED": "Code Swift requis",
    "INVALID_SWIFT": "Code Swift invalide",
    "BANK_NAME": "Nom de la banque",
    "BANK_NAME_REQUIRED": "Nom de la banque requis",
    "INVALID_BANK_NAME": "Nom de la banque invalide",
    "BANK_PLACEHOLDER": "Entrez le nom de la banque",
    "BANK_DETAIL_SUCCESS": "Détails bancaires téléchargés avec succès",
    "BANK_PENDING": "Téléchargement réussi, en attente de vérification",
    "BANK_REJECTED": "Votre demande a été rejetée par l'administrateur",
    "SUCCESS": "Succès",
    "REJECTED": "Rejeté",
    "CREATE_REFERRAL": "Créer un parrainage",
    "REFERRAL_SUCCESS": "Parrainage ajouté avec succès",
    "CREATE_SUPPORT_TICKET": "Créer un ticket de support",
    "CATEGORY": "Catégorie",
    "SELECT_CATEGORY": "Sélectionner une catégorie",
    "CATEGORY_REQUIRED": "Catégorie requise",
    "MESSAGE": "Message",
    "MESSAGE_REQUIRED": "Message requis",
    "MESSAGE_ERROR": "Veuillez entrer votre message",
    "UPLOAD_FILE": "Pièce jointe (ajoutez un fichier ou déposez des fichiers ici)",
    "UPLOAD_SIZE_RESTRICT": "Remarque : MAX 1 Mo (uniquement .jpeg, .png, .jpg, .pdf)",
    "INVALID_IMAGE": "Format d'image invalide",
    "SUPPORT_ERROR": "Le ticket de support n'a pas été soumis",
    "SUPPORT_SUCCESS": "Ticket de support soumis avec succès",
    "TICKET_DETAILS": "Détails du ticket",
    "TICKET_ID": "ID du ticket",
    "SUB": "Sous",
    "SEND": "Envoyer",
    "OPEN": "Ouvert",
    "CLOSED": "Fermé",
    "ALL": "Tout",
    "ISSUE": "Problème",
    "STATUS": "Statut",
    "ADMIN": "Admin",
    "YOU": "Vous",
    "FILE": "Fichier",
    "NO_RECORD": "Aucun enregistrement",
    "CLOSE_TICKET": "Satisfait, fermer ce ticket",
    "CLOSE_SUCCESS": "Ticket fermé avec succès",
    "REPLY_SUBMIT": "Réponse soumise avec succès",
    "MESSAGE_SENT_SUCCESS": "Votre message a été envoyé avec succès",
    "TICKET_ALREADY_CLOSED": "Ticket déjà fermé",
    "DAILY_PROFIT": "Profit quotidien",
    "CURRENCY": "Monnaie",
    "TOTAL_ORDERS": "Total des commandes",
    "TOTAL_PROFIT": "Profit total",
    "MY_PRODUCTS": "Mes produits",
    "NEW_PRODUCTS": "Nouveaux produits",
    "SALES_OVERVIEW": "Aperçu des ventes",
    "DAYS": "Jours",
    "WEEK": "Semaine",
    "MONTH": "Mois",
    "OVERALL": "Global",
    "OVERVIEW": "Aperçu",
    "TOTAL_SELL_COST": "Coût total des commandes de vente",
    "MAINTENANCE_FEE": "Frais de maintenance mensuels",
    "ADVERTISEMENT": "Publicité",
    "SHIPPING": "Expédition",
    "STARTUP": "Démarrage",
    "TOTAL_FEE": "Frais totaux",
    "WORLD_OVERVIEW": "Aperçu de la couverture mondiale du magasin",
    "ORDER_LIST": "Liste des commandes",
    "VIEW_ORDERS": "Voir plus de commandes",
    "TOP_SELLING": "Produits les plus vendus",
    "ADD_PRODUCTS": "Ajouter des produits",
    "PRODUCT_NOT_FOUND": "Aucun produit trouvé",
    "PROFIT": "Profit",
    "PRODUCT_COST": "Coût du produit",
    "IMPORT_COST": "Coût d'importation",
    "ONE_TIME": "Paiement unique",
    "DEPOSIT_SUCCESS": "Montant déposé avec succès",
    "PRODUCT_NAME": "Nom du produit",
    "ORDERID": "ID de commande",
    "STORE_NAME": "Nom du magasin",
    "DATE_TIME": "Date et heure",
    "TOTAL_SALE": "Vente totale",
    "ALL_CATEGORY": "Toutes les catégories",
    "PRODUCT_PRICE": "Prix du produit",
    "IMPORT_PRICE": "Prix d'importation",
    "HIGH": "Élevé",
    "LOW": "Bas",
    "SORT_BY": "Trier par",
    "RECENTS": "Récents",
    "OLDEST": "Plus anciens",
    "TYPE_SOME": "Tapez quelque chose",
    "GALLERY": "Galerie",
    "LIST": "Liste",
    "ADDED_CART": "Ajouté au panier",
    "IMPORT_CART": "Importer au panier",
    "SUBSCRIBE": "Veuillez vous abonner à VIP",
    "ACCOUNT_HISTORY": "Historique du compte",
    "SEARCH": "Rechercher",
    "ORDERS_NOT_FOUND": "Aucune commande trouvée",
    "REASON": "Raison",
    "TYPE": "Type",
    "PAYMENT_ID": "ID de paiement",
    "PENDING": "En attente",
    "COMPLETED": "Terminé",
    "ADMIN_DEPOSIT": "Dépôt de l'administrateur",
    "USER_NOT_FINISHED": "Dépôt de l'utilisateur non terminé",
    "SUCCEEDED": "Réussi",
    "USER": "Utilisateur",
    "USER_NAME": "Nom d'utilisateur",
    "USER_EMAIL": "Email de l'utilisateur",
    "BEFORE_BALANCE": "Solde avant",
    "AFTER_BALANCE": "Solde après",
    "BILLED_MONTH": "Facturé mensuellement",
    "SUBSCRIBED": "Abonné",
    "BUY_NOW": "Acheter maintenant",
    "BUSINESS_DAYS": "Jours ouvrables pour l'expédition",
    "AIR_HUB": "Hubs aériens",
    "FOR": "Pour",
    "INCLUDES": "Comprend",
    "AUTHORIZED": "Centre de livraison autorisé® (FASC) et partenaires d'alliance.",
    "ARE_YOU": "Êtes-vous sûr d'acheter",
    "PACKAGE_WITH": "Forfait avec",
    "CANCEL": "Annuler",
    "INSUFFICIENT_USD": "Votre solde en USD est faible",
    "SUBSCRIBED_SUCCESS": "Abonnement réussi",
    "NO_PACKAGE": "Aucun forfait trouvé",
    "ADVERTISE": "Faire de la publicité pour",
    "CLIENT_WORLD": "clients dans le monde entier",
    "AIR_EXPRESS_HUBS": "Hubs Air Express",
    "AVERAGE_DAILY_PACKAGE": "Volume moyen de colis par jour",
    "AIRPORTS": "Aéroports desservis",
    "SELL_COST": "Coût de vente",
    "SELECT_STORE": "Sélectionner un magasin",
    "VIEW_PRODUCTS": "Voir le produit",
    "EDIT_SUCCESS": "Édition réussie",
    "EDIT_SELL_PRICE": "Modifier le prix de vente",
    "ID": "ID",
    "CREATED": "Créé",
    "SELLING_PRICE": "Prix de vente",
    "SELLING_PRICE_PLACEHOLDER": "Entrez le montant de vente",
    "WAITING": "attente",
    "SELLING_PRICE_REQUIRED": "Le coût de vente ne doit pas être vide",
    "SELLILNG_PRICE_MAX": "Le coût de vente doit être supérieur au coût du produit",
    "ARE_YOU_DELETE": "Êtes-vous sûr de vouloir supprimer",
    "DELETE_SUCCESS": "Produit supprimé avec succès",
    "DELETE_NOTE": "Remarque : nous ne remboursons pas votre coût d'importation pour la suppression de ce produit",
    "PRODUCT_DETAILS": "Détails du produit",
    "PRODUCT_ID": "ID du produit",
    "REVIEWS": "Avis",
    "SHIPS_FROM": "Expédié de",
    "22BUSINESS_DAYS": "22 jours ouvrables pour",
    "US": "États-Unis",
    "PRODUCT_INFO": "Infos sur le produit",
    "SPECIFICATIONS": "Spécifications",
    "POLICY": "Politique",
    "ITEM_COST": "Coût de l'article",
    "NUMBER_ORDERS": "Nombre de commandes",
    "ADDED_DRAFT": "Ajouté au brouillon",
    "IMPORT_PRODUCT": "Importer le produit",
    "PRODUCT_NOT_FOUND": "Produit non trouvé",
    "ALREADY_DRAFT": "Oups, l'article est déjà dans le brouillon",
    "QUANTITY": "Quantité",
    "ACTUAL_PRICE": "Prix réel",
    "USER_PROFIT": "Profit de l'utilisateur",
    "RETURN": "Retour",
    "SEARCH_PRODUCT": "Rechercher un produit ou un magasin",
    "SELECT_DATE": "Sélectionner une date",
    "STORE": "Magasin",
    "SEARCH_STORE": "Rechercher le nom du magasin",
    "SETTINGS": "Paramètres",
    "ADD_STORE": "Ajouter un magasin",
    "EDIT": "Éditer",
    "SEARCH_ANYTHING": "Rechercher n'importe quoi",
    "STORE_LOCATION": "Emplacement du magasin",
    "SEARCH_COUNTRY": "Rechercher un pays...",
    "STORE_LOGO": "Logo du magasin",
    "STORE_LOGO_RESTRICT": "Format acceptable : png, jpeg, jpg",
    "UPDATE_SUCCESS": "Mise à jour réussie",
    "STORE_NAME_REQUIRED": "Nom du magasin requis",
    "COUNTRY_REQUIRED": "Pays requis",
    "SELECT_DEFAULT": "Veuillez sélectionner le magasin par défaut",
    "FILE_SIZE": "La taille du fichier dépasse 30 Mo",
    "SELECT_COUNTRY": "Sélectionner un pays",
    "STORE_NAME_PLACEHOLDER": "Entrez le nom du magasin",
    "ACCEPTABLE_SIZE": "Taille acceptable : 30 Mo format : png, jpeg, jpg",
    "SELECT_STORE_TYPE": "Sélectionner le type de magasin",
    "CREATE_STORE": "Créer un magasin",
    "STORE_ALREADY_EXISTS": "Le magasin existe déjà",
    "STORE_SUCCESS": "Magasin créé avec succès",
    "NO_DATA": "Aucune donnée",
    "DRAFT_PRODUCT": "Produit brouillon",
    "BACK": "Retour",
    "ALL_PRODUCT": "Tous les produits",
    "FAILED": "Échoué",
    "SUCCESSFUL": "Réussi",
    "ACTIONS": "Actions - Importation rapide",
    "IMPORT": "Importer",
    "FOURTH_TAB": "contenu de l'onglet quatre",
    "ARE_YOU_REMOVE": "Êtes-vous sûr de vouloir retirer",
    "SELECT_YOUR_STORE": "Sélectionnez votre magasin",
    "SELLING_PRICE_ERROR": "Aucun produit n'a de prix de vente",
    "SELECT_STORE_ERROR": "Veuillez sélectionner votre magasin",
    "PRODUCT": "Produit",
    "AVERAGE_MARKPRICE": "Prix moyen du marché",
    "ACTION": "Action",
    "SELL_PRICE": "Prix de vente",
    "INSUFFICIENT_FUNDS": "Fonds insuffisants",
    "REMOVE_SUCCESS": "Retiré avec succès",
    "STORE_REQUIRED": "Magasin requis",
    "PROFIT_PER_ORDER": "Profit par commande",
    "IMPORT_STORE": "Importer un magasin",
    "PRODUCT_ALREADY_SELECTED": "Produit déjà dans le magasin sélectionné",
    "IMPORT_SUCCESS": "Produit importé avec succès",
    "ALREADY_DEPOSITED": "Déjà déposé",
    "CONFIRM_MAIL_SUCCESS": "Votre email a été vérifié, vous pouvez maintenant vous connecter",
    "IS_ACTIVE": "Votre email est déjà vérifié",
    "UNAUTHORIZED": "Non autorisé",
    "SHIPPING_TIME": "Temps d'expédition",
    "BUSSINESS_DAYS": "Jours ouvrables",
    "PRODUCT_SUCCESS": "{{SUCCESS}} Importation du produit réussie {{FAILER}} Échec de l'importation du produit",


    "WALLET": "Portefeuille",
    "BANK_DETAILS": "Détails Bancaires",
    "DEPOSIT_TERMS": "Conditions de Dépôt",
    "MINIMUM_DEPOSIT_AMOUNT": "Montant minimum de dépôt : ",
    "INVALID_IMAGE": "Image invalide",
    "MAINTENANCE_ERROR": "Maintenance en cours, veuillez réessayer plus tard !",
    "DEPOSIT_DESCRIPTION": "Les dépôts inférieurs au montant minimum ne seront pas acceptés et les remboursements ne sont pas autorisés",
    "DEPOSIT_NOTES1": "Envoyez uniquement {{CURRENCY_SYMBOL}} à cette adresse de portefeuille.",
    "DEPOSIT_NOTES2": "L'envoi de pièces ou de jetons autres que {{CURRENCY_SYMBOL}} à ce portefeuille peut entraîner la perte totale de votre dépôt",
    "DEPOSIT_NOTES3": "Veuillez télécharger une capture d'écran ou un hash de votre transaction pour que nous puissions l'approuver",
    "DEPOSIT_CARD": "Déposer par carte",
    "DEPOSIT_ADDRESS": "Adresse de Dépôt",
    "MEMO_ADDRESS": "Adresse de Mémo",
    "DEPOSIT_IMAGE_RESTRICTION": "Taille maximale de fichier à télécharger 2 Mo (uniquement Jpg, Jpeg, Png, Pdf).",
    "DEPOSIT_AMOUNT_ERROR": "Veuillez entrer un montant de dépôt supérieur ou égal à {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "Demande de dépôt envoyée avec succès",
    "ASSET_ERROR": "Le champ d'actifs de l'utilisateur est requis",
    "COPIED": "Copié !",
    "DOCUMENT_UPLOAD": "Télécharger un Document (Optionnel)",
    "UPLOAD": "Télécharger",
    "WITHDRAW_TERMS": "Conditions de Retrait",
    "MINIMUM_WITHDRAW_AMOUNT": "Le montant minimum de retrait est : ",
    "TERMS1": "Retrait maximum :",
    "TERMS2": "Frais de retrait :",
    "RECEIVER_ADDRESS": "Adresse du Destinataire",
    "RECEIVER_ADDRESS_PLACEHOLDER": "Veuillez entrer l'adresse de retrait ici",
    "RECEIVER_ADDRESS_REQUIRED": "Le champ d'adresse du destinataire est requis",
    "WITHDRAW_AMOUNT": "Montant du Retrait",
    "WITHDRAW_AMOUNT_ERROR": "Veuillez entrer un montant de retrait supérieur ou égal à {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "Le montant minimum de retrait doit être supérieur ou égal à {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "Le montant de retrait doit être inférieur ou égal à {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "Demande de retrait envoyée avec succès",
    "INSUFFICIENT_WITHDRAW": "Montant de retrait trop bas",
    "WITHDRAW_OTP": "Un OTP a été envoyé avec succès à votre adresse e-mail. Veuillez vérifier votre boîte de réception et utiliser le code fourni pour continuer",
    "HOLDER_NAME": "Nom du Titulaire",
    "BANK_CODE": "Code Banque",
    "BANKCODE_REQUIRED": "Code Banque requis",
    "INVALID_CODE": "Code invalide",
    "ACCOUNTNO": "Numéro de Compte",
    "ACCOUNTNO_REQUIRED": "Numéro de Compte requis",
    "INVALID_ACCOUNTNO": "Numéro invalide",
    "COUNTRY": "Pays",
    "TOTAL_BALANCE": "Solde Total",
    "BALANCE": "Solde",
    "EXCHANGE": "Échange",
    "HIDE_ZERO_BALANCES": "Masquer les Soldes Zéro",
    "FROM_CURRENCY": "De la Monnaie",
    "TO_CURRENCY": "À la Monnaie",
    "TO_CURRENCY_REQUIRED": "Le champ de la monnaie cible est requis",
    "SELECT_CURRENCY": "Sélectionner la Monnaie",
    "INVALID_USER": "Utilisateur invalide",
    "INSUFFICIENT_BALANCE": "Solde insuffisant",
    "TRANSFER_SUCCESS": "Transféré avec succès",
    "AMOUNT_CAN'T_ZERO": "Le montant doit être supérieur à zéro",
    "AVAILABLE_BALANCE": "Solde Disponible",
    "MAX": "Max",
    "TOO_LARGE": "Trop grand",
    "ASSET": "Actif",
    "AMOUNT_PLACEHOLDER": "Entrez le Montant",
    "OTP": "OTP",
    "OTP_PLACEHOLDER": "Entrez OTP",
    "RESENT_OTP": "Renvoyer le Code de Vérification",
    "OTP_VALIDUPTO": "OTP valable jusqu'à",
    "OTP_REQUIRED": "Le champ OTP est requis",
    "OTPSTATUS_REQUIRED": "L'état de l'OTP est requis",
    "INVALID_OTPSTATUS": "État de l'OTP invalide",
    "OTP_SENT_SUCCESS": "OTP envoyé à votre adresse e-mail, veuillez entrer un OTP",
    "EXPIRED_OTP": "OTP expiré",
    "INVALID_OTP": "OTP invalide",
    "SEC": "Sec",
    "STORE_MARKETPLACE": "Marché de Magasin",
    "STORE_PRICE": "Prix du Magasin",
    "INVALID_CURRENCY": "Monnaie invalide",
    "VALID_AMOUNT": "Veuillez entrer un montant valide",
    "INVALID_ASSET": "Actif invalide",
    "SITE_URL": "URL du Site",
    "PAYMENTYPE": "Type de Paiement",
    "CURRENCYSYMBOL": "Symbole de Monnaie",
    "COIN_DEPOSIT": "Dépôt de Pièces",
    "COIN_WITHDRAW": "Retrait de Pièces",
    "COIN_TRANSFER": "Transfert de Pièces",
    "FIAT_DEPOSIT": "Dépôt Fiat",
    "FIAT_WITHDRAW": "Retrait Fiat",
    "NEW_USER": "Nouvel Utilisateur",
    "BUYING_STORE": "Acheter un Magasin",
    "BUYING_PRODUCT": "Acheter des Produits",
    "USER_DEPOSIT": "Dépôt de l'Utilisateur",
    "USER_WITHDRAW": "Retrait de l'Utilisateur en Attente",
    "USER_WITHDRAW_VERIFY": "Retrait de l'Utilisateur Vérifié",
    "USER_WITHDRAW_REJECT": "Retrait de l'Utilisateur Rejeté",
    "ADMIN_WITHDRAW": "Retrait Administrateur",
    "ADMIN_DEP": "Dépôt Administrateur",
    "PROFITS": "Profits",
    "PACKAGE_SUBSCRIBE": "Abonnement au Paquet",
    "STORE_RENEWAL": "Renouvellement de Magasin",
    "RETURN_PRODUCT": "Retourner Produit",
    "COIN_TRANSFERS": "Transferts de Pièces",
    "ROWS_PER_PAGE": "Lignes par Page",


    "ABOUT_US": "À propos de nous",
    "ABOUT_LIST1": " DSPLATFORM a été fondée",
    "ABOUT_LIST2": " Utilisateurs actifs mensuels",
    "ABOUT_LIST3": " Pays d'origine des utilisateurs",
    "ABOUT_LIST4": " Employés",
    "ABOUNT_HEAD1": "Votre plateforme tout-en-un pour la recherche de produits et l'exécution",
    "ABOUT_PARA1": "Une bonne recherche de produits est la clé du succès, mais la réalité de l'approvisionnement, des relations avec les agents, de l'expédition internationale et des douanes est plus complexe que ce que la plupart des gens s'attendent. La vision de DSPLATFORM est de supprimer cette complexité. Ce que vous voyez, c'est ce que vous pouvez obtenir ici même dans votre tableau de bord en quelques clics. Pas de services d'automatisation externes, pas de gestion des fournisseurs.",
    "ABOUNT_HEAD2": "Expérience de savoir plutôt que de deviner",
    "ABOUT_PARA2": "L'équipe de recherche de DSPLATFORM travaille avec des vendeurs de boutiques autonomes, Amazon et eBay de toutes tailles, qui testent des hypothèses et aident les analystes de DSPLATFORM à valider leurs données. Grâce à un réseau de partenaires, la méthodologie de prédiction de DSPLATFORM n'est pas seulement sur papier, mais largement testée sur le terrain. C'est une recherche précise, pas des chiffres inventés comme le font presque tous les soi-disant «suiveurs de ventes».",
    "ABOUNT_HEAD3": "Transparence",
    "ABOUT_PARA3": "Nous savons à quel point il peut être frustrant d'être submergé par des tonnes de sites Web anonymes et de chiffres fictifs. Nous corrigeons cela. DSPLATFORM est une entreprise au cœur de Londres dont les fondateurs soutiennent leur méthodologie de recherche et leurs services par leur nom.",
    "ABOUNT_HEAD4": "Professionnalisme",
    "ABOUT_PARA4": "Nous comprenons vos besoins de base pour pouvoir fonctionner en tant qu'entreprise : factures appropriées, certifications de produit nécessaires, conseils compétents. L'équipe de support est composée de locuteurs natifs anglais qui peuvent gérer même les questions les plus difficiles et la bureaucratie.",
    "ABOUNT_HEAD5": "Esprit entrepreneurial",
    "ABOUT_PARA5": "Nous voulons que vous réussissiez, surtout si vous êtes débutant. C'est pourquoi nous avons rendu le tableau de bord de DSPLATFORM aussi facile à utiliser que possible, très léger, avec de nombreuses fonctionnalités pour la scalabilité et la croissance que nous aurions souhaité avoir en construisant nos premiers magasins.",
    "ABOUNT_HEAD6": "Optimisation",
    "ABOUT_PARA6": "Le tableau de bord de DSPLATFORM n'est que le début. Nous améliorons, nous ajoutons des fonctionnalités, nous demandons des retours et nous mettons en œuvre les retours. Nous croyons qu'une amélioration de la qualité entraîne naturellement la croissance. Merci de nous donner autant de retours que possible. Chaque détail compte !",
    "ABOUNT_HEAD7": "E-Commerce pour tous",
    "ABOUT_PARA7": "Nous voulons moins de grands acteurs, comme Amazon et Costco, et plus d'entrepreneurs. DSPLATFORM permet aux vendeurs indépendants de former un marché diversifié pour tout le monde. Apprenons les uns des autres, grandissons ensemble et aidons les autres à réussir.",
    "ABOUNT_HEAD8": "Amour",
    "ABOUT_PARA8": "Les membres de l'équipe DSPLATFORM créent des fonctionnalités qu'ils adorent. Personne n'implémente quoi que ce soit qu'il ne voudrait pas utiliser lui-même. Nous rassemblons des personnes qui ont une passion profonde pour l'e-commerce. Cette passion est partagée avec chaque abonné via le tableau de bord.",
    "AFFILIATE_PROGRAM": "Programme d'affiliation",
    "AFFILIATE_QUES": "Qui peut promouvoir DSPLATFORM",
    "AFFILIATE_HEAD1": "Créateurs de contenu",
    "AFFILIATE_PARA1": "Utilisez le contenu prêt à l'emploi de DSPLATFORM pour la promotion si vous êtes créateur de contenu sur Facebook, Instagram ou TikTok.",
    "AFFILIATE_HEAD2": "Influenceurs sur les réseaux sociaux",
    "AFFILIATE_PARA2": "Gagnez de l'argent en promouvant DSPLATFORM sur les réseaux sociaux et en exprimant votre affection pour elle en tant qu'influenceur.",
    "AFFILIATE_HEAD3": "YouTubers",
    "AFFILIATE_PARA3": "Faites part de vos impressions sur DSPLATFORM à travers une vidéo YouTube et incluez votre lien d'affiliation dans la description.",
    "AFFILIATE_HEAD4": "Éducateurs",
    "AFFILIATE_PARA4": "Transformez votre contenu en une source de revenus en éduquant les autres sur la manière d'utiliser efficacement DSPLATFORM.",
    "AFFILIATE_HEAD5": "Entrepreneurs",
    "AFFILIATE_PARA5": "Améliorez le potentiel de monétisation de votre contenu en guidant les autres dans l'utilisation efficace de DSPLATFORM.",
    "AFFILIATE_HEAD6": "Blogueurs et écrivains",
    "AFFILIATE_PARA6": "Augmentez la rentabilité de vos articles de blog en incorporant les annonces de DSPLATFORM et votre lien d'affiliation.",
    "UNLOCK_POTENTIAL": "Déverrouillez votre potentiel",
    "COMMUNITY_PARA1": "Rejoignez un groupe d'enthousiastes de l'e-commerce partageant les mêmes idées. Partagez vos expériences, posez des questions et recevez des conseils de personnes qui comprennent votre parcours.",
    "COMMUNITY_HEAD2": "Sélections de produits gratuits",
    "COMMUNITY_PARA2": "Accédez à des produits soigneusement sélectionnés. Ces sélections gratuites vous aideront à découvrir de nouveaux articles ayant le potentiel de devenir les prochaines meilleures ventes dans votre magasin.",
    "COMMUNITY_HEAD3": "Aperçus précieux",
    "COMMUNITY_PARA3": "Apprenez de la sagesse collective de la communauté. Obtenez des informations sur ce qui fonctionne dans l'e-commerce, vous aidant à prendre des décisions commerciales plus intelligentes.",
    "COMMUNITY_HEAD4": "Opportunités de réseautage",
    "COMMUNITY_PARA4": "Connectez-vous avec des partenaires potentiels, des mentors ou même des investisseurs. Le réseautage peut ouvrir de nouvelles avenues pour la croissance de votre entreprise.",
    "COMMUNITY_HEAD5": "Actualités",
    "COMMUNITY_PARA5": "Restez à l'affût des tendances de l'e-commerce. Faire partie de cette communauté signifie que vous serez parmi les premiers à entendre parler des dernières nouvelles et stratégies.",
    "COMMUNITY_HEAD6": "Motivation",
    "COMMUNITY_PARA6": "Rejoindre une communauté solidaire peut fournir l'encouragement nécessaire pour persévérer face aux défis de la gestion d'une entreprise d'e-commerce.",
    "COMMUNITY_HEAD7": "Ne dropshippez pas comme en 2017",
    "COMMUNITY_PARA7": "Imaginez que quelqu'un vous demande qui est votre fournisseur, et vous devez lui dire que c'est un agent privé, ou AliExpress, ou un outil d'automatisation qui achète simplement pour vous sur AliExpress. Nous sommes en 2024. Vos clients, vos revenus et vos profits vous remercieront.",
    "TESTIMONIALS": "TÉMOIGNAGES",
    "WHAT_OTHERS": "Ce que d'autres entrepreneurs disent de DSPLATFORM",
    "SAYS_HEAD1": "Timo Herzog",
    "SAYS_PARA1": "«Après 2 ans dans l'e-commerce et peu de succès, j'ai réalisé que des suiveurs de magasins ou des outils d'espionnage précis n'existent tout simplement pas. Le succès dans le dropshipping nécessite une recherche appropriée et un fournisseur fiable pour faire le travail. DSPLATFORM a été le changement de jeu dont j'avais besoin. Une recherche réelle, transparente, de niveau universitaire, couplée à une logistique et une automatisation. Cela m'a permis de passer de simple vendeur à entrepreneur en moins de 3 mois.»",
    "SAYS_HEAD2": "Mario Giordano",
    "SAYS_PARA2": "«DSPLATFORM est très accessible aux débutants. Pour les débutants en dropshipping, c'est de loin la meilleure solution tout-en-un. Les produits sont expédiés dans un emballage neutre, donc le client final ne voit pas que c'est du dropshipping. Pour des professionnels comme moi, il manque encore une option de branding. Selon le support, ils y travaillent. Cependant, c'est toujours le meilleur pour la recherche et l'exécution de produits qui ne nécessitent pas de branding.»",
    "SAYS_HEAD3": "Lisa-Marie Meskendahl",
    "SAYS_PARA3": "«Super pour gérer plusieurs magasins simultanément et tester des produits rapidement. Il n'y a rien de comparable sur le marché. Pour le dropshipping, il y a, mais pour rester léger et agile sans trop investir, il n'y en a pas.»",
    "SAYS_HEAD4": "Farid Saadlou",
    "SAYS_PARA4": "«Expédition mondiale fiable, automatisation complète, recherche transparente. L'outil de tendances de produits est particulièrement génial. Je suis toujours prêt à réagir aux demandes de demain, et l'analyse de la saisonnalité m'aide à savoir quand vendre quoi.»",
    "SAYS_HEAD5": "Agnesa Veliu",
    "SAYS_PARA5": "«Lorsque je suis entrée dans l'e-commerce il y a de nombreuses années, la complexité des douanes, des importations et de la comptabilité fiscale m'a rapidement fait perdre intérêt. J'ai toujours été bonne en marketing mais mauvaise en opérations, en particulier pour les questions juridiques. DSPLATFORM a réglé ces problèmes pour moi, et maintenant je gère et fais croître mon magasin !»",
    "SAYS_HEAD6": "Dominic Galagher",
    "SAYS_PARA6": "«DSPLATFORM a sans conteste le meilleur support ! Ils se soucient, posent des questions, conseillent, ils veulent votre succès. Je suis toujours étonné par la qualité de ce service.»",
    "SAYS_HEAD7": "Marc Malen",
    "SAYS_PARA7": "«Ce qui a commencé comme un projet secondaire s'est transformé en une entreprise avec maintenant huit employés. DSPLATFORM a été à mes côtés tout au long du parcours et est toujours mon outil de recherche et d'exécution de produits. J'expérimente avec d'autres fournisseurs, mais cela nécessite tellement de microgestion. Avec DSPLATFORM, je sais que tout se déroulera sans accroc.»",
    "SAYS_HEAD8": "Dima Voronov",
    "SAYS_PARA8": "«J'ai connecté mes magasins. Ça fonctionne simplement. La plupart des outils tout-en-un ne sont que des automateurs qui vous connectent à des agents chinois douteux ou achètent pour vous sur AliExpress. J'adore que DSPLATFORM soit un véritable fournisseur. Factures de TVA, certificats CE et conseils de locuteurs natifs anglais ayant une évidente expérience en e-commerce. Plus besoin de supplier des agents ou des vendeurs d'AliExpress qui ne savent pas ce qu'est une facture.»"
}

import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// img
import w1 from "../assets/images/w1.svg";
import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacy() {


  return (
    <div>
      <Navbar />
      <div className="common_page" >
        <div className="container" >
          <h1 className="h2tag" ><strong>Privacy Policy</strong></h1> 
          <p>This privacy notice for&nbsp;DSPLATFORM Research Ltd&nbsp;(doing business as&nbsp;DSPLATFORM)&nbsp;(&lsquo;<strong><strong>DSPLATFORM</strong></strong>&lsquo;, &lsquo;<strong><strong>we</strong></strong>&lsquo;, &lsquo;<strong><strong>us</strong></strong>&lsquo;, or &lsquo;<strong><strong>our</strong></strong>&lsquo;), describes how and why we might collect, store, use, and/or share (&lsquo;<strong><strong>process</strong></strong>&lsquo;) your information when you use our services (&lsquo;<strong><strong>Services</strong></strong>&lsquo;), such as when you:</p>
          <ul>
            <li>Visit our website&nbsp;at&nbsp;Https://DSPLATFORM.PRO, or any website of ours that links to this privacy notice</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
          </ul>
          <p><strong><strong>Questions or concerns?&nbsp;</strong></strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at&nbsp;privacy@DSPLATFORM.pro.</p>
          <p>&nbsp;</p>
          <h2><strong>SUMMARY OF KEY POINTS</strong></h2>
          <p><strong><strong>What personal information do we process?</strong></strong>&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with&nbsp;DSPLATFORM&nbsp;and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;<a href="#personalinfo"><u>personal information you disclose to us</u></a>.</p>
          <p><strong><strong>Do we process any sensitive personal information?</strong></strong>&nbsp;We do not process sensitive personal information.</p>
          <p><strong><strong>Do we receive any information from third parties?</strong></strong>&nbsp;We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about&nbsp;<a href="#othersources"><u>information collected from other sources</u></a>.</p>
          <p><strong><strong>How do we process your information?</strong></strong>&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;<a href="#infouse"><u>how we process your information</u></a>.</p>
          <p><strong><strong>In what situations and with which&nbsp;types of&nbsp;parties do we share personal information?</strong></strong>&nbsp;We may share information in specific situations and with specific&nbsp;categories of&nbsp;third parties. Learn more about&nbsp;<a href="#whoshare"><u>when and with whom we share your personal information</u></a>.</p>
          <p><strong><strong>How do we keep your information safe?</strong></strong>&nbsp;We have&nbsp;organisational&nbsp;and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other&nbsp;unauthorised&nbsp;third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp;<a href="#infosafe"><u>how we keep your information safe</u></a>.</p>
          <p><strong><strong>What are your rights?</strong></strong>&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;<a href="#privacyrights"><u>your privacy rights</u></a>.</p>
          <p><strong><strong>How do you exercise your rights?</strong></strong>&nbsp;The easiest way to exercise your rights is by&nbsp;visiting&nbsp;Https://DSPLATFORM.pro, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
          <p>Want to learn more about what&nbsp;DSPLATFORM&nbsp;does with any information we collect?&nbsp;</p>
          <h3><strong>&nbsp;</strong></h3>
          <h3><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></h3>
          <p><strong><strong>Personal information you disclose to us</strong></strong></p>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>We collect personal information that you provide to us.</em></em></p>
          <p>We collect personal information that you voluntarily provide to us when you&nbsp;register on the Services,&nbsp;express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
          <p><strong><strong>Personal Information Provided by You.</strong></strong>&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
          <ul>
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>mailing addresses</li>
            <li>job titles</li>
            <li>usernames</li>
            <li>passwords</li>
            <li>contact preferences</li>
            <li>contact or authentication data</li>
            <li>billing addresses</li>
            <li>debit/credit card numbers</li>
          </ul>
          <p><strong><strong>Sensitive Information.</strong></strong>&nbsp;We do not process sensitive information.</p>
          <p><strong><strong>Payment Data.</strong></strong>&nbsp;We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by&nbsp;Stripe&nbsp;and&nbsp;PayPal. You may find their privacy notice link(s) here:&nbsp;<a href="https://www.stripe.com/privacy"><u>https://www.stripe.com/privacy</u></a>&nbsp;and&nbsp;<a href="https://www.paypal.com/privacy"><u>https://www.paypal.com/privacy</u></a>.</p>
          <p>Various payment methods (credit card, Apple Pay, Google Pay, Link, and others) are handled through Stripe.</p>
          <p><strong><strong>Social Media Login Data.&nbsp;</strong></strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called&nbsp;&lsquo;<a href="#sociallogins"><u>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</u></a>&lsquo;&nbsp;below.</p>
          <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
          <p><strong><strong>Information automatically collected</strong></strong></p>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</em></em></p>
          <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
          <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
          <p>The information we collect includes:</p>
          <ul>
            <li><em><em>Log and Usage Data.</em></em>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services&nbsp;(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called&nbsp;&lsquo;crash dumps&rsquo;), and hardware settings).</li>
            <li><em><em>Device Data.</em></em>We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
            <li><em><em>Location Data.</em></em>We collect location data such as information about your device&rsquo;s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
          </ul>
          <p><strong><strong>Information collected from other sources</strong></strong></p>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>We may collect limited data from public databases, marketing partners,&nbsp;social media platforms,&nbsp;and other outside sources.</em></em></p>
          <p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers,&nbsp;social media platforms,&nbsp;and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user&nbsp;behaviour&nbsp;data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.&nbsp;If you interact with us on a social media platform using your social media account (e.g.&nbsp;Facebook or Twitter), we receive personal information about you such as your name, email address, and gender. Any personal information that we collect from your social media account depends on your social media account&rsquo;s privacy settings.</p>
          <h3><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></h3>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></em></p>

          <p><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></p>
          <ul>
            <li><strong><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong></strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
            <li><strong><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong></strong>We may process your information to provide you with the requested service.</li>
            <li><strong><strong>To respond to user inquiries/offer support to users.&nbsp;</strong></strong>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
            <li><strong><strong>To send administrative information to you.&nbsp;</strong></strong>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
            <li><strong><strong>To&nbsp;fulfil&nbsp;and manage your orders.</strong></strong>We may process your information to&nbsp;fulfil&nbsp;and manage your orders, payments, returns, and exchanges made through the Services.</li>
            <li><strong><strong>To enable user-to-user communications.&nbsp;</strong></strong>We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
            <li><strong><strong>To request feedback.&nbsp;</strong></strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
            <li><strong><strong>To send you marketing and promotional communications.&nbsp;</strong></strong>We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see&nbsp;&lsquo;<a href="#privacyrights"><u>WHAT ARE YOUR PRIVACY RIGHTS?</u></a>&lsquo;&nbsp;below.</li>
            <li><strong><strong>To deliver targeted advertising to you.</strong></strong>We may process your information to develop and display&nbsp;personalised&nbsp;content and advertising tailored to your interests, location, and more.</li>
            <li><strong><strong>To protect our Services.</strong></strong>We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
            <li><strong><strong>To identify usage trends.</strong></strong>We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
            <li><strong><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong></strong>We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
            <li><strong><strong>To save or protect an individual&rsquo;s vital interest.</strong></strong>We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</li>
          </ul>
          <h3><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</strong></h3>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.&nbsp;legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or&nbsp;fulfil&nbsp;our contractual obligations, to protect your rights, or to&nbsp;fulfil&nbsp;our legitimate business interests.</em></em></p>
          <p><strong><em><u><strong><em>If you are located in the EU or UK, this section applies to you.</em></strong></u></em></strong></p>
          <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
          <ul>
            <li>We may process your information if you have given us permission (i.e.&nbsp;consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about&nbsp;<a href="#withdrawconsent"><u>withdrawing your consent</u></a>.</li>
            <li><strong><strong>Performance of a Contract.</strong></strong>We may process your personal information when we believe it is necessary to&nbsp;fulfil&nbsp;our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
            <li><strong><strong>Legitimate Interests.</strong></strong>We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</li>
            <li>Send users information about special offers and discounts on our products and services</li>
            <li>Develop and display&nbsp;personalised&nbsp;and relevant advertising content for our users</li>
            <li>Analyse&nbsp;how our Services are used so we can improve them to engage and retain users</li>
            <li>Support our marketing activities</li>
            <li>Diagnose problems and/or prevent fraudulent activities</li>
            <li>Understand how our users use our products and services so we can improve user experience</li>
            <li><strong><strong>Legal Obligations.</strong></strong>We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
            <li><strong><strong>Vital Interests.</strong></strong>We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
          </ul>
          <p><strong><em><u><strong><em>If you are located in Canada, this section applies to you.</em></strong></u></em></strong></p>
          <p>We may process your information if you have given us specific permission (i.e.&nbsp;express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e.&nbsp;implied consent). You can&nbsp;<a href="#withdrawconsent"><u>withdraw your consent</u></a>&nbsp;at any time.</p>
          <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
          <ul>
            <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
            <li>For investigations and fraud detection and prevention</li>
            <li>For business transactions provided certain conditions are met</li>
            <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
            <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
            <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
            <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
            <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
            <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
            <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
            <li>If the information is publicly available and is specified by the regulations</li>
          </ul>
          <h3><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></h3>
          <p><strong><em><strong><em>In Short:</em></strong></em></strong><em><em>&nbsp;We may share information in specific situations described in this section and/or with the following&nbsp;categories of&nbsp;third parties.</em></em></p>
          <p><strong><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong></strong>&nbsp;We may share your data with third-party vendors, service providers, contractors, or agents (&lsquo;<strong><strong>third parties</strong></strong>&lsquo;) who perform services for us or on our behalf and require access to such information to do that work.&nbsp;We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any&nbsp;organisation&nbsp;apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.&nbsp;The&nbsp;categories of&nbsp;third parties we may share personal information with are as follows:</p>
          <ul>
            <li>Affiliate Marketing Programs</li>
            <li>Cloud Computing Services</li>
            <li>Communication &amp; Collaboration Tools</li>
            <li>Data Analytics Services</li>
            <li>Data Storage Service Providers</li>
            <li>Finance &amp; Accounting Tools</li>
            <li>Order&nbsp;Fulfilment&nbsp;Service Providers</li>
            <li>Payment Processors</li>
            <li>Performance Monitoring Tools</li>
            <li>Government Entities</li>
            <li>Product Engineering &amp; Design Tools</li>
            <li>Sales &amp; Marketing Tools</li>
            <li>Website Hosting Service Providers</li>
            <li>User Account Registration &amp; Authentication Services</li>
            <li>Testing Tools</li>
            <li>Social Networks</li>
            <li>Retargeting Platforms</li>
            <li>Ad Networks</li>
            <li>Review Platforms</li>
          </ul>
          <p>We&nbsp;also&nbsp;may need to share your personal information in the following situations:</p>
          <ul>
            <li><strong><strong>Business Transfers.</strong></strong>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            <li>We may share your information with our affiliates, in which case we will require those affiliates to&nbsp;honour&nbsp;this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
            <li><strong><strong>Business Partners.</strong></strong>We may share your information with our business partners to offer you certain products, services, or promotions.</li>
          </ul>
          <h3><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></h3>
          <p><strong><em><strong><em>In Short:</em></strong></em></strong><em><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></em></p>
          <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
          <h3><strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></h3>

          <p><strong><em>In Short:&nbsp;</em></strong><em>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</em></p>
          <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
          <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
          <h3><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></h3>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>We keep your information for as long as necessary to&nbsp;fulfil&nbsp;the purposes outlined in this privacy notice unless otherwise required by law.</em></em></p>
          <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).&nbsp;No purpose in this notice will require us keeping your personal information for longer than&nbsp;the period of time in which users have an account with us.</p>
          <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or&nbsp;anonymise&nbsp;such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
          <h3><strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></h3>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>We aim to protect your personal information through a system of&nbsp;organisational&nbsp;and technical security measures.</em></em></p>
          <p>We have implemented appropriate and reasonable technical and&nbsp;organisational&nbsp;security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other&nbsp;unauthorised&nbsp;third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
          <h3><strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong></h3>
          <p><strong><em><strong><em>In Short:</em></strong></em></strong><em><em>&nbsp;We do not knowingly collect data from or market to&nbsp;children under 18 years of age.</em></em></p>
          <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at&nbsp;privacy@DSPLATFORM.io.</p>
          <h3><strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong></h3>
          <p><strong><em><strong><em>In Short:</em></strong></em></strong><em><em>&nbsp;In some regions, such as&nbsp;the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information.&nbsp;You may review, change, or terminate your account at any time.</em></em></p>
          <p>In some regions (like&nbsp;the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section&nbsp;&lsquo;<a href="#contact"><u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u></a>&lsquo;&nbsp;below.</p>
          <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
          <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your&nbsp;<a href="#"><u>Member State data protection authority</u></a>&nbsp;or&nbsp;<a href="#"><u>UK data protection authority</u></a>.</p>
          <p>If you are located in Switzerland, you may contact the&nbsp;<a href="#"><u>Federal Data Protection and Information Commissioner</u></a>.</p>
          <p><strong><u><strong>Withdrawing your consent:</strong></u></strong>&nbsp;If we are relying on your consent to process your personal information,&nbsp;which may be express and/or implied consent depending on the applicable law,&nbsp;you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section&nbsp;&lsquo;<a href="#contact"><u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u></a>&lsquo;&nbsp;below.</p>
          <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,&nbsp;when applicable law allows,&nbsp;will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
          <p><strong><u><strong>Opting out of marketing and promotional communications:&nbsp;</strong></u></strong>You can unsubscribe from our marketing and promotional communications at any time by&nbsp;clicking on the unsubscribe link in the emails that we send,&nbsp;or by contacting us using the details provided in the section&nbsp;&lsquo;<a href="#contact"><u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u></a>&lsquo;&nbsp;below. You will then be removed from the marketing lists. However, we may still communicate with you &mdash; for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
          <p><strong><strong>Account Information</strong></strong></p>
          <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
          <ul>
            <li>Contact us using the contact information provided.</li>
            <li>Log in to your account settings and update your user account.</li>
          </ul>
          <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
          <p><strong><u><strong>Cookies and similar technologies:</strong></u></strong>&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also&nbsp;<a href="http://www.aboutads.info/choices/"><u>opt out of interest-based advertising by advertisers</u></a>&nbsp;on our Services.</p>
          <p>If you have questions or comments about your privacy rights, you may email us at&nbsp;privacy@DSPLATFORM.pro.</p>
          <h3><strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></h3>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&lsquo;DNT&rsquo;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for&nbsp;recognising&nbsp;and implementing DNT signals has been&nbsp;finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
          <h3><strong>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></h3>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></em></p>
          <p>California Civil Code Section 1798.83, also known as the&nbsp;&lsquo;Shine The Light&rsquo;&nbsp;law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
          <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.&nbsp;backups, etc.).</p>
          <p><strong><strong>CCPA Privacy Notice</strong></strong></p>
          <p>The California Code of Regulations defines a&nbsp;&lsquo;resident&rsquo;&nbsp;as:</p>
          <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</p>
          <p>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</p>
          <p>All other individuals are defined as&nbsp;&lsquo;non-residents&rsquo;.</p>
          <p>If this definition of&nbsp;&lsquo;resident&rsquo;&nbsp;applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>


          <p><strong>What categories of personal information do we collect?</strong></p>
          <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
          <div className="table-responsive">
           <table className="mb-3" >
            <tbody>
              <tr>
                <td>
                  <p><strong><strong>Category</strong></strong></p>
                </td>
                <td>
                  <p><strong><strong>Examples</strong></strong></p>
                </td>
                <td>
                  <p><strong><strong>Collected</strong></strong></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>A. Identifiers</p>
                </td>
                <td>
                  <p>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>B. Personal information categories listed in the California Customer Records statute</p>
                </td>
                <td>
                  <p>Name, contact information, education, employment, employment history, and financial information</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>C. Protected classification characteristics under California or federal law</p>
                </td>
                <td>
                  <p>Gender and date of birth</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>D. Commercial information</p>
                </td>
                <td>
                  <p>Transaction information, purchase history, financial details, and payment information</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>E. Biometric information</p>
                </td>
                <td>
                  <p>Fingerprints and voiceprints</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>F. Internet or other similar network activity</p>
                </td>
                <td>
                  <p>Browsing history, search history, online&nbsp;behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>G. Geolocation data</p>
                </td>
                <td>
                  <p>Device location</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>H. Audio, electronic, visual, thermal, olfactory, or similar information</p>
                </td>
                <td>
                  <p>Images and audio, video or call recordings created in connection with our business activities</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>I. Professional or employment-related information</p>
                </td>
                <td>
                  <p>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>J. Education Information</p>
                </td>
                <td>
                  <p>Student records and directory information</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>K. Inferences drawn from other personal information</p>
                </td>
                <td>
                  <p>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>L. Sensitive Personal Information</p>
                </td>
                <td>
                  <p>&nbsp;</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
            </tbody>
           </table>
          </div>
          <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
          <ul>
            <li>Category A &ndash;&nbsp;As long as the user has an account with us</li>
            <li>Category B &ndash;&nbsp;As long as the user has an account with us</li>
            <li>Category C &ndash;&nbsp;As long as the user has an account with us</li>
            <li>Category D &ndash;&nbsp;As long as the user has an account with us</li>
            <li>Category G &ndash;&nbsp;As long as the user has an account with us</li>
            <li>Category K &ndash;&nbsp;As long as the user has an account with us</li>
          </ul>
          <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
          </ul>
          <p><strong><strong>How do we use and share your personal information?</strong></strong></p>
          <p>DSPLATFORM Research Ltd&nbsp;collects and shares your personal information through:</p>
          <ul>
            <li>Targeting cookies/Marketing cookies</li>
            <li>Beacons/Pixels/Tags</li>
          </ul>
          <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
          <p>You may contact us&nbsp;by email at&nbsp;privacy@DSPLATFORM.io,&nbsp;by visiting&nbsp;Https://DSPLATFORM.pro,&nbsp;by post at DSPLATFORM Research Ltd, 71-75 Shelton Street, Covent Garden, WC2H 9JQ, London, United Kingdom,&nbsp;or by referring to the contact details at the bottom of this document.</p>
          <p>If you are using an&nbsp;authorised&nbsp;agent to exercise your right to opt out we may deny a request if the&nbsp;authorised&nbsp;agent does not submit proof that they have been validly&nbsp;authorised&nbsp;to act on your behalf.</p>
          <p><strong><strong>Will your information be shared with anyone else?</strong></strong></p>
          <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.</p>
          <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be&nbsp;&lsquo;selling&rsquo;&nbsp;of your personal information.</p>
          <p>DSPLATFORM Research Ltd&nbsp;has not sold or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months.&nbsp;</p>
          <p>DSPLATFORM Research Ltd&nbsp;has disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:</p>
          <ul>
            <li>Category A. Identifiers, such as contact details like your real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name.</li>
          </ul>
          <p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under&nbsp;&lsquo;<a href="#whoshare"><u>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</u></a>&lsquo;.</p>
          <p><strong><strong>Your rights with respect to your personal data</strong></strong></p>
          <p>Right to request deletion of the data &mdash; Request to delete</p>
          <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>
          <p>Right to be informed &mdash; Request to know</p>
          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>the purposes for which the collected personal information is used;</li>
            <li>whether we sell or share personal information to third parties;</li>
            <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>
            <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</li>
            <li>the business or commercial purpose for collecting, selling, or sharing personal information; and</li>
            <li>the specific pieces of personal information we collected about you.</li>
          </ul>
          <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>
          <p>Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s Privacy Rights</p>
          <p>We will not discriminate against you if you exercise your privacy rights.</p>
          <p>Right to Limit Use and Disclosure of Sensitive Personal Information</p>
          <p>We do not process consumer&rsquo;s sensitive personal information.</p>
          <p>Verification process</p>
          <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g.&nbsp;phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>
          <p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</p>
          <p>Other privacy rights</p>
          <ul>
            <li>You may object to the processing of your personal information.</li>
            <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>
            <li>You can designate an&nbsp;authorised&nbsp;agent to make a request under the CCPA on your behalf. We may deny a request from an&nbsp;authorised&nbsp;agent that does not submit proof that they have been validly&nbsp;authorised&nbsp;to act on your behalf in accordance with the CCPA.</li>
            <li>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</li>
          </ul>
          <p>To exercise these rights, you can contact us&nbsp;by email at&nbsp;privacy@DSPLATFORM.io,&nbsp;by visiting&nbsp;Https://DSPLATFORM.pro,&nbsp;by post at DSPLATFORM Research Ltd, 71-75 Shelton Street, Covent Garden, WC2H 9JQ, London, United Kingdom,&nbsp;or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</p>
          <h3><strong>13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></h3>
          <p><strong><em><strong><em>In Short:</em></strong></em></strong><em><em>&nbsp;Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</em></em></p>
          <p><strong><strong>Virginia CDPA Privacy Notice</strong></strong></p>
          <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
          <p>&lsquo;Consumer&rsquo;&nbsp;means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>
          <p>&lsquo;Personal data&rsquo;&nbsp;means any information that is linked or reasonably linkable to an identified or identifiable natural person.&nbsp;&lsquo;Personal data&rsquo;&nbsp;does not include de-identified data or publicly available information.</p>
          <p>&lsquo;Sale of personal data&rsquo;&nbsp;means the exchange of personal data for monetary consideration.</p>
          <p>If this definition&nbsp;&lsquo;consumer&rsquo;&nbsp;applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
          <p>The information we collect, use, and disclose about you will vary depending on how you interact with&nbsp;DSPLATFORM Research Ltd&nbsp;and our Services. To find out more, please visit the following links:</p>
          <ul>
            <li><a href="#infocollect"><u>Personal data we collect</u></a></li>
            <li><a href="#infouse"><u>How we use your personal data</u></a></li>
            <li><a href="#whoshare"><u>When and with whom we share your personal data</u></a></li>
          </ul>
          <p>Your rights with respect to your personal data</p>
          <ul>
            <li>Right to be informed whether or not we are processing your personal data</li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>Right to obtain a copy of the personal data you previously shared with us</li>
            <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects (&lsquo;profiling&rsquo;)</li>
          </ul>
          <p>DSPLATFORM Research Ltd&nbsp;has not sold any personal data to third parties for business or commercial purposes.&nbsp;DSPLATFORM Research Ltd&nbsp;will not sell personal data in the future belonging to website visitors, users, and other consumers.</p>
          <p>Exercise your rights provided under the Virginia CDPA</p>
          <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
          <p>You may contact us by email at&nbsp;privacy@DSPLATFORM.io,&nbsp;by visiting&nbsp;Https://DSPLATFORM.pro, or by referring to the contact details at the bottom of this document.</p>
          <p>If you are using an&nbsp;authorised&nbsp;agent to exercise your rights, we may deny a request if the&nbsp;authorised&nbsp;agent does not submit proof that they have been validly&nbsp;authorised&nbsp;to act on your behalf.</p>
          <p>Verification process</p>
          <p>We may request that you provide additional information reasonably necessary to verify you and your consumer&rsquo;s request. If you submit the request through an&nbsp;authorised&nbsp;agent, we may need to collect additional information to verify your identity before processing your request.</p>
          <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>
          <p>Right to appeal</p>
          <p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it.&nbsp;If you wish to appeal our decision, please email us at&nbsp;privacy@DSPLATFORM.io. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the&nbsp;<a href="#"><u>Attorney General to submit a complaint</u></a>.</p>
          <h3><strong>14. DO WE MAKE UPDATES TO THIS NOTICE?</strong></h3>
          <p><strong><em><strong><em>In Short:&nbsp;</em></strong></em></strong><em><em>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></em></p>
          <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated&nbsp;&lsquo;Revised&rsquo;&nbsp;date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
          <h3><strong>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></h3>
          <p>If you have questions or comments about this notice, you may&nbsp;email us at&nbsp;privacy@DSPLATFORM.io&nbsp;or contact us by post at:</p>
          <p>DSPLATFORM Research Ltd</p>
          <p>71-75 Shelton Street</p>
          <p>Covent Garden</p>
          <p>London&nbsp;WC2H 9JQ&nbsp;</p>
          <p>England</p>
          <h3><strong>16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></h3>
          <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it.&nbsp;To request to review, update, or delete your personal information, please&nbsp;visit:&nbsp;Https://DSPLATFORM.pro.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
